import React, { useState } from "react";
import { ISchemaFormProps } from "./SchemaForm";


// ACE
import AceEditor, { IAceEditorProps } from "react-ace";

import 'ace-builds/webpack-resolver';
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { registerExtensionFormComponent } from "./SchemaController";



export const SchemaFormAceEdit : React.FC<ISchemaFormProps> = (props) =>  {

	const [value, setvalue] = useState(props.value);
	const [context, setcontext] = useState(0);
	const [update, setupdate] = useState(0);
	const { args } = props;
	const { uiElem } = args;
	const { componentOptions } = uiElem || {};

	if (props.context != context) {
		setvalue(props.value);
		setcontext(props.context);
	}

	const onChange = (value: string, event: any) => {
		const text = props.onTextChange(value);
		if (text != null) {
			setvalue(text);
		} else {
			setupdate(update + 1);
		}
	}



	let mode: string = componentOptions?.aceMode || componentOptions?.languageMode || "typescript";
	if (mode === "plaintext") { mode = "plain_text"; }

	const aceProps: IAceEditorProps = {
		className: "schema-modal-ace-edit",
		mode,
		theme: "github",
		value,
		onChange,
		name: "AceEditor",
		minLines: uiElem?.multiLine,
		maxLines: componentOptions?.aceMaxLines
					? componentOptions?.aceMaxLines === "Infinity"
						? Infinity
						: componentOptions?.aceMaxLines
					: Infinity,
		width: componentOptions?.aceWidth || "100%",
		readOnly: args.readOnly,
		height: componentOptions?.aceHeight || undefined,

		setOptions: { 
			useWorker: true,
			showPrintMargin: false
		},
		editorProps: {
			$blockScrolling: true,
		}
	}

	return <AceEditor key={args.fullkey} {...aceProps}/>;
};


registerExtensionFormComponent("aceedit", SchemaFormAceEdit);
registerExtensionFormComponent("aceedit-object-json", SchemaFormAceEdit);
