import React, {useState, useCallback, useEffect } from "react";
import { strings } from "./../../services/Localization";
// import AppContext from '../../context/AppContext'

import { useParams, useNavigate  } from 'react-router-dom';

import {notImplemented, /*DataTable, PageHeader, DetailPageContainer, , DataTableWrapper */} from '../../components/Common';

import { BreadCrumbType } from '../../datatypes/datatypes';

//import { Outlet  } from 'react-router-dom';

import PageContent from "../PageContent";

import {  PageButtonType } from '../../datatypes/datatypes';

import { faRefresh, faBoltAuto }  from '@fortawesome/pro-regular-svg-icons';

import { IUser } from "src/dassTypes";
import { ColumnType, OptionType } from "../../components/Common/DataTable/DataTypes";

import { TAB_DEVICE_INFO } from '../../datatypes/tabsconstants'

import { getAppBase, autoRefreshInterval } from "./../../utils/consts";

import{ DEVEUI_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";

interface IPageStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    autoRefresh:boolean;
}

interface IPageProps {

}

export const pageTitle = strings.NAV_NST_RELAYS;
export const pageUrlPart = "omc-relays";

// export interface IRowType {

//     activated: boolean;             // true
//     altitude: number;               // null
//     appeui: string;                 // "0000000000000000"
//     applications: string;           // ""
//     comment: string;                // "vdev-p0-4-0-profile-otaa-1.0"
//     creation_time: string;          // "2021-11-30T13:42:51.767Z"
//     devaddr: string;                // "290396717"
//     deveui: string;                 // "6600000400000000"
//     device_profile_uuid: string;    // "1f5f1b18-166b-437f-8938-95bc28e94a2c"
//     device_status: number;          // 3
//     dl_fcnt: number;                // 8
//     groups: number;                 // ""
//     joineui: string;                // null
//     last_reception: string;         // "2021-12-02T08:57:15.587Z"
//     latitude: number;               // null
//     longitude: number;              // null
//     lora_device_class: number;      // 0
//     ownerid: string;                // "noise_robot_p0_4"
//     registration_status: number;    // 1
//     service_profile_uuid: string;   // "8c19a45d-4bc7-44b7-b123-935ff3041bc2"
//     suspended: boolean;             // null
//     tags: any;                      // null

//     ns_data: {
//         last_rx_time: string;
//         last_used_sf: string;
//         lastrssi: number;
//         lastsnr: number;
//         ma_nb_gws: number;
//         main_gtw_id: string;
//         main_gtw_name: string;
//     };
// }

interface IRowType {
    deveui: string;
    num_device_relayed: number;
    num_filtering_rules: number;
    status: string;
    tenant: string;
    description: string;
}

// function hex32(value: number) {
//     const hex = value.toString(16);
//     return "00000000".substring(hex.length) + hex;
// }



const OmcRelays: React.FC<IPageProps> = (props: IPageProps) =>  {

    let { id, tabname } = useParams();
    const navigate = useNavigate();


    // const AppContextObj = useContext(AppContext);

    const stateInit = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label: pageTitle, url:''}],
        refresh:false,
        autoRefresh:false
    };

    const [state, setState] = useState<IPageStates>(stateInit)


    const detailPageNav = ( navigate, tab, id, row = null) => {

        if(id) {
            
            navigate(`${getAppBase()}/${pageUrlPart}/${id}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/omc-relays`}})
        }else {

            notImplemented();

            
            navigate(`${getAppBase()}/${pageUrlPart}/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/omc-relays`}})
        }
    }

    const  getActions = () => {

        return {
            actions: [
            ],
            bulkActions:[
            ]
        }
    }

    useEffect(() => {
        
        const interval = setInterval(() => {
            if(state.autoRefresh) {
                refreshTable();
            }
        }, autoRefreshInterval);
        return () => clearInterval(interval);

    }, [state.autoRefresh]);

    const toggleAutoRefresh = () => {

        setState(prevState => { 
            return {...prevState, autoRefresh:!state.autoRefresh}
        })
    }

    const getPageButtons = useCallback(() => {
    
        const pageButtons:PageButtonType[] = [
            {
                title: strings.REFRESH_RELAY_LIST,
                action: () => {  refreshTable() },
                type: 'button',
                icon: faRefresh
            },
            {
                title: (state.autoRefresh) ?  strings.STOP_AUTO_REFRESH : strings.AUTO_REFRESH,
                action: () => {  toggleAutoRefresh() },
                type: 'button_with_icon',
                varient: 'primary',
                icon: faBoltAuto
                
            }
        ]

        return pageButtons;
    },[state.autoRefresh]);

    const  initDataTable = () => {

        let pageButtons = getPageButtons();

        const actions = getActions();

		let columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 2,
                newCellWidth: "2%"
            },
            {
                key: "deveui",
                type: "text",
                title: strings.DEVEUI,
                inputValidation: DEVEUI_INPUT_VALIDATION,
                filterable: true,
                newCellWidth: "180px",
                filterField: 'search_by_deveui',
                filterType: 'text',
                filterParams: {
                    mapper: x => (x || "").replace(/[-:\s]/g, "").toLowerCase()
                },
                sortable: true,
                sortKey: "sort_by_deveui",
                detailLink: true,
                copyLink: true,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_DEVICE_INFO, row.deveui, row),
                customClass: 'font-monospace nowarp',
                cellWidth:25

            },
            {
                key: "description",
                type: "text",
                title: strings.TABLE_NST_RELAY_COMMENT,
                newCellWidth: "200px",
            },
            {
                key: "tenant_name",
                type: "text",
                title: strings.TABLE_NST_TENANT,
                sortable: false,
                filterable: false,
                filterField: 'search_tenantName',
                filterType: 'text',
                render_tooltip:(row) => {
                    return row.tenant_id;
                },
                cellWidth: 25,
                newCellWidth: "200px",
                dataAlign: "center",
            },
            {
                key: "status",
                type: "text",
                title: strings.TABLE_NST_RELAY_STATUS,
                sortable: false,
                filterable: false,
                cellWidth: 25,
                newCellWidth: "200px",
                dataAlign: "center",
                //render: (row: IRowType) => !row.last_used_sf ? "" : row.last_used_sf,
            },
            {
                key: "num_device_relayed",
                type: "number",
                title: strings.TABLE_NST_RELAY_NUM_DEVICE_RELAYED,
                sortable: false,
                filterable: false,
                // filterable: true,
                // filterType: 'text',
                // inputValidation: ID_INPUT_VALIDATION,
                // filterField: 'search_comment',
                // filterSearchMinLength: 1,
                // filterParams: {
                //     mapper: (x) => x || undefined
                // },
                // sortable: true,
                // sortKey: "sort_by_comment",
                cellWidth: 25,
                newCellWidth: "200px",
                dataAlign: "center",
            },
            {
                key: "num_filtering_rules",
                title: strings.TABLE_NST_RELAY_NUM_FILTER_RULES,
                type: "text",
                sortable: false,
                filterable: false,
                newCellWidth: "200px",
                cellWidth:25,
                dataAlign: "center",
                //detailLink: true,
                //copyLink: true,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_DEVICE_INFO, row.deveui, row),
                customClass: 'font-monospace nowarp'
            }
        ];

        // URL: https://cto-playground.dev.orbiwise.com:8443/omc/gateways?tags=&untagged=false&page=0&size=10&sort=name,asc&activated=true&projection=gatewayListView&sort=uuid,asc


        let options: OptionType = {
            url:'/uiapi/rest/omc/relay_info/jsonrelaylistview',
            query_param: {
                all: true,
                get_pages: true,
                limit: DEFAULT_RECORD_LIMIT,
                stream: 'progress',
            } as any,
            serial_number: false,
            //defaultSortField: 'sort_by_last_rx_time',
            //defaultSortOrder: 'asc',
            id_field:'deveui',
            oboe_path:'pages.*',
            available_key: 'deveui',
            emptyDataMsg: 'No Relay Available'

        }


        return {
             ...actions
            , columns
            ,...options
            ,...pageButtons
        };
	}

    const refreshTable = () => {

        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })

    }


    return (<PageContent
        name={`omc-relays`} 
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={strings.NAV_NST_RELAYS} 
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
    </PageContent>)

}


export default OmcRelays;