
import React, { useContext, useEffect, useState } from 'react';
import { DateRangePicker, Range } from 'react-date-range';
import {  Modal, Button } from 'react-bootstrap';
import { DataTableContext } from '../Common/DataTable/DataTableState';
import { strings } from "./../../services/Localization";


interface DateRangeRenderState {
    show:boolean;
    emptyDate: Range[];
    selectedDate:Range[];
    dateRangeStr:string;
    startTime: string;
    endTime: string;
}

interface PayloadExportModalProps {
    handleClose: () => void;
    show: boolean;
    deveui?: string;

}

const PayloadExportModal: React.FC<PayloadExportModalProps> = ( props ) => {

    const DataTableContextObj = useContext(DataTableContext);
    let selectedIds = [];
    if (props.deveui) {
        selectedIds.push(props.deveui);
    } else {
        selectedIds = DataTableContextObj.searchState.selectedRowIds.map(v => v.deveui)
    }

    const emptyDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }
    
    const initState = {
            show:false,
            emptyDate: [emptyDate],
            selectedDate:[emptyDate],
            dateRangeStr: '',
            startTime: '00:00',
            endTime: '23:59'
    }

    const statement = props.deveui ? `${strings.DEVICE} ${props.deveui.toUpperCase()}` 
                    : (selectedIds.length == 0 ? `${strings.ALL_DEVICES}` : `${selectedIds.length} ${strings.SELECTED_DEVICES}`)  

    const [state, setState] = useState<DateRangeRenderState>(initState);

    useEffect(() => {

        if(!state.startTime) {
            setState(prevState => {     
                return {...prevState, startTime: initState.startTime }
            });
        }
        if(!state.endTime) {
            setState(prevState => {     
                return {...prevState, endTime: initState.endTime }
            });
        }

    }, [state.startTime, state.endTime])

    function timeToMinutes(timeStr) {

        const [hoursStr, minutesStr] = timeStr.split(':');
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        const totalMinutes = (hours * 60) + minutes;
        
        return totalMinutes;
    }
    
    const setCalenderToggle = () => { props.handleClose() }

      /**
       * endDate: Tue May 24 2022 00:00:00 GMT+0530 (India Standard Time) {}
        key: "selection"
        startDate: Tue May 24 2022 00:00:00 GMT+0530 (India Standard Time) {}
        [[Prototype]]: Object
       * 
      */
    const setSelectedDateHandler = ( item ) => {
            
            setState(prevState => {     
                return {...prevState, selectedDate: item }
            });
    }

    const applySearch = () => {
        
        //https://cto-playground.dev.orbiwise.com/uiapi/rest/export/payloads?download_filename=payloads.csv&tz=-330&payload_from_date=2022-06-14T18:30:00.000Z&payload_to_date=2022-07-25T18:29:59.999Z

        let startDate = state.selectedDate[0].startDate;
        let endDate = state.selectedDate[0].endDate;

        const startTimeSplit = state.startTime.split(":").map(v => parseInt(v))
        const endTimeSplit = state.endTime.split(":").map(v => parseInt(v))

        startDate.setHours((startTimeSplit[0] ?? 0),(startTimeSplit[1] ?? 0), 0, 0)
        endDate.setHours((endTimeSplit[0] ?? 23), (endTimeSplit[1] ?? 59), 59, 999)

        var queryStr = '';

        queryStr = selectedIds.length > 0 ? `&deveuis=${selectedIds.join(",")}`: queryStr;
    
        queryStr += '&payload_from_date=';
        queryStr += startDate.toISOString();

        queryStr += '&payload_to_date=';
        queryStr += endDate.toISOString();

        var url = window.location.protocol;
        var tz = "&tz=" + (new Date()).getTimezoneOffset();
        url += '//';
        url += window.location.host;
        let downloadUrl = url + '/uiapi/rest/export/payloads?download_filename=payloads.csv' + tz + queryStr;
        
        window.location.assign(downloadUrl);
        //close popup after 1 sec
        setTimeout(() => {
            props.handleClose();
        },1000)
        

    }

    const clearSearch = () => {
        
        setState(prevState => {
            return {...prevState, show:!state.show, selectedDate:[emptyDate], dateRangeStr:'', startTime: initState.startTime, endTime: initState.endTime}
        });

    }

    let display = (props.show) ? '' : 'none';

    const DateRangePickerAny: any = DateRangePicker;

      return (
            <div style={{display: display}}>
                <input type="text" 
                                className='form-control form-control-sm'
                                onClick={setCalenderToggle} 
                                onChange={ (e) => {  } }
                                value={state.dateRangeStr}  
                                placeholder={`Search Date`} />
                <Modal
                        show={props.show}
                        onHide={props.handleClose}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Export Payloads
                            </Modal.Title>
                        </Modal.Header>
                            <Modal.Body>
                                <small>{strings.YOU_ARE_ABOUT_TO_USE_EXPORT_PAYLOADS_FOR} {statement} {strings.WHICH_MAY_RESULT_IN_A_VERY_LARGE_DOWNLOAD}. {strings.PLEASE_LIMIT_THE_EXPORT_TO_THE_RELEVANT_TIME_RANGE}.</small><br/>
                                <div className='rdrDateRangePickerWrapper'>
                                <input
                                    type="time"
                                    value={ state.startTime }
                                    placeholder="From Date"
                                    className='time-picker'
                                    style={{
                                        width: '318px',
                                        marginLeft: '236px',
                                        textAlign: "center",
                                        fontFamily: "",
                                        color: "gray"
                                        
                                    }}
                                    onClick={(e) => e.preventDefault()}
                                    onChange={(e) => {
                                        const { selectedDate, endTime } = state;
                                        const startDate = selectedDate[0].startDate;
                                        const endDate = selectedDate[0].endDate;
                                        
                                        const newStartTime = e.target.value;

                                        if (startDate.toLocaleDateString() === endDate.toLocaleDateString()) {

                                            const endTimeMinutes = timeToMinutes(endTime);
                                            const startTimeMinutes = timeToMinutes(newStartTime);

                                            if (startTimeMinutes > endTimeMinutes) {
                                                console.error('Start time cannot be greater than end time.');
                                                return;
                                            }
                                        } 
                                        setState(prevState => ({ ...prevState, startTime: newStartTime }));
                                    }}/>
                                 <input
                                    type="time"
                                    value={ state.endTime }
                                    onClick={(e) => e.preventDefault()}
                                    onChange={(e) => {
                                        const { selectedDate, startTime } = state;
                                        const startDate = selectedDate[0].startDate;
                                        const endDate = selectedDate[0].endDate;

                                        const newEndTime = e.target.value;
                                    
                                        if (startDate.toLocaleDateString() === endDate.toLocaleDateString()) {

                                            const endTimeMinutes = timeToMinutes(newEndTime);
                                            const startTimeMinutes = timeToMinutes(startTime);

                                            if (endTimeMinutes < startTimeMinutes) {
                                                console.error('End time cannot be less than start time.');
                                                return;
                                            }
                                        }
                                        setState(prevState => ({ ...prevState, endTime: newEndTime }));
                                    }}
                                    className='time-picker'
                                    style={{
                                        width: '318px',
                                        textAlign: "center",
                                        marginLeft: '9px',
                                        fontFamily: "inherit",
                                        color: "gray"
                                    }}
                                /></div>
                                <DateRangePickerAny
                                    onChange={item => setSelectedDateHandler([item.selection])}
                                    moveRangeOnFirstSelection={true}
                                    editableDateInputs={false}
                                    months={2}
                                    direction="horizontal"
                                    ranges={state.selectedDate}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="dark" onClick={applySearch}>Download</Button>
                                <Button variant="outline-dark" onClick={clearSearch}>Clear</Button>
                                <Button variant="outline-dark"  onClick={setCalenderToggle}>Close</Button>
                            </Modal.Footer>
                        </Modal>
            </div>
        );
     
}

export default PayloadExportModal;