import { strings } from "../../services/Localization";

import { IConstants } from "../../types";
import { INavArrayType } from "./NavBuildComponets";

declare const constants: IConstants;



const checkFlag = (license: number, bit: number, user: any) => {

    //let {user} = state;

    if (!user || !user.can_register) {
        user = {
            can_register: false,
        };
    }
    if (license && bit) {
        switch (bit) {
            case 1:
                // tslint:disable-next-line:no-bitwise
                return ((license & 0x1) !== 0); // legacy registration
            case 2:
                return ((license & 0x200) !== 0); // license management
            case 4:
                // tslint:disable-next-line:no-bitwise
                return (((license & 0x4) !== 0)            // batch registration
                    && (user.can_register)
                    && (((license & 0x1) !== 0)    // legacy registration
                        || ((license & 0x2) !== 0))); // profile registration
            case 5:
                // tslint:disable-next-line:no-bitwise
                return ((license & 0x8) !== 0);             // active connections
            case 6:
                // tslint:disable-next-line:no-bitwise
                return ((license & 0x10) !== 0);            // event log
            default:
                return false;
        }
    } else {
        return false;
    }
}


interface ISubmenuType {
    href: string | ((currentState: any) => string);
    hreftype?: "navlink" | "extlink";
    title: string;
    render?: (currentState: any, usernav?: any) => boolean;
    onClick?: () => void;
}

export { ISubmenuType }
// interface INavArrayType {
//     title: string;
//     type: "link" | "dropdown";
//     href?: string;
//     render: (currentState: any) => boolean;
//     submenu?: ISubmenuType[];
// }

// export { INavArrayType }


const navArray: INavArrayType[] = [
    {
        title: strings.NAV_DASHBOARD,
        type: 'link',
        href: '/app/mydashboard',
        visible: (currentState) => {
            return constants.dassboardEnabled
        }
    },


    //-- MAP ---------------------------------------------------------------------------


    {
        title: strings.NAV_MAP,
        type: 'link',
        href: '/app/network_map',
        visible: (state) => {
            return state.user.account_status === "active" && !state.user.is_organisation
        }
    },
    {
        title: strings.NAV_DEVICES_ITEM,
        type: 'link',
        href: '/app/my-devices',
        visible: (state) => {
            return state.user.account_status === "active" && !state.user.is_organisation
        }

    },

    //-- GROUPS ---------------------------------------------------------------------------


    {
        title: strings.NAV_MANAGE_GROUPS,
        type: 'link',
        href: '/app/groups',
        visible: (currentState) => {
            return !currentState.user.hasOwnProperty("is_organisation") && currentState.user.account_status === "active"
        },
    },


    //-- APPLICATIONS MENU ---------------------------------------------------------------------------

    {
        title: strings.NAV_APPLICATIONS_ITEM,
        type:'dropdown',
        visible: (currentState) => {
            const user = currentState.user;
            return user != null && !user.is_organisation && user.account_status === "active" && !user.app_uuid;
        },
        submenu: [
            {
                href: '/app/my-apps',
                title: strings.MANAGE_APPLICATIONS_NAV_TITLE
            },
            {
                href: '/app/active_connections',
                title: strings.ACTIVE_CONNECTIONS_NAV_TITLE,
                visible: (currentState) => {
                    return checkFlag(currentState.user._license, 5, currentState.user)
                }
            },
            {
                href: '/app/event_log',
                title: strings.EVENT_LOG_NAV_TITLE,
                visible: (state) => {
                    return checkFlag(state.user._license, 6, state.user) && state.user.can_access_logs
                }
            }
        ]
    },


    //-- GATEWAYS ---------------------------------------------------------------------------


    {
        title: strings.NAV_GATEWAYS_ITEM,
        type: 'link',
        href: '/app/my-gateways',
        visible: (currentState) => {
            return currentState.user.can_access_gtw_trace && !currentState.user.is_organisation
        },
        // submenu:[
        //     {
        //         href: '/app/my-gateways',
        //         title: strings.NAV_GATEWAYS_LIST_GATEWAYS,
        //         visible: (currentState) => {
        //             return currentState.user.can_own_gtw && !currentState.user.hasOwnProperty("is_organisation")
        //         }
        //     }
        // ]
    },
    {
        title: strings.NAV_GATEWAYS_ITEM,
        type: 'link',
        href: '/app/my-gateways',
        visible: (currentState) => {
            return currentState.user.can_own_gtw && !currentState.user.hasOwnProperty("is_organisation") && !currentState.user.can_access_gtw_trace
        }
    },


    //-- PROFILES MENU ---------------------------------------------------------------------------

    {
        title: strings.NAV_PROFILES_ITEM,
        type:'dropdown',
        visible: (currentState) => {
            const u = currentState.user;
            return constants.enable_profiles && !u.hasOwnProperty("is_organisation") && u.account_status === "active" &&
                (u.can_list_device_profile || u.can_list_service_profile || u.can_list_connectivity_profile ||
                    u.can_list_roaming_profile || u.can_list_qos_profile || u.can_list_channel_profile || u.can_list_relay_profile);
        },
        submenu: [
            {
                href: '/app/profiles/device',
                title: strings.NAV_DEVICE_PROFILES,
                visible: (currentState) => {
                    return currentState.user.can_list_device_profile
                }
            },
            {
                href: '/app/profiles/service',
                title: strings.NAV_SERVICE_PROFILES,
                visible: (currentState) => {
                    return currentState.user.can_list_service_profile
                }
            },
            {
                href: '/app/profiles/connectivity',
                title: strings.NAV_CONNECTIVITY_PROFILES,
                visible: (currentState) => {
                    return currentState.user.can_list_connectivity_profile
                }
            },
            {
                href: '/app/profiles/roaming',
                title: strings.NAV_ROAMING_PROFILES,
                visible: (currentState) => {
                    return currentState.user.can_list_roaming_profile
                }
            },
            {
                href: '/app/profiles/qos',
                title: strings.NAV_QOS_PROFILES,
                visible: (currentState) => {
                    return currentState.user.can_list_qos_profile
                }
            },
            {
                href: '/app/profiles/channel',
                title: strings.NAV_CHANNEL_PROFILES,
                visible: (currentState) => {
                    return currentState.user.can_list_channel_profile
                }
            },
            {
                href: '/app/profiles/relay',
                title: strings.NAV_RELAY_PROFILES,
                visible: (currentState) => {
                    return currentState.user.can_list_relay_profile
                }
            },
            {
                href: '/app/join-servers',
                title: strings.NAV_JOIN_SERVERS,
                visible: (currentState) => {
                    return currentState.user.can_register_joinserver
                }
            },
        ]
    },
    {
        title: strings.FIND,
        type:'dropdown',
        visible: (currentState) => {
            return currentState.user && (currentState.user.administrator  || currentState.user.administrator_readonly  ||
                                         currentState.user.customer_admin || currentState.user.customer_admin_readonly || 
                                         currentState.user.org_admin      || currentState.user.org_admin_readonly)
        },
        submenu: [
            {
                href: '/app/admin-devices',
                title: strings.NAV_ADMIN_DEVICES,
                visible: (currentState) => {
                    return currentState.user.administrator || currentState.user.administrator_readonly;
                }
            },
            {
                href: '/app/admin-users',
                title: strings.NAV_ADMIN_USERS,
                visible: (currentState) => {
                    return (currentState.user.customer_admin || currentState.user.customer_admin_readonly ||
                            currentState.user.org_admin      || currentState.user.org_admin_readonly);
                }
            }
        ]
    },
    {
        title: strings.ACCOUNTS,
        type:'dropdown',
        visible: (currentState) => {
            return currentState.user && (currentState.user.login_admin    ||
                                         currentState.user.administrator  || currentState.user.administrator_readonly  ||
                                         currentState.user.customer_admin || currentState.user.customer_admin_readonly || 
                                         currentState.user.org_admin      || currentState.user.org_admin_readonly)
        },
        submenu: [
            {
                href: '/app/accounts/logins',
                title: strings.NAV_LOGINS_LIST_USERS,
                visible: (currentState) => {
                    return currentState.user.login_admin;
                }
            },
            {
                href: '/app/accounts/users',
                title: strings.NAV_USERS_LIST_USERS,
                visible: (currentState) => {
                    return (currentState.user.administrator || currentState.user.administrator_readonly) && !currentState.user.hasOwnProperty("is_organisation")
                }
            },
            {
                href: '/app/accounts/customers',
                title: strings.NAV_CUSTOMERS_LIST_CUSTOMERS,
                visible: (currentState) => {
                    return currentState.user.customer_admin || currentState.user.customer_admin_readonly
                }
            },
            {
                href: '/app/accounts/organisations',
                title: strings.NAV_ORGANIZATION_LIST_USERS,
                visible: (currentState) => {
                    return currentState.user.org_admin || currentState.user.org_admin_readonly
                }
            }
        ]
    },



    //-- SYSTEM MENU ---------------------------------------------------------------------------

    {
        title: strings.NAV_SYSTEM,
        type:'dropdown',
        visible: (currentState) => {
            return currentState.user.sys_admin && (

                (currentState.user && checkFlag(currentState.user._license, 2, currentState.user))
                || constants.loralogsEnabled
                || constants.svrlogsEnabled)
        },
        submenu: [
            {
                href: '/app/product-license',
                //onClick: (currentState) => { return currentState.ShowLicense },
                title: strings.PRODUCT_LICENSE_TITLE,
                visible: (currentState) => {
                    return (currentState.user && currentState.user.sys_admin && checkFlag(currentState.user._license, 2, currentState.user))
                }
            },
            {
                href: '/rawlog.log',
                title: strings.LORA_UPLINK_LOGS,
                visible: (currentState) => {
                    return constants.loralogsEnabled
                }
            },
            {
                href: '/svrlogs.tar',
                title: strings.GET_SERVER_LOGS,
                visible: (currentState) => {
                    return constants.svrlogsEnabled
                }
            },
            {
                href: '',
                title: `Build: ${constants.buildtime}`,
                visible: (currentState) => {
                    return constants.svrlogsEnabled
                }
            },
            {
                href: '/app/sys-join-servers',
                title: strings.NAV_GLOBAL_JOIN_SERVERS,
                visible: (currentState) => {
                    return currentState.user.can_register_joinserver
                }
            },
        ]
    },

    {
        title: strings.NAV_HELP,
        type:'dropdown',
        visible: (currentState: any) => {
            const userenv = currentState.user?._environment || {};
            return (userenv.getting_started_link || userenv.help_page_link || userenv.helpdesk_email_template_id || userenv.swagger_ui)
        },
        submenu: [
            {
                href: (currentState) => {
                    const userenv = (currentState.user || {})._environment || {};
                    return userenv.help_page_link;
                },
                title: strings.NAV_HELP_PAGE,
                visible: (currentState) => {
                    const userenv = (currentState.user || {})._environment || {};
                    return !!userenv.help_page_link
                }
            },
            {
                href: (currentState) => {
                    const userenv = (currentState.user || {})._environment || {};
                    return userenv.getting_started_link;
                },
                title: strings.NAV_HELP_GETTING_STARTED,
                visible: (currentState) => {
                    const userenv = (currentState.user || {})._environment || {};
                    return !!userenv.getting_started_link
                }
            },
            {
                href: '/app/swagger',
                title: strings.NAV_HELP_SWAGGER,
                visible: (currentState) => {
                    const userenv = (currentState.user || {})._environment || {};
                    return !!userenv.swagger_ui
                }
            },
            {
                href: (currentState) => {
                    return currentState.helpdeskMailto;
                },
                title: strings.NAV_HELP_SUPPORT_EMAIL,
                visible: (currentState) => {
                    const userenv = (currentState.user || {})._environment || {};
                    return !!(userenv.helpdesk_email_template_id && currentState.helpdeskMailto)
                }
            }
        ]
    },


]

export { navArray };