// tslint:disable:max-line-length
import React, { Suspense, lazy, ReactNode } from "react";
import { Col, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

import { GenericDassQuery, IRequestMethod } from "../../services/BasicDassQueries";

import { toast } from "../../utils/Toaster";
import { strings } from "../../services/Localization";

import { IJsonSchemaObject } from "./UiJsonSchemaTypes";
import { IUiSchemaElemArgs, IUiSchemaUpdateState } from "./SchemaController";

import SchemaPanel from "./SchemaPanel";
import { Container } from "react-bootstrap";
// import { IUser } from "../../dassTypes";

// The good old status lights
import yellowLight from "../../../resources/images/yellow_light.png";
import redLight from "../../../resources/images/red_light.png";
import greenLight from "../../../resources/images/green_light.png";
import amberLight from "../../../resources/images/amber_light.png";

import AppContext from '../../context/AppContext'

// Import for map extension
import "./SchemaModal.css";

const VisTimeline = lazy(() => import("./VisTimeline"));

import { ipadWidth, windowWidth } from '../../utils/consts';
declare const headerContentTypeJson;
declare const constants: IConstants;



import { dialog } from "../Common/ConfirmDialog";
import { faInfo } from "@fortawesome/pro-regular-svg-icons";


// Standard SchemaModal extensions
import "./ExtCarousel";
import "./ExtMapView";
import "./ExtChartJs";
import "./ExtAmChart";
import "./ExtColorPicker";
import "./ExtProgressBar";
import "./ExtMarkdown"
import "./SchemaFormAceEdit";
import "./ExtSchemaFormMonacoEdit";

// Dedicated extensions
import "./RadioConfig";
import { BreadCrumbType } from "src/datatypes/datatypes";
import { BreadcrumbComponent } from "../Common/BreadcrumbComponent";
import { setTranslationHandler } from "./SchemaTranslationAudit";
import { IConstants } from "src/types";



const testPrefix = ""; // "/uitest";       // Note NEVER commit with this enabled.


interface ISchemaModalState {
    currentValues: any;
    oldValues: any;
    objectErrors: any;
    jsonSchema: IJsonSchemaObject;
    activeTab: string;
    close: boolean,
    apply: boolean,
    success: boolean;
    readOnly: boolean;
    updateCount: number;

    extensions: {
        [ext: string]: (args: IUiSchemaElemArgs) => ReactNode;
    };
    textMarkerExtensions: {
        [ext: string]: (key: string, style: any) => JSX.Element[];
    };
    libExtensions: {
        [name: string]: (...any) => any;
    }

    debug: boolean;
    Loading: boolean;
    ShowModal: boolean;
    modalFullScreen: true | string;
}

interface ISchemaModalProps {
    Schema?: IJsonSchemaObject;
    SchemaUrl?: string;
    EditObject?: any;
    editMode?: boolean;
    breadCrumbArr?: BreadCrumbType[];
    loadDataOnOpen?: boolean;           // if set, data resources with onOpenOnLoadRequest will be loaded
    warningFooter?: boolean;            // ?? should be removed
    OnClose?: (obj: any, refreshRequired: boolean) => void;
    OnChange?: (obj: any) => void;
    type: "modal" | "page";
}

export class SchemaModal extends React.Component<ISchemaModalProps, ISchemaModalState> {

    static contextType = AppContext
    declare context: React.ContextType<typeof AppContext>

    linkRef = React.createRef<HTMLAnchorElement>();
    schemaRef = React.createRef<SchemaPanel>();
    develMonitorTimer = null;
    debugLogBuf: any[] = [];
    isLocalhost = ["localhost", "127.0.0.1"].includes(window.location.hostname);
    argDebug = window.location.search?.indexOf("debuG") > 0;
    readOnlyMode = false;
    modifyQueryExecuted = false;

    constructor(props) {
        super(props);


        setTranslationHandler(async (translations, verifications) => {
            try {
                this.setState({ updateCount: this.state.updateCount + 1 });
                await GenericDassQuery("/rest/audit-translations", {
                    method: "POST",
                    data: { translations, verifications }
                });
            } catch (e) {
                console.log("Can't save translation audits", e.message);
            }
        });



        const currentValues = {...this.props.EditObject};
        let modalFullScreen = (windowWidth() < ipadWidth) ? true : "xxl-down";

        this.state = {
            Loading: true,
            ShowModal: true,
            readOnly: this.props.EditObject ? this.props.EditObject.__readonly !== false : false,
            debug: false,
            updateCount: 0,

            apply: false,
            close: false,
            success: false,
            activeTab: "",
            jsonSchema: null as any,
            currentValues: currentValues,
            oldValues: currentValues,
            objectErrors: null,
            modalFullScreen: modalFullScreen,
            extensions: {
                timeline: this.timelineExtension,
            },
            textMarkerExtensions: {
                "yellow-light": (key) => [<img key={key} src={yellowLight} style={{ width: "1em", height: "auto" }} />],
                "amber-light": (key) => [<img key={key} src={amberLight}  style={{ width: "1em", height: "auto" }} />],
                "red-light": (key) => [<img key={key} src={redLight}  style={{ width: "1em", height: "auto" }}/>],
                "green-light": (key) => [<img key={key} src={greenLight}  style={{ width: "1em", height: "auto" }} />],
            },
            libExtensions: {
                downloadAsFile: this.downloadAsFile,
                console: (...args) => this.log("EXPR", ...args),
                getLanguage: () => this.context.navBarState.language || "en",
            },
        };

        this.log("EditDevice", {...this.props.EditObject});
    }



    public log = (...args) => {
        if (this.state.debug) {
            console.log(...args);
        } else {
            if (this.debugLogBuf && this.debugLogBuf.length < 100) {
                this.debugLogBuf.push(args);
            }
        }
	}



    public getSettings = (scope: string) => {
        if (scope === "default-map-center") {
            const ui = this.context.user?.ui_settings || {};
            return {
                lat: ui.map_center_latitude, lng: ui.map_center_longitude, zoom: ui.map_zoom
            };
        }
        return {};
	}



    public timelineExtension = (args: IUiSchemaElemArgs) => {

        const { key, value } = args;
        const { options, items, groups } = value || {};

        return args.embedObject(
            <Suspense key={key} fallback={<div>Loading...</div>}>
                <VisTimeline options={options} items={items} groups={groups}></VisTimeline>
            </Suspense>,
            { isContainer: true }
        );
    }




    public async componentDidMount() {

        try {

            let jsonSchema: IJsonSchemaObject = null;

            // check if we're in readonly mode, in this case we immediately set the readonly state to true.
            this.readOnlyMode = this.context?.user?._readonly === true;
            if (this.readOnlyMode) {
                this.setState({ readOnly: true }); 
            }

            if (this.props.Schema) {
                jsonSchema = this.props.Schema;

            } else if (this.props.SchemaUrl) {

                const lang = this.context.navBarState.language || "en";
                const url = this.props.SchemaUrl + (this.props.SchemaUrl.indexOf("?") ? "&" : "?") + "lang=" + lang;

                if (testPrefix && this.isLocalhost) {
                    // When testPrefix is set, we always try to load the schema first via the testPrefix. This allow the developer
                    // to use a separate development server to server the Schema. That way the normal backend can run unchanged, and
                    // we can simply serve locally the Schema we are working on.
                    // If the develMonitorSchema flag is set in the UI schema, the engine will continue to load the schema from
                    // the test server every 3 seconds to see if there are changes. And if changes, it will reset the modal according
                    // to the new schema.
                    try {
                        jsonSchema = (await GenericDassQuery(url, { method: "GET", prefix: testPrefix })).data;

                        if (jsonSchema?.$uiSchema?.develMonitorSchema) {
                            this.develMonitorTimer = setInterval(async () => {
                                try {
                                    const jsonSchema = (await GenericDassQuery(url, { method: "GET", prefix: testPrefix })).data;

                                    if (jsonSchema && JSON.stringify(this.state.jsonSchema) !== JSON.stringify(jsonSchema)) {
                                        const currentValues = {...this.props.EditObject};
                                        this.setState({
                                            jsonSchema: jsonSchema,
                                            activeTab: "",
                                            currentValues: currentValues,
                                            oldValues: currentValues,
                                            objectErrors: null,
                                        });
                                    }
                                } catch (e) { }
                            }, 3000);
                        }

                    } catch (e) {
                        console.log(e.message);
                    }
                }

                if (!jsonSchema) {
                    jsonSchema = (await GenericDassQuery(url, { method: "GET" })).data;
                }
            }
            
            this.setState({
                jsonSchema: jsonSchema || {},
                Loading: false,
            });

        } catch (error) {
            if(error.status == 402){
                toast.warning(error.message)
            }
            console.log(error);
        }

    }


    public showMessage = async (type: "success" | "error" | "confirm", message: string) => {

        const messageTxt = (message || "").substring(0, 1).toUpperCase() + (message || "").substring(1);

        this.log("Toast, type=" + type + ", message=" + message);
        if (type === "success") {
            toast.success(messageTxt);
            return true;
        } else if (type === "error") {
            toast.error(messageTxt);
            return false;
        } else {
            return await dialog({
                title: "Confirm",
                description: messageTxt,
                actionLabel: strings.OK,
                cancelLabel: strings.CANCEL,
                faIcon: faInfo,
            });
        }
    }

    public getResources = async (reguestMethod: string, url: string, options: any) => {
        try {

            const method: IRequestMethod = (reguestMethod || "get").toUpperCase() as any;
            if (method !== "GET") {
                this.modifyQueryExecuted = true;
            }

            this.log(`Loading resource ${method} '${url}'`);


            let prefix: string = null;
            if (url.startsWith(":test:")) {
                url = url.substring(6);
                if (testPrefix && ["localhost", "127.0.0.1"].includes(window.location.hostname)) {
                    prefix = testPrefix;
                }
            }

            const data = url === "/rest/users/$" && method === "GET"
                ? { data: this.context.user, status: 200 }
                : await GenericDassQuery(url, {
                    data: options && options.body,
                    headers: options && options.headers,
                    method, prefix,
                });
            return { ok: data.status >= 200 && data.status <= 206, data: data.data, status: data.status };
        } catch (e) {
            console.log("Error loading resource '" + url + "'", e.message);
            return { ok: false, data: e.message, status: e.status };
        }
    };



    public downloadAsFile = async (value: string, filename: string) => {
        await constants.wait;
        const response = await fetch("/download_storage", {
            credentials: "same-origin",
            headers: headerContentTypeJson,
            method: "POST",
            body: JSON.stringify({ filename, value }),
        });
        if (response.status === 401) { // the status should be changed!!!
            window.location.href = '/app/signout?resignin';
        }
        if (response.status === 200) {
            window.location.href = await response.text();
        }
    }




    public componentWillUnmount() {
        // document.removeEventListener("mousedown", this.handleClickOutside);
        clearInterval(this.develMonitorTimer);
    }

    // What should this function do??
    public handleClickOutside = (e: any) => {
        this.log("Inside handleClickOutside", {...e});
    };

    public closeModal = (success?: boolean) => {
        this.setState({ ShowModal: false });
        if (this.props.OnClose) {
            this.props.OnClose(success ? this.state.currentValues : null, success || this.modifyQueryExecuted);
        }
    };



    public updateObjStates = async (state: IUiSchemaUpdateState) => {
        
        if (state.close && !this.state.close) {
            this.setState({ ShowModal: false });
            if (this.props.OnClose) {
                this.props.OnClose(state.success ? this.state.currentValues : null, state.success || this.modifyQueryExecuted);
            }
        }

        if (state && state.debug && this.debugLogBuf) {
			// When debugging is enabled first time, we dump the buffer of the first log messages that was recorded up till now
            for (const db of this.debugLogBuf) {
                console.log(...db);
            }
            this.debugLogBuf = null;
        }

        this.setState(state as any);
    };

    public componentDidUpdate(prevProps: Readonly<ISchemaModalProps>, prevState: Readonly<ISchemaModalState>, snapshot?: any): void {
        if (!this.state.ShowModal){
            this.props.OnClose(this.state.currentValues, true);
        }
        if(this.props.OnChange &&  prevState.currentValues !== this.state.currentValues && prevState.ShowModal === this.state.ShowModal) {
            this.props.OnChange(this.state.currentValues);
        } 
    }
   
    // helpLinkCallback is invoked when the user press the context help link.
    // As we need to be authenticated when accessing the documentation in the DASS
    // we first get the token from the DASS, then append it as an query to the 
    // help link, then finally "click" the link.
    public helpLinkCallback(link: string) {
        this.getResources("get", "/rest/users?get_doctoken=true", {}).then((tok) => {

            const [root,hash] = link.split("#");
            const [path,query] = root.split("?");
            const q = (query || "") + (tok.data.token ? (query ? "&t=" : "t=") + tok.data.token : "");

            if (tok.ok) {
                this.linkRef.current.href = path + (q ? "?" + q : "") + (hash ? "#" + hash : "");
                this.linkRef.current.click();
            }
        });
        return false;
    }



    public render() {

        const dict = {
			"false": strings.NO,
			"true": strings.YES,
            "click_to_unlock": strings.CLICK_TO_UNLOCK,
            "cancel": strings.CANCEL,
		};

		const body = (
			this.state.Loading
			?
				<Col sm={12} className="LoaderWrapper">
					<i className="fas fa-spinner fa-spin fa-5x"></i>
				</Col>
			:
            <SchemaPanel
                ref={this.schemaRef}
                jsonSchema={this.state.jsonSchema}
                object={this.state.currentValues}
                oldObject={this.state.oldValues}
                errors={this.state.objectErrors}
                readOnly={this.state.readOnly}
                activeTab={this.state.activeTab}
                extensions={this.state.extensions}
                textMarkerExtensions={this.state.textMarkerExtensions}
                updateState={this.updateObjStates}
                getResources={this.getResources}
                showMessage={this.showMessage}
                loadDataOnOpen={this.props.loadDataOnOpen || false}
                localeDictionary={dict}
                libExtensions={this.state.libExtensions}
                debug={this.state.debug}
                apply={this.state.apply}
                log={this.log}
                getSettings={this.getSettings}
                helpLinkCallback={(link) => this.helpLinkCallback(link)}
                defaultLayoutOptions={{
                    titleLayout: "left",
                    descriptionLayout: "popup",
                    componentLayout: "right",
                    titleWidthPercent: 40,
                    numColumns: 0,
                    cardStyle: "normal",
                    panelLayout: "masonry",
                    cardSize: "col-2",
                }}
            />
		);

        // This is not really clean. But the problem is that when calling this function, the state of the SchemaPanel
        // has not yet been updated, so it can't genereate the buttons based on its own inner state.
        const controlButtons = this.schemaRef?.current
            ? this.schemaRef?.current.getControlButtons(this.state.jsonSchema,
                        this.readOnlyMode,
                        this.state.currentValues,
                        this.state.objectErrors, this.state.readOnly,
                        this.isLocalhost || this.argDebug, this.state.debug)
            : [];

        if (this.props.type === "modal") {
            return this.renderModal(body, controlButtons);
        } else {
            return this.props.breadCrumbArr ? this.renderFullPage(body, controlButtons) : this.renderPage(body, controlButtons);
        }
    }



    public renderModal(body: JSX.Element, controlButtons: ReactNode) {

        const uiSchema = this.state.jsonSchema?.$uiSchema;

        return (
            <div>
                <a href={"#"} ref={this.linkRef} target="_blank" rel="noopener noreferrer" style={{display: "none"}}/>
                <Modal
                    show={this.state.ShowModal}
                    onHide={() => this.closeModal(false)}
                    className="schema-modal-content"
                    size="xl"
                    fullscreen={this.state.modalFullScreen}
                    backdrop="static"
                >
                    <Modal.Header closeButton={true} className="schema-modal-header">
                        <Modal.Title>
                            {uiSchema && uiSchema.modal && uiSchema.modal.title || ""}
                        </Modal.Title>
                    </Modal.Header>


                    <Modal.Body className="schema-modal-body">
                        {body}
                    </Modal.Body>


                    <Modal.Footer className="d-flex justify-content-between schema-modal-footer">
                        <div className="buttons">
                            {controlButtons}
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }



    // renderPage()
    // This render function is called when the schema modal type is set to "page". I contains all the outer
    // HTML to render the actual body of the schema, but inside a page

    public renderPage(body: JSX.Element, controlButtons: ReactNode) {

        return (
            <Container className="schema-detail-page" style={{width: '100%'}}>
                <a href={"#"} ref={this.linkRef} target="_blank" rel="noopener noreferrer" style={{display: "none"}}/>
                <div className="header">
                    <div className="buttons">
                        {controlButtons}
                    </div>
                </div>
                <div className="body">
                    {body}
                </div>
            </Container>
        );
    }

    public renderFullPage(body: JSX.Element, controlButtons: ReactNode) {

        return (<div className={`child-tab-wrapper`} >
            <a href={"#"} ref={this.linkRef} target="_blank" rel="noopener noreferrer" style={{display: "none"}}/>
            <div className="mx-0 d-lg-flex border-bottom border-1 mb-2 " >
                <div className="col-lg-8 col-md-auto col-xs-12">
                    {this.props.breadCrumbArr && <BreadcrumbComponent breadCrumbArr={this.props.breadCrumbArr}  />}
                </div>
            </div> 
            <div className="d-flex flex-column flex-grow border-2 border-primary" style={{height: '100%'}}>

                { this.renderPage(body, controlButtons) }                

            </div>
        </div>);
    }


}