// tslint:disable:max-line-length
import React, { ReactNode } from "react";

import { IJsonSchemaObject, IUiSchema, IUiSchemaDataResource, IUiSchemaObject, IUiSchemaLayoutOptions, IUiSchemaPanelLayoutOptions, IUiSchemaDropFile } from "./UiJsonSchemaTypes";
import {  ISchemaOptions, evalString, evalExpr, getObjectValues, ISchemaLib, proxyClone, updateConditionalSchema, evalSchemaElem, IInnerStates, IExprObjects } from "./SchemaTools";
import { AuditTranslation, translationMap } from "./SchemaTranslationAudit";
import { ISchemaFormProps } from "./SchemaForm";

// TODO:
// - add transformers. More convenient way to transform on load and apply. For example for map <-> array etc.
// - apply defaults after load
// - track changes
// - change and error per card and panel



// Controllers


export interface IUiSchemaUpdateState {
	currentValues?: any;
	objectErrors?: any;
	oldValues?: any;
	activeTab?: string;
	apply?: boolean;
	close?: boolean;
	success?: boolean;
	readOnly?: boolean;
	debug?: boolean;
}

export interface IUiSchemaSetValueResult {
	setValues?: any;	// set values and oldValues without merge
	oldValues?: any;	// merge properties into oldValues
	values?: any;		// merge properties into values
	errors?: any;		// merge properties into errors
	value?: any;		// merge [key] into values
	activeTab?: string;	// set active tab
	apply?: boolean;
	close?: boolean;
	success?: boolean;
	readOnly?: boolean;
	debug?: boolean;
}

export interface IColumnElem {
	elem: ReactNode;
	options: {
		width?: number;
	};
}

interface IProfilingStat {
	min: number;
	max: number;
	total: number;
	avg: number;
	cnt: number;
}


interface IValueUpdate {
	value: any;
}

export interface IEmbedObjectOptions {
	useFlex?: boolean;
	isContainer?: boolean;
}

export interface IUiSchemaElemArgs {
	key: string;
	fullkey: string;
	elem: IJsonSchemaObject;
	uiElem: IUiSchemaObject;
	readOnly: boolean;			// composite readOnly state, incl. modal readonly state
	elemReadOnly: boolean;		// readOnly state of only object.
	required: boolean;
	title: string;
	description: string;
	helpLink: string;

	layoutOptions?: IUiSchemaLayoutOptions;
	value: any;
	values: {
		[key: string]: any;
	};
	error: any;
	errors: any;
	enums: any[];
	enumLabels: {
		[key: string]: any;
	};
	update: (value: IValueUpdate) => void;
	dropFile?: IUiSchemaDropFile;

	type: string;

	objects: IExprObjects;
	embedObject: (obj: ReactNode, options?: IEmbedObjectOptions) => ReactNode;
	getSettings: (scope: string) => any;
	stringToComponent: (text: string) => ReactNode;
}

interface IListener {
	keys?: string[];
	tabs?: string[];
	handle: () => void;
}


type ComponentHandler = (args: IUiSchemaElemArgs) => ReactNode;

interface IComponentHandlers {
	[name: string]: ComponentHandler;
}
interface ITextMarkerHandlers {
	[name: string]: (key: string, style: any) => ReactNode;
}

interface ISchemaModalState {
	jsonSchema: IJsonSchemaObject;
	componentHandlers: IComponentHandlers;
	textMarkersHandlers: ITextMarkerHandlers;
	activeTab: string;


	listeners: IListener[] | null;

	oldValues: any;
	values: any;
	objectErrors: any;
	schemaOptions: ISchemaOptions;
	lib: any;
}

interface ISchemaLocaleDictionary {
	"true": string;
	"false": string;
	click_to_unlock: string;
	cancel: string;
}

interface ISchemaCardList {
	title?: string;
	jsxElements: ReactNode[];
}

export interface ISchemaModalProps {
	jsonSchema: IJsonSchemaObject;
	oldObject: any;
	object: any;
	errors: any;
	updateState: (state: IUiSchemaUpdateState) => void;
	getResources: (method: string, url: string, options: any) => Promise<{ ok: boolean, data: any, status?: number }>;
	showMessage: (type: "success" | "error" | "confirm", message: string) => Promise<boolean>;

	loadDataOnOpen: boolean;
	readOnly: boolean;
	extensions?: IComponentHandlers;
	textMarkerExtensions?: ITextMarkerHandlers;
	libExtensions?: ISchemaLib;
	activeTab: string;
	localeDictionary: ISchemaLocaleDictionary;
	lang?: string;			// language
	defaultLayoutOptions: IUiSchemaPanelLayoutOptions;
	debug?: boolean;
	apply: boolean;
	log: (...args: any) => void;
	getSettings: (scope: string) => any;
	helpLinkCallback?: (link: string) => void;
}


type FormComponent = React.FC<ISchemaFormProps>;


export const registeredExtensionHandlers: IComponentHandlers = {};
export const registeredExtensionComponents: { [component: string]: React.FC<any> } = {};
export const registeredExtensionFormsComponents: { [component: string]: FormComponent } = {};


export function registerComponentHandler(type: string, handler: ComponentHandler) {
	registeredExtensionHandlers[type] = handler;
}
export function registerExtensionComponent(type: string, component: any) {
	registeredExtensionComponents[type] = component;
}
export function registerExtensionFormComponent(name: string, func: FormComponent) {
	registeredExtensionFormsComponents[name] = func;
}


export default class SchemaController extends React.Component<ISchemaModalProps, ISchemaModalState> {

	public busyWithResources = 0;
	public profiling: { [func: string]: IProfilingStat } = {};
	public instantValues: any;
	public intervalTimers: NodeJS.Timer[] = [];
	public innerStates: IInnerStates = {};

	// This object is updated at the beginning of each render cycle in top of render() and for each update with updateValues()
	public objects: IExprObjects;
	public modalReadOnly: boolean;
	public deferredUpdate: Array<() => void>;

    constructor(props: ISchemaModalProps) {
        super(props);

		const lib: ISchemaLib = {
			...this.props.libExtensions,
			copyTextToClipboard: (text: string) => navigator.clipboard.writeText(text),
			log: (...args: any) => this.log(...args),
			showMessage: (type: "success" | "error" | "confirm", msg: string) => this.props.showMessage(type, msg),
		}


		const schemaOptions: ISchemaOptions = { treatNullAsUndefined: true, useDefaults: true, contOnError: true, skipOnNullType: false, };
		const jsonSchema = this.props.jsonSchema || {};
		const uiSchema = jsonSchema.$uiSchema;
		const values = proxyClone(this.props.object || {}, jsonSchema);
		const oldValues = proxyClone(this.props.oldObject || {}, jsonSchema);
		const objects = { values, oldValues, jsonSchema, errors: {}, uiSchema };
		const objectErrors = this.checkObject(objects, lib, schemaOptions);

        this.state = {

			componentHandlers: {
/*
				boolean: this.addBoolean,
				number: this.addInput,
				integer: this.addInput,
				string: this.addInput,
				radio: this.addRadioButton,
				select: this.addDropdown,
				"object-json": this.addInput,
*/
				...registeredExtensionHandlers,
				...this.props.extensions,
			},
			textMarkersHandlers: {
				...this.props.textMarkerExtensions,
			},
			listeners: null,
			schemaOptions,
            activeTab: props.activeTab,
            jsonSchema,
			oldValues,
			values,
            objectErrors,
			lib
        };
		this.deferredUpdate = [];

	}

	public log = (...args: any) => {
		this.props.log(...args);
	}



	public logTime(func: string, time: number) {
		const profile = this.profiling[func] = this.profiling[func] || {} as IProfilingStat;
		profile.cnt = (profile.cnt || 0) + 1;
		profile.min = profile.min == null || time < profile.min ? time : profile.min;
		profile.max = profile.max == null || time > profile.max ? time : profile.max;
		profile.total = (profile.total || 0) + time;
		profile.avg   = profile.total / profile.cnt;
	}

	public async applySync() {

		const props = this.props;
		const jsonSchema = this.props.jsonSchema;
		const uiSchema: IUiSchema = jsonSchema.$uiSchema || {};
		let status = true;

		for (const datres of uiSchema.dataResources || []) {
			// exec immediately for onApply handlers.
			if (datres.triggerOnApply) {
				if (!await this.handleResource(datres, props, jsonSchema)) {
					status = false;
				}
			}
		}
		return status;
	}


	public checkObject(objects: IExprObjects, lib: ISchemaLib, schemaOptions: ISchemaOptions) {

		if (this.busyWithResources) {
			return {};
		}

		const ts0 = Date.now();

		const schema = objects.jsonSchema || {};
		const errObj = evalSchemaElem(schema, schema, objects.values, this.innerStates,
										{ ...schemaOptions, skipOnNullType: true }, lib, objects) || {};
		const ts1 = Date.now();
		this.logTime("checkobject", ts1-ts0);
		return errObj;
	}


	public handleNewSchemaResources(props: ISchemaModalProps, jsonSchema: IJsonSchemaObject): IListener[] {

		// schema is changed. We need to update the listeners
		const uiSchema: IUiSchema = jsonSchema.$uiSchema || {};
		const listeners: IListener[] = [];

		// First clear all existing (in any) interval timers
		for (const it of this.intervalTimers) {
			clearInterval(it as any);
		}
		this.intervalTimers = [];

		for (const datres of uiSchema.dataResources || []) {

			// For periodic triggers, create the interval timer and push the handle to the intervalTriggers array
			// so we can clear the timers again later when we close the view.
			if (datres.triggerPeriodicallyEveryNumSeconds) {
				this.intervalTimers.push(setInterval(() => {
					this.handleResource(datres, props, jsonSchema);
				}, datres.triggerPeriodicallyEveryNumSeconds * 1000));
			}

			// setup trigger
			let firstTriggerOnValue = false;
			if (datres.triggerOnValuesChange) {
				listeners.push({
					keys: datres.triggerOnValuesChange,
					handle: () => { this.handleResource(datres, props, jsonSchema) },
				});
				for (const key of datres.triggerOnValuesChange) {
					if (this.state.values[key] != null) {
						firstTriggerOnValue = true;
					}
				}
			}
			if (datres.triggerOnSetTabs) {
				listeners.push({
					tabs: datres.triggerOnSetTabs,
					handle: () => { this.handleResource(datres, props, jsonSchema) },
				});
				if (datres.triggerOnSetTabs.includes(this.props.activeTab)) {
					firstTriggerOnValue = true;
				}
			}

			// exec immediately for onOpen handlers.
			if (datres.triggerOnOpen || (datres.triggerOnOpenOnLoadRequest && this.props.loadDataOnOpen) || firstTriggerOnValue) {
				this.handleResource(datres, props, jsonSchema);
			}
		}

		return listeners;
	}


	private async handleResource(datres: IUiSchemaDataResource, props: ISchemaModalProps, jsonSchema: IJsonSchemaObject) {

		try {
			this.busyWithResources++;

			const lib = this.state.lib;
			const oldValues: any = getObjectValues(this.objects.jsonSchema, this.state.oldValues, { treatNullAsUndefined: true, useDefaults: false,
													contOnError: false, skipOnNullType: false }, lib, this.objects);
			const newValues: any = getObjectValues(this.objects.jsonSchema, this.state.values,    { treatNullAsUndefined: true, useDefaults: true, 
													contOnError: false, skipOnNullType: false }, lib, this.objects);

			const diffValues: any = {};
			for (const key of Object.keys(newValues)) {
				if (newValues[key] !== oldValues[key]) {
					diffValues[key] = newValues[key];
				}
			}

			this.log("values", this.state.values);
			this.log("old values", oldValues);
			this.log("new values", newValues);
			this.log("diff values", diffValues);

			let objects = { ...this.objects, newValues, diffValues };
			let readOnly = !!(this.modalReadOnly || this.props.readOnly);

			if (datres.confirmMessage) {
				const msgStr = evalString(datres.confirmMessage, lib, objects, { readOnly });
				if (!await this.props.showMessage("confirm", msgStr + "")) {
					this.busyWithResources--;
					return true;
				}
			}

			const headers = datres.contentType && { "Content-Type": datres.contentType };
			const body = datres.body ? evalString(datres.body, lib, objects, { readOnly }) : null;
			const url = evalString(datres.url, lib, objects, { readOnly });

			this.log("Resource", datres.url, url, headers, body);

			const data = url && typeof url === "string"
							? await props.getResources((datres.method || "get").toUpperCase(), url, { headers, body })
							: { ok: true, data: null };

			this.log("Resource result", url, data);

			// UPdate values again after the await
			objects = { ...this.objects, newValues, diffValues };
			readOnly = !!(this.modalReadOnly || this.props.readOnly);

			const value = data.data;
			const status = data.status;
			const lang = this.props.lang || "";
			let evtxt = "";

			// Depending if the call returns success or error, we evaluate one for the showMessageOnSuccess or showMessageOnError
			// These string are parsed with evalString and can therefor contain expressions. The normal returned value is a string,
			// however it is also possible to return an object with the following:
			//    { type: "success" | "error", message: string }
			// This way it is possible to popup an error dialog on an success and vice versa.
			// An empty return value ("" or null) will not show any message

			if (data.ok && datres.showMessageOnSuccess) {
				evtxt = ((lang && (datres as any)["showMessageOnSuccess[" + lang + "]"]) || datres.showMessageOnSuccess);
			}
			if (!data.ok && datres.showMessageOnError !== "") {
				evtxt = ((lang && (datres as any)["showMessageOnError[" + lang + "]"]) || datres.showMessageOnError) || "{{value}}";
			}
			if (evtxt) {
				const msgtxt: any = evalString(evtxt, lib, objects, { value, status, readOnly }) as string;
				if (msgtxt) {
					const type: any   = (typeof msgtxt === "object" && msgtxt.type)    ? msgtxt.type    : (data.ok ? "success": "error");
					const msg: string = (typeof msgtxt === "object" && msgtxt.message) ? msgtxt.message : msgtxt as string;
					msg && props.showMessage(type, msg);
				}
			}


			let continueOnError = false;
			if (!data.ok && datres.continueOnError != null) {
				continueOnError = typeof datres.continueOnError === "string" 
							? evalExpr(datres.continueOnError, lib, objects, { fullkey: datres.targetKey, readOnly, value, status })
							: datres.continueOnError;
			}

			let targetObj: IUiSchemaSetValueResult = {};
			if (data.ok || continueOnError) {
				targetObj = datres.setValue
						? evalExpr(datres.setValue, lib, objects, { fullkey: datres.targetKey, readOnly, value, status })
						: { value };
				this.log("setValues", datres.setValue, targetObj);
			}

			// If the IO fail we abort the apply
			// TODO: allow more control on this.
			if (!data.ok && !continueOnError) { targetObj = { apply: false, ...targetObj }; }

			this.busyWithResources--;
			this.updateValues(targetObj instanceof Promise ? await targetObj : targetObj, "", "/" + datres.targetKey);

			return data.ok;

		} catch (e) {
			this.log(e);
			this.busyWithResources--;
			return false;
		}
	}




	public updateValues(targetObj: IUiSchemaSetValueResult, keyPath: string, key: string) {

		if (targetObj instanceof Promise) {
			targetObj.then(res => this.updateValues(res, keyPath, key));
			return;
		}


		function objectMerge(dstObj: any, srcObj: any) {

			for (const k of Object.keys(srcObj)) {
				const path = (k.startsWith("/") ? k.substring(1) : (keyPath ? keyPath + "/" : "") + k).split("/");

				// Handle the .. and . operators
				for (let i = 0; i < path.length; i++) {
					if (path[i] === "..") {
						if (i > 0) { path.splice(i - 1, 2);
						} else { path.splice(i, 1); }
						i--;
					} else if (path[i] === ".") {
						path.splice(i, 1);
						i--;
					}
				}

				const key = path.pop() as string;
				let cv = dstObj;
				for (const pe of path) {
					const cve = cv && cv[pe];
					cv = cv[pe] = typeof cve === "object" ? (Array.isArray(cve) ? [...cve] : {...cve}) : {};
				}
				cv[key] = srcObj[k];
			}
			return dstObj;
		}

		const currentValues = this.instantValues ? this.instantValues : this.state.values;
		const cpValues = {...currentValues};
		const setValues = targetObj.setValues ? objectMerge({}, targetObj.setValues) : null;
		const { lib, schemaOptions } = this.state;

		const update: IUiSchemaUpdateState = Object.assign({}, { currentValues },
			targetObj.hasOwnProperty("value") && { currentValues: objectMerge(cpValues, { [key]: targetObj.value }) },
			targetObj.values              && { currentValues: objectMerge(cpValues, targetObj.values) },
			targetObj.setValues           && { currentValues: setValues, oldValues: setValues },
			targetObj.oldValues           && { oldValues:     objectMerge({...this.state.oldValues },  targetObj.oldValues) },
			targetObj.errors              && { objectErrors:  objectMerge({...this.state.objectErrors }, targetObj.errors) },

			targetObj.activeTab           && { activeTab: targetObj.activeTab },
			targetObj.apply != null       && { apply:     targetObj.apply },
			targetObj.close != null       && { close:     targetObj.close },
			targetObj.success != null     && { success:   targetObj.success },
			targetObj.readOnly != null    && { readOnly:  targetObj.readOnly },
			targetObj.debug != null       && { debug:     targetObj.debug },
		);

		this.log("Updating values", keyPath, key, targetObj, update);
		this.instantValues = update.currentValues;
		this.objects.values = proxyClone(this.instantValues, this.state.jsonSchema);
		this.objects.jsonSchema = updateConditionalSchema(this.state.jsonSchema || {}, this.objects.values, schemaOptions, lib, this.objects);

		update.objectErrors = {
			...this.checkObject(this.objects, lib, schemaOptions),
			...update.objectErrors
		};
		this.objects.errors = proxyClone(update.objectErrors, this.state.jsonSchema)

		this.props.updateState(update);
	}



	static getDerivedStateFromProps(props: ISchemaModalProps, state: ISchemaModalState) {

		const values = props.object;
		const oldValues = props.oldObject;
		const jsonSchema = props.jsonSchema;

		return {
			values,
			oldValues,
			jsonSchema,
			objectErrors: props.errors,
			activeTab: props.activeTab
		};
	}


	public extractState() {
		const {objectErrors, values, oldValues, schemaOptions, jsonSchema, lib } = this.state;
		const uiSchema: IUiSchema = jsonSchema.$uiSchema || {};

		this.instantValues   = this.state.values;
		const proxyValues    = proxyClone(values || {}, this.state.jsonSchema);
		const proxyOldValues = proxyClone(oldValues || {}, this.state.jsonSchema);
		const proxyErrors    = proxyClone(objectErrors || {}, this.state.jsonSchema);

		this.objects = { 
			jsonSchema, uiSchema, values: proxyValues, errors: proxyErrors,	oldValues: proxyOldValues
			// newValues, diffValues
		};

		this.objects.jsonSchema = updateConditionalSchema(jsonSchema, proxyValues,	schemaOptions, lib,	this.objects);

		// Determine if the schema is readonly
		this.modalReadOnly = false;
		if (uiSchema.modal?.readOnly) {
			if (typeof uiSchema.modal?.readOnly === "boolean") {
				this.modalReadOnly = uiSchema.modal?.readOnly;
			} else if (typeof uiSchema.modal?.readOnly === "string") {
				try {
					this.modalReadOnly = evalExpr(uiSchema.modal.readOnly, lib, this.objects, { schema: this.state.jsonSchema, readOnly: null } );
				} catch (e) {}
			}
		}
	}


	public componentDidUpdate(prevProps: ISchemaModalProps, prevState: ISchemaModalState) {

		// Run deferred updates
		for (const cb of this.deferredUpdate || []) {
			cb();
		}
		this.deferredUpdate = [];


		const { jsonSchema, values, lib, schemaOptions } = this.state;
		const uiSchema = jsonSchema.$uiSchema;

		let resourceTriggered = false;

		if (prevProps.debug !== this.props.debug && this.props.debug) {
			this.log("values", this.state.values);
			this.log("errors", this.state.objectErrors);
			this.log("schema", this.state.jsonSchema);
			this.log("cond-schema", this.objects.jsonSchema);
			this.log("profiling", this.profiling);
		}

		if (this.state.objectErrors == null) {
			this.props.updateState({ objectErrors: this.checkObject(this.objects, lib, schemaOptions) });
		}

		let listeners = this.state.listeners;


		if (this.props.activeTab !== prevProps.activeTab) {
			// Check triggers on tabs
			for (const listener of listeners) {
				if (listener.tabs && listener.tabs.includes(this.props.activeTab)) {
					this.log("Resource triggered on tab " + this.props.activeTab);
					resourceTriggered = true;
					listener.tabs = null;	// trigger only once
					listener.handle();
					break;
				}
			}
		}

		if (prevProps.jsonSchema !== jsonSchema || listeners == null) {
			this.log("schema update");
			listeners = this.handleNewSchemaResources(this.props, jsonSchema);
			this.setState({ listeners });
		}

		if (prevState.values !== values) {
			this.log("values updated", values);

			// check listeners
			for (const listener of listeners) {
				const cValues = this.objects.values;
				const pValues = proxyClone(prevState.values, jsonSchema);
				for (const key of listener.keys || []) {
					if (cValues[key] !== pValues[key]) {
						this.log("Resource triggered " + key + " change " + cValues[key] + "!==" + pValues[key]);
						resourceTriggered = true;
						listener.handle();
						break;
					}
				}
			}
		}

		if (this.props.apply) {
			if (!prevProps.apply) {
				for (const datres of uiSchema.dataResources || []) {
					// exec immediately for onApply handlers.
					if (datres.triggerOnApply) {
						resourceTriggered = true;
						this.log("Resource on apply triggered");
						this.handleResource(datres, this.props, jsonSchema);
					}
				}
			}

			// Finally, we close the dialog when three is no more IO ongoing
			if (!this.busyWithResources && !resourceTriggered) {
				this.updateValues({ close: uiSchema.modal?.closeOnApply !== false, success: true, apply: false }, "", "");
			}
		}
	}


    public async componentDidMount() {
        // document.addEventListener("mousedown", this.handleClickOutside);

        try {
			const jsonSchema = this.state.jsonSchema;
			const listeners = this.handleNewSchemaResources(this.props, jsonSchema);

			this.setState({listeners});

        } catch (error) {
            console.log(error);
        }
    }


    public componentWillUnmount() {
		console.log("Unmounting");
		for (const it of this.intervalTimers) {
			clearInterval(it as any);
		}
		this.intervalTimers = [];
    }



	public getFirstTabKey() {

		const uiSchema: IUiSchema = this.state.jsonSchema?.$uiSchema || {};
		if (uiSchema && uiSchema.panels) {
			for (const tabKey of Object.keys(uiSchema.panels)) {
				const tab = uiSchema.panels[tabKey];
				if (tab.title) { return tabKey; }
			}
		}
		return undefined;
	}


	public convertTextMarkers(marker: string, args: IUiSchemaElemArgs | null, key: string): ReactNode[] {
		// This function should be overridden by the extended class
		return [];
	}



	public formatText(text: string, args: IUiSchemaElemArgs | null, key?: string) {

		if (typeof text !== "string") { return null; }

		const idMatch = text.startsWith("[[@id") && text.match(/^\[\[@id([0-9]+[a-zA-Z0-9_.]*),([a-z])\]\]/);
		let orgText = text;

		if (idMatch) {        
			const id = idMatch[1];
			if (translationMap[id] != null) {
				text = orgText = translationMap[id];
			} else {
				text = orgText = text.substring(idMatch[0].length);
			}
		}


		if (text.startsWith("[[@md]]")) {
			const Markdown = registeredExtensionComponents["Markdown"];
			return Markdown ? <Markdown key={key} markdown={text.substring(7)}/> : text.substring(7);
		}

		const res: ReactNode[] = [];
		const open = "[[";
		const close = "]]";

		let idx = 0;

		function findNext(pos: number) {
			const openIdx = text.indexOf(open, pos);
			const closeIdx = text.indexOf(close, pos);

			if (openIdx >= 0 && (closeIdx < 0 || closeIdx > openIdx)) { return { pos: openIdx, tok: open }; }
			if (closeIdx >= 0) { return { pos: closeIdx, tok: close }; }
			return null;
		}

		for (;;) {

			let nest = 1;
			let pos = 0;
			const starttok = findNext(pos);
			if (starttok == null) {
				if (text) { res.push(text); }
				break;
			}

			if (starttok.tok === close) { throw new Error("unexpected closing " + close)}
			pos = starttok.pos + 2;
			let nexttok: { pos: number, tok: string } | null;
			do {
				nexttok = findNext(pos);;
				if (nexttok == null) { throw new Error("no closing " + close)}
				if (nexttok.tok === close) { nest--; }
				if (nexttok.tok === open) { nest++; }
				pos = nexttok.pos + 2;

			} while (nest > 0);

			if (text.substring(0, starttok.pos)) { res.push(text.substring(0, starttok.pos)); }
			for (const elem of this.convertTextMarkers(text.substring(starttok.pos + 2, nexttok.pos), args,
												(key || args?.key) + "mrk" + idx++)) {
				res.push(elem);
			}

			text = text.substring(nexttok.pos + 2);
		}

		if (idMatch) {
			const id = idMatch[1];
			const status = idMatch[2];
			return <AuditTranslation id={id} status={status} text={orgText}>{res}</AuditTranslation>;
		} else {
			return res;
		}
	}


	public parseAndFormatText = (text: string, key: string) => {

		if (!text) { return null; }
		const readOnly = !!(this.modalReadOnly || this.props.readOnly)
		const txt = evalString(text, this.state.lib, this.objects, { readOnly }) + "";
		const jsx = this.formatText(txt, null as any, key);

		return jsx;
	}




	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public addTabElem(tabkey: string, isActive: boolean, hasError: boolean, title: ReactNode, onClick: () => void) {
		// Should be overriden by extended class
		return <div></div>;
	}


    public getTabItems(activeTabKey: string, jsonSchema: IJsonSchemaObject) {
        const { objectErrors, lib } = this.state;
        const uiElems: JSX.Element[] = [];
		const uiSchema: IUiSchema = jsonSchema?.$uiSchema || {};
		const objects = this.objects;
		const readOnly = !!(this.modalReadOnly || this.props.readOnly);


		if (!uiSchema || !uiSchema.panels) { return []; }

        for (const tabKey of Object.keys(uiSchema.panels)) {
            const tab = uiSchema.panels[tabKey];
			if (tab.hidden && evalExpr(tab.hidden, lib, objects, { readOnly })) { continue; }

			if (tab.title) {

				let hasElements = false;
				let hasError    = false;

				for (const cardKey of tab.cards) {
					const card = uiSchema.cards && uiSchema.cards[cardKey];
					if (card?.hidden && evalExpr(card.hidden, lib, objects, { readOnly })) { continue; }

					for (const fullkey of card?.properties || []) {

						if (!hasElements) {
							let schema: IJsonSchemaObject | undefined = jsonSchema;
							let elemPresent = true;

							for (const key of fullkey.split(/[/]/)) {
								schema = schema?.properties && schema.properties[key];
								if (!schema) { elemPresent = false; break; }
							}

							if (schema?.$uiSchemaObject?.hidden != null) {
								const hidden = schema.$uiSchemaObject.hidden;
								if (hidden === true || (typeof hidden === "string" && 
												evalExpr(hidden, lib, objects, { fullkey, readOnly, schema }))) {
									elemPresent = false;
								}
							}

							hasElements ||= elemPresent;
						}

						if (objectErrors && !hasError) {
							let elemHasError = true;
							let obj          = objectErrors;
							for (const key of fullkey.split(/[./]/)) {
								obj = obj[key];
								if (!obj) { elemHasError = false; break; }
							}
							hasError ||= elemHasError;
						}
					}
				}
				if (!hasElements) { continue; }

				const lang = this.props.lang;
				const title: string = (lang && (tab as any)["title[" + lang + "]"]) || tab.title;
				const titleText = title && evalString(title, lib, objects, { readOnly }) + "";
				const titleObj = titleText && this.formatText(titleText, null, tabKey);
				uiElems.push(this.addTabElem(tabKey, activeTabKey === tabKey, hasError, titleObj,
											() => this.props.updateState({ activeTab: tabKey })));
			}
        }

        return uiElems;
    }



	public embedArrayContainer(title: string, fullkey: string,  schema: IJsonSchemaObject,
								elements: ReactNode, add: () => void, boxType: "embox" | "table" | "accordion" | "card") {
		// Override in extended class
		return <div>{elements}</div>;
	}

	public embedArrayElement(key: string, element: ReactNode, rem: () => void, add: () => void) {
		// Override in extended class
		return <div>{element}</div>;
	}

	public embedArrayElementObject(args: IUiSchemaElemArgs, elements: ReactNode, boxType: "embox" | "table"| "accordion",
								   rem: () => void, add: () => void) {

		// Override in extended class
		return <div>{elements}</div>;
	}



	public embedObject(args: IUiSchemaElemArgs, obj: ReactNode, options?: IEmbedObjectOptions) {
		// Override in extended class
		return <div>{obj}</div>;
	}



	public addRowWithColumns(numColumns: number, columnsElems: IColumnElem[]) {
		// Override in extended class
		return <div></div>;
	}

	/**
	 * renderSchema
	 * Main render function to generate a composite react component created based on
	 * the schema.
	 *
	 * @param card - name of card in UISchema to be rendered
	 * @returns React component
	 */



	public renderSchema(card: string, rootJsonSchema: IJsonSchemaObject, layoutOptions: IUiSchemaPanelLayoutOptions) {
		const uiElems: ReactNode[] = [];
		const cards: ISchemaCardList[] = [{ jsxElements: uiElems }];
        const uiSchema: IUiSchema = rootJsonSchema?.$uiSchema || {};
		const { lang } = this.props;
		const lib = this.state.lib;
		const oldValues = this.state.oldValues;

		const valuesProxy = this.objects.values;
		const errorsProxy = this.objects.errors;
		let hasError = false;

        if (!rootJsonSchema) { return { hasError, cards: [] }; }

		const cardProps = uiSchema && uiSchema.cards && uiSchema.cards[card];
        const keys = cardProps?.properties;

		const parseObject = (keys: string[], rootpath: string,
							 baseJsonSchema: IJsonSchemaObject,
							 layoutOptions: IUiSchemaPanelLayoutOptions,
							 pushElem: (elem: ReactNode, uiElem: IUiSchemaObject) => void) => {

            for (const keygrp of keys) {
				const patharr = keygrp.replace(/[.]/g, "/").split("/");			// for the MODEL we convert . to /
				const key = patharr.pop() as string;
				const path = patharr.join("/");
				let   keypath = rootpath + (rootpath && path ? "/" : "") + path;
				let   fullkey  = (keypath ? keypath + "/" : "") + key;


				const values = valuesProxy[keypath + "?"];
				const errors = errorsProxy[keypath + "?"];

				// now resolve the schema object
				const spatharr = keygrp.split("/");			// For the SCHEMA we keep the . separated elements in the key
				const skey = spatharr.pop() as string;
				let   jsonSchema = baseJsonSchema;

				for (const pkey of spatharr) {
					jsonSchema = (jsonSchema?.properties || {})[pkey] || {};
				}
				const properties = jsonSchema.properties || {};
                const elem = properties[skey];
				if (elem == null) { continue; }

				const uiElem = (elem || {}).$uiSchemaObject || {};
				const required = !!(jsonSchema.required && jsonSchema.required.includes(key));
				const args = getArgs(elem, uiElem, layoutOptions, keypath, key, values, errors, required);
				if (!args) { continue; }

				// Process the object. If it is an ARRAY we will loop over all the elements. Further if it is an array of Objects
				// we will process the object properties directly.

				if (elem.type === "array") {
					if (!elem.items) {
						this.log("Missing items in", elem);
						continue;
					}

					const arrControls = (uiElem.editArrayControls && (Array.isArray(uiElem.editArrayControls) ? uiElem.editArrayControls : [uiElem.editArrayControls])) || [];
					const itemElem    = elem.items;
					const itemUiElem  = itemElem.$uiSchemaObject || {};
					const objects     = { values, errors, uiSchema, jsonSchema, oldValues };
					const arrayElems: ReactNode[] = [];
					const arrayValues = valuesProxy[fullkey + "?"] || [];		// FIXME: how is this supposed to work 
					const arrayErrors = errorsProxy[fullkey + "?"] || [];
					const editArray = typeof uiElem.editArray === "string" ?
										evalExpr(uiElem.editArray, lib, objects, 
										{ fullkey: args.fullkey, value: args.value, readOnly: args.readOnly, error: args.error, schema: elem }) :
										uiElem.editArray;

					const boxType = itemUiElem.type === "table" ? "table" : (itemUiElem.type === "card" ? "card" : "accordion");
					const arrLayoutOptions = {...layoutOptions};
					if (boxType === "table") {
						arrLayoutOptions.titleLayout = "none";
						arrLayoutOptions.descriptionLayout = "popup";
					}

					// This function will update a __acc_ prefixed control variable to show the
					// active entry in the array accordion
					const setActive = (activeArrayKey: string) => {
						if (boxType === "accordion") {
							const acckey = "__acc_" + fullkey.replace(/[/]/g, ".");
							this.updateValues({ values: { [acckey]: activeArrayKey }}, "", "" );
						}
					}

					const canInsert = editArray && arrControls.includes("insert") && !args.readOnly && (elem.maxItems == null || arrayValues.length < elem.maxItems)
					const canDelete = editArray && arrControls.includes("delete") && !args.readOnly && (elem.minItems == null || arrayValues.length > elem.minItems)

					for (let idx = 0; idx < arrayValues.length; idx++) {

						const arElem   = (elem.itemsArray && elem.itemsArray[idx]) || elem.items;
						const arUiElem = itemElem.$uiSchemaObject || {};

						const arFullkey = (keypath ? keypath + "/" : "") + key + "/" + idx;
						const ridx = idx;
						const deleteHandle = () => {
							const arr = [...args.value];
							arr.splice(ridx, 1);
							args.update({ value: arr });
						};
						const insertHandle = () => {
							const arr = [...args.value];
							arr.splice(ridx, 0, itemElem.type === "object" ? {} : undefined);
							args.update({ value: arr });
							setActive(String(ridx));
						};
						const arrayArgs = getArgs(arElem, arUiElem, layoutOptions, (keypath ? keypath + "/" : "") + key, idx, arrayValues, arrayErrors, required);
						if (!arrayArgs) { continue; }


						if (arElem.type === "object") {

							// this.log("Array obj ", key, idx, arrayValues, path);

							const arrayJsxElemObjets: ReactNode[] = [];
							parseObject(Object.keys(arElem.properties || {}),	// keys of object
										arFullkey,								// absolute path of the object
										arElem,									// schema
										arrLayoutOptions,
										(jsxElem, uiUlem) => arrayJsxElemObjets.push(jsxElem));

							if (boxType === "card") {

								// TODO: add controls (add, delete, etc)
								cards.push({ jsxElements: arrayJsxElemObjets });

							} else {
								arrayElems.push(this.embedArrayElementObject(arrayArgs, arrayJsxElemObjets, boxType,
																canDelete && deleteHandle, canInsert && insertHandle));
							}
						} else {

							if (arrayArgs && arrayArgs.type && this.state.componentHandlers[arrayArgs.type]) {
								const obj = this.state.componentHandlers[arrayArgs.type](arrayArgs);

								if (obj && !(obj as JSX.Element).key) { console.log("Missing key", args); }

								obj && arrayElems.push(this.embedArrayElement(idx + "", obj, canDelete && deleteHandle,
																			  canInsert && insertHandle));
							}
						}

					}

					if (boxType !== "card") {
						// Prepare add new array element handle
						const addHandle = () => {
							args.update({ value: [...(args.value || []), itemElem.type === "object" ? {} : undefined] });
							setActive((args.value || []).length);
						};
						const canAppend = editArray && arrControls.includes("append") && !args.readOnly && (elem.maxItems == null || arrayValues.length < elem.maxItems);

						// Wrap array in container and then in object embed
						const arrayContainer = this.embedArrayContainer("", fullkey, itemElem, arrayElems, canAppend && addHandle, boxType);
						const arrayObj = this.embedObject(args, arrayContainer, { isContainer: true });
						arrayObj && pushElem(arrayObj, uiElem);
					}
					
				} else {

					// The object is NOT an array, so we just invoke directly the component handler.

					if (args && args.type && this.state.componentHandlers[args.type]) {
						const obj = this.state.componentHandlers[args.type](args);

						if (obj && !(obj as JSX.Element).key) { console.log("Missing key", args); }

						obj && pushElem(obj, uiElem);
					}
				}
			}
		}



		// getArgs parse an element and generate the IUiSchemaElemArgs structure that is passed to all component
		// functions

		const getArgs = (elem: IJsonSchemaObject, uiElem: IUiSchemaObject, layoutOptions: IUiSchemaPanelLayoutOptions,
						   keypath: string, key: string | number,
						   values: any, errors: any, required: boolean) => {

			const fullkey = (keypath ? keypath + "/" : "") + key;
			const error   = errors[key];
			const objects = { values, errors, jsonSchema: rootJsonSchema, uiSchema, oldValues };

			let value = values[key];
			if (value === undefined && !this.innerStates[fullkey]?.modified) { value = elem.default };

			// Resolve readOnly states
			const evReadOnly = typeof uiElem.readOnly === "string"
								? evalExpr(uiElem.readOnly, lib, objects, { fullkey, value, error, readOnly: null, schema: elem }) : null;
			const elemReadOnly = typeof evReadOnly === "boolean" ? evReadOnly :
								(typeof elem.readOnly === "boolean" ? elem.readOnly : (this.modalReadOnly || false));
			const readOnly = typeof evReadOnly === "boolean" ? evReadOnly : (elemReadOnly || this.props.readOnly);

			// Check if this element should be hidden
			if (uiElem.hidden === true || (typeof uiElem.hidden === "string" && 
					evalExpr(uiElem.hidden, lib, objects, { fullkey, value, error, readOnly, schema: elem }))) {
				return null;
			}

			if (uiElem.getValue) {
				try {
					value = evalExpr(uiElem.getValue, lib, objects, { fullkey, value, error, readOnly, schema: elem });
				} catch (e: any) {
					this.log("error in custom getValue function for " + key + ":" + e.message);
				}
			}

			const enumElem = uiElem.getEnum
								? evalExpr(uiElem.getEnum, lib, objects, { fullkey, value: uiElem.enum || elem.enum, readOnly, schema: elem })
								: (uiElem.enum || elem.enum);
			const labels: any = {};
			if (enumElem) {
				const enumLabels = uiElem.getEnumLabels
									? evalExpr(uiElem.getEnumLabels, lib, objects, { fullkey, value: uiElem.enumLabels, readOnly, schema: elem })
									: uiElem.enumLabels;

				if (enumLabels) {
					for (const uie of enumLabels) {
						labels[uie.value + ""] = uie.label;
					}
				}
			}


			// Process titles and descriptions
			const titleLayout = uiElem?.titleLayout       || layoutOptions.titleLayout;
			const descLayout  = uiElem?.descriptionLayout || layoutOptions.descriptionLayout;
			const description = evalString((lang && (elem as any)["description[" + lang + "]"]) || elem.description || "",
											lib, objects, { fullkey, value, error, readOnly, schema: elem }) + "";
			const title       = evalString((lang && (elem as any)["title[" + lang + "]"]) || elem.title || key,
											lib, objects, { fullkey, value, error, readOnly, schema: elem }) + "";
			const helpLink = uiElem.helpLink ? evalString(uiElem.helpLink, lib, objects, 
								{ fullkey, value, error, readOnly, schema: elem }) + "" : null;


			const type = uiElem?.type || (elem.type && enumElem ? "select" : (Array.isArray(elem.type) ? elem.type[0] : elem.type)) || "";

			if (uiElem?.placeholder) {
				uiElem = {
					...uiElem,
					placeholder: evalString((lang && (uiElem as any)["placeholder[" + lang + "]"]) || uiElem.placeholder,
											lib, objects, { fullkey, value, error, readOnly, schema: elem }) + ""
				};
			}

			const elementArgs: IUiSchemaElemArgs = {
				key: key as string, 			// TODO:
				fullkey,
				elem,
				layoutOptions,
				uiElem,
				// The value parsed is normally the value, but can be also the title or description text.
				value: titleLayout === "value" ? title : descLayout === "value" ? description : value,
				values,
				title,
				description,
				helpLink,
				readOnly,
				elemReadOnly,
				required,
				error,
				errors,
				dropFile: uiElem.dropFile,
				update: (update: IValueUpdate) => {
					const ts0 = Date.now();
					let targetObj: IUiSchemaSetValueResult = { value: update.value };
					if (uiElem.setValue) {
						try {
							targetObj = evalExpr(uiElem.setValue, lib, objects, { fullkey, value: update.value, error, readOnly, schema: elem });
							this.log("setValue", targetObj, update.value);
						} catch (e: any) {
							this.log("error in custom setValue function for " + key + ":" + e.message);
						}
					}

					this.updateValues(targetObj, keypath, key + "");
					this.logTime("update", Date.now() - ts0);
				},
				enumLabels: labels,
				enums: enumElem as any,
				type,

				objects,
				embedObject: (obj, flex) => this.embedObject(elementArgs, obj, flex),
				getSettings: this.props.getSettings,
				stringToComponent: (text: string) => {
					const txt = evalString(text, lib, objects, { fullkey, value, error, readOnly, schema: elem }) + "";
					const jsx = this.formatText(txt, elementArgs);
					return jsx;
				}
			};

			// If there is a value to be auto set, we schedule it to be updated after the render is completed.
			if (uiElem.autoSet && enumElem?.length > 0 && !enumElem.includes(value)) {
				this.deferredUpdate.push(() => elementArgs.update({ value: enumElem[0] }));
			}

			if (!type || (!this.state.componentHandlers[type] && type !== "array" && type !== "object")) {
				this.log("Unknown type " + type + " for " + fullkey);
			}
			if (error) { hasError = true; }


			return elementArgs;
		};



		// Implement the column layout function that is passed to the parseObject function and is used to
		// layout the added fields in columns, depending on layoutOptions.
		const columns = layoutOptions.numColumns || 0;
		let colElems: IColumnElem[] = [];

		const pushElem = (jsxElem: ReactNode, uiElem: IUiSchemaObject) => {

			if (!(jsxElem as JSX.Element).key) { console.log("Missing key", uiElem); }
			if (columns) {
				const colBehaviour = uiElem.colBehaviour || "normal";

				if (colElems.length > 0 && (colBehaviour === "alone" || colBehaviour === "break-before"
												|| colBehaviour === "fullwidth")) {
					uiElems.push(this.addRowWithColumns(columns, colElems));
					colElems = [];
				}
				colElems.push({ options: { width: uiElem.colWidth || 1 }, elem: jsxElem});

				if (colElems.length >= columns || colBehaviour === "fullwidth" || colBehaviour === "break-after" || colBehaviour === "alone") {
					uiElems.push(this.addRowWithColumns(colBehaviour === "fullwidth" ? 1 : columns, colElems));
					colElems = [];
				}
			} else {
				uiElems.push(jsxElem);
			}
		}


		// Start scanning the object via the supplied key list.
		//
        if (rootJsonSchema && rootJsonSchema.type === "object" && rootJsonSchema.properties && keys) {
			parseObject(keys, "", rootJsonSchema, layoutOptions, pushElem);
		}

		// flush last columns
		if (colElems.length > 0) {
			uiElems.push(this.addRowWithColumns(columns, colElems));
			colElems = [];
		}

        return { hasError, cards };
    }



    public render(): ReactNode {
		// To be overridden by extended class
		return <div></div>;
    }
}
