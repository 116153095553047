import React, { lazy, Suspense } from "react";
import { ISchemaFormProps } from "./SchemaForm";
import { registerExtensionFormComponent } from "./SchemaController";

const SchemaFormMonacoEdit = lazy(() => import("./SchemaFormMonacoEdit"));

export default function LazySchemaFormMonacoEdit(props: ISchemaFormProps) {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <SchemaFormMonacoEdit {...props}/>
        </Suspense>
    );
};

registerExtensionFormComponent("monacoedit", LazySchemaFormMonacoEdit);
