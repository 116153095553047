import React,  { useContext, useEffect } from "react";
import { Badge, Container, Dropdown, Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import ReactDOM from "react-dom";
import { strings } from "../../services/Localization";

import { isMobile } from 'react-device-detect';
import { Button } from 'react-bootstrap';

import { BuildNavArray } from "./NavBuildComponets";

import { ToastContainer } from "react-toastify";
import { IConstants } from "../../types";
import { IUser } from "../../dassTypes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import userIcon from "../../../resources/images/user.png";
import adminUserIcon from "../../../resources/images/admin_user.png";
import userCustomerIcon from "../../../resources/images/user_customer.png";
import forwardUserIcon from "../../../resources/images/forward_user.png";

import AppContext from '../../context/AppContext'

import { navArray} from './NavArray';
import { faChartUser,faArrowUpRightFromSquare , faArrowRightFromBracket, faCircleInfo, faUserGear, faArrowsRepeat } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router";  
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

declare const constants: IConstants;

//const NavBar: React.FC<{}, INavbarState> = () => {

const NavBar: React.FC<{}> = (props) => {
    
    const AppContextObj = useContext(AppContext);
    
    const portalEl: HTMLElement = document.createElement("div");

    let checkUser:boolean = AppContextObj.navBarState.user !== AppContextObj.user;

    useEffect(() => {
        if(AppContextObj.navBarState.user !== AppContextObj.user) {
            AppContextObj.setNavBarState(prevState => { return {...prevState, user: AppContextObj.user}})
        }
    },[checkUser])

    
    const activateButton = (id: string) => {
        let retry = 10; // continue to try for 2 seconds
        function tryit() {
            const obj = document.getElementById(id);
            if (obj && obj.click) {
                obj.click();
            } else {
                if (retry-- > 0) {
                    setTimeout(tryit, 200);
                }
            }
        }
        // wait 1 sec the start looking for the  button.
        setTimeout(tryit, 1000);
    }

    useEffect(() => {
        async function fetch() {
            
            document.body.insertBefore(portalEl, document.body.children[0]);

            try {
                const hashObj: any = {};
                if (window.location.hash) {
                    for (const arg of window.location.hash.slice(1).split("&")) {
                        const i = arg.indexOf("=");
                        if (i > 0) {
                            hashObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                        }
                    }
                }

                if (hashObj.click) {
                    AppContextObj.setNavBarState(prevState =>  { return {...prevState, click: hashObj.click }});
                }

            } catch (error) { console.log(error); }

            await AppContextObj.CheckSystemError();

            if (AppContextObj.navBarState.click) {
                activateButton(AppContextObj.navBarState.click);
            }
        }
        
        fetch();
        return () => {
            document.body.removeChild(portalEl);
            clearTimeout( AppContextObj.SessionCheck());
            clearTimeout( AppContextObj.CheckSystemErrorTimer);
        }

    },[])


    const portalled = ReactDOM.createPortal(
        (<ToastContainer theme="colored" toastClassName="toast-container"></ToastContainer>),
        portalEl,
    );
    const user = AppContextObj.user || ({} as IUser);
    return (
        <div id="navbar-root" className="besides-data-table">
             {
            AppContextObj.isSignedIn(user) && AppContextObj.isForwardedSignIn() && AppContextObj.navBarState.forwardedSignIn && 
            <div style={{backgroundColor : "#F6C457", width: "100%", textAlign:"center", padding: "5px"}}>
                <span>You are currently impersonating user <b>{`${AppContextObj?.user?.userid}`}</b></span>
                <Button style={{marginLeft : "10px"}} variant="dark" size="sm" onClick={ () => { AppContextObj.retrnToOldUser() }}>
                    Exit
                </Button>
            </div>
        }
            {portalled}
            {!isMobile && <DesktopMenu />}
            {isMobile && <MobileMenu  />}
        </div>
    );

}

export { NavBar };


let key = 0;

function skey() { key += 1; return key; }

const DesktopMenu:React.FC<{}> =  ( ) => {
    
    return (        
        <div className="desktop-menu  ">
            <div >
                <TopNavBar   />
            </div>
            <div >
                <MenuBar />
            </div>
        </div>
    )
}


const MobileMenu:React.FC<{}> =  ( props ) => {
    
    return (
        <div className="mobile-menu d-flex flex-row flex-grow-1 bg-dark position-relative" >
             <MobileMenuBar />
        </div>
    )
}

const TopNavBarMobile:React.FC<{}> = ( props ) => { 

    
    return (
        <Navbar className="top_header_desktop navbar navbar-dark bg-dark" style={{width: '100%'}}>
                <RightMenuNav  />
        </Navbar>
    )
}




const userHasProp = (user,  prop ) => {
    
    if(user.hasOwnProperty(prop) && user[prop]) {
        return true;
    } else {
        return false;
    }
}

const MobileMenuBar:React.FC<{}> = () => {

    
    const AppContextObj = useContext(AppContext);
    
    return (
        <Navbar collapseOnSelect className="main_header_desktop flex-grow-1" expand="lg" bg="dark" variant="dark">
                <div className="d-flex flex-grow-1" style={{width: '100%'}}>
                    
                    <div className="d-flex flex-shrink-1" >
                        {AppContextObj.isSignedIn(AppContextObj.user) && <Navbar.Toggle />}
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center ">
                        <AppLogoTitle />
                    </div>
                    <div className="d-flex flex-shrink-1" >
                        <TopNavBarMobile />
                    </div>
                </div>

                {AppContextObj.isSignedIn(AppContextObj.user) && <MainNavBar />}
        </Navbar>
                    
    )
}



const MainNavBar:React.FC<{}> = ({ }) => {
    
    return (
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="main_header_desktop">
                    <BuildNavArray navArray={navArray} />
                </Nav>
            </Navbar.Collapse>
    )
}



export function cnvStorageUrl(u: string) {
    return u?.startsWith("storageid:") ? "/storagecache/" + u.substring(10) : u;
}

const AppLogoTitle:React.FC<{}> = ({ }) => {
    const appContext = useContext(AppContext);
    let user = appContext.user;
    let parentClasses = (isMobile) ? "d-flex flex-column justify-content-center align-items-start" : 'd-flex flex-row';
    const isNst = (String(location.pathname).indexOf('/nst'));
    const redirection = isNst != -1 ? "/nst/network_map" : "/app";
    const logoUrl = user?._environment?.ui_left_logo_url && typeof user._environment.ui_left_logo_url === "string"
                        ? cnvStorageUrl(user._environment.ui_left_logo_url)
                        : (constants.leftSideLogo ? constants.left_logo_file : "");

    const title = user?._environment?.ui_left_title && typeof user._environment.ui_left_title === "string"
                        ? user._environment.ui_left_title
                        : (constants.instance_title || "")


    return (
        <div className={parentClasses}>
            {logoUrl ? <a href={redirection}><img src={logoUrl} className="img-left-logo" /></a>
                     : <div className="fake_left_logo"></div>}

            <div className={`top_bar_name_instance_holder d-flex`}>

                {title &&  <div className="ms-1 instance_holder">{title.substring(0, 29)}</div>}

                {appContext.debugMessage && 
                    <div className="ms-1 instance_holder">
                        {appContext.debugMessage}
                    </div>
                }
                {user && user.account_status === "suspended" &&
                    <div className="ms-2 px-2  bg-warning rounded-pill instance_holder">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> this account is currently suspended
                    </div>
                }
                {user && user.sys_admin && user._license_error &&
                    <div className="ms-2 px-2  bg-danger rounded-pill  instance_holder">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> {user._license_error}
                    </div>
                }

            </div>
        </div>
    )
}

const MenuBar:React.FC<{}> = () => {

    const appContextObj = useContext(AppContext);

    return (
        <Navbar collapseOnSelect className="main_header_desktop px-2" expand="sm" bg="dark" variant="dark">
            <Container fluid>
            {appContextObj.isSignedIn(appContextObj.user) && <Navbar.Toggle className="pull-left" />}
            {/*<FontAwesomeIcon icon={faHamburger} />*/}
            {appContextObj.isSignedIn(appContextObj.user) && <MainNavBar />}
            </Container>
        </Navbar>

    )
}

const TopNavBar:React.FC<{}> = ( ) => {

    return (
        <Navbar className="top_header_desktop navbar navbar-dark bg-dark px-2" style={{width: '100%'}}>
            <Container fluid>
            <AppLogoTitle   />
                <RightMenuNav  />
             </Container>
        </Navbar>
    )
}




const RightMenuNav:React.FC<{}> = ({ }) => {

    const languages = {
        en: strings.BUTTON_LANG_EN,
        zh: strings.BUTTON_LANG_ZH,
        fr: strings.BUTTON_LANG_FR,
        dd: strings.BUTTON_LANG_DD,
    };

    const AppContextObj = useContext(AppContext);
    const navigate = useNavigate();

    const user = AppContextObj.user || ({} as IUser);
    const userenv = user._environment || {};

    // The NST switch is conditional and is check on rights here
    const canSwitchToNst = !user.app_uuid && Object.keys(user).filter(k => k.startsWith("omc_")).length > 0;

    // const userIsForwarded = AppContextObj.isSignedIn(user) && AppContextObj.isForwardedSignIn() && AppContextObj.navBarState.forwardedSignIn;

    // const rightMenuArr = [
    //     {
    //         type: 'dropdown',
    //         title: AppContextObj.navBarState.language === "en" ? strings.BUTTON_LANG_EN : strings.BUTTON_LANG_ZH,
    //         image: `/images/${AppContextObj.navBarState.language}_flag.png`,
    //         dropdownOptions : [
    //             {
    //                 title: strings.BUTTON_LANG_EN,
    //                 action: () => { AppContextObj.changeLanguage() },
    //                 image: `/images/${AppContextObj.navBarState.language}_flag.png`
    //             },
    //             {
    //                 title: strings.BUTTON_LANG_ZH,
    //                 action: () => { AppContextObj.changeLanguage() },
    //                 image: `/images/${AppContextObj.navBarState.language}_flag.png`
    //             }
    //         ]
    //     }
    // ]


    // Removed the flags for the moment
    //  <img src={`/images/${AppContextObj.navBarState.language}_flag.png`} className="img_language" />

    const loginId = user?.loginid ? <Badge bg="dark">{user.loginid}</Badge> : null;
    let accountType = strings.TYPE_USER;

    if (user.is_organisation) { accountType = strings.ADMIN_TYPE_ORGANIZATION;
    } else if (user.customer_admin) { accountType = strings.ADMIN_TYPE_CUSTOMER;
    } else if (user.administrator) { accountType = strings.ADMIN_TYPE_USER;
    } else if (user.is_customer) { accountType = strings.TYPE_CUSTOMER;
    }

    const userid = user?.userid?.split("/")[0] || "";
    const accounts = (user.selectable_accounts || []).filter(a => a !== userid);

    return (
        <Nav className="top_header">
            {constants.languages && constants.languages.length > 1 && (
            <NavDropdown
                title={(<span className="pull-left margin_lang">
                    { languages[AppContextObj.navBarState.language || "en"] }
                </span>)}
                id="basic-nav-dropdown"
                className="shadow_dropdown lang_dropdown">

                {constants.languages.filter(lang => languages[lang]).map((lang, idx) => (
                    <Dropdown.Item key={lang} eventKey={8.0 + idx/10} onClick={AppContextObj.changeLanguage } id={lang} >
                        {languages[lang]}
                    </Dropdown.Item>
                ))}
            </NavDropdown>)}

            {AppContextObj.isSignedIn(user) && (
                <NavDropdown
                    title={user && AppContextObj.navBarState.forwardedSignIn ? (
                        <span className="pull-left margin_lang">
                            <b id="user_dropdown_item">{user?.userid}</b>
                            <img
                                src={forwardUserIcon} className="user_icon" id="user_icon1"
                            />
                        </span>) : AppContextObj.isSignedIn(user) ? (
                        <span className="pull-left">
                        <b id="user_dropdown_item">{user?.userid}</b>
                        {(userHasProp(user, 'administrator')  || userHasProp(user, 'customer_admin')) ?
                            <img
                                src={adminUserIcon} className="user_icon" id="user_icon2"
                            /> :
                            <img src={userIcon} className="user_icon" id="user_icon2" />
                        }
                    </span>) : ""}
                    align="end"
                    id="basic-nav-dropdown"
                    className="shadow_dropdown width_mobile">
                    {AppContextObj.isSignedIn(user) && !AppContextObj.navBarState.forwardedSignIn && (
                        <Dropdown.Item className="d-flex">
                            <div><img src={userCustomerIcon} className="img_user" /></div>
                            <div>{accountType}<br/>
                            {loginId}</div>
                        </Dropdown.Item>
                    )}
                    {AppContextObj.isSignedIn(user) && AppContextObj.navBarState.forwardedSignIn &&
                        <Dropdown.Item className="align-left" eventKey={1.1} >
                            <img src={userCustomerIcon} className="img_user_forward" />
                            <div className="forwarded_user">
                                {strings.NAV_SIGNED_AS} {user.userid} <br></br>
                                {strings.formatString(strings.VIA, {
                                    originalUser: AppContextObj.navBarState.originalUser,
                                })}
                                <br></br>
                            ({user.customer_admin ?
                                strings.ADMIN_TYPE_CUSTOMER : strings.ADMIN_TYPE_USER
                            })
                        </div>
                    </Dropdown.Item>}
                    {AppContextObj.isSignedIn(user) &&
                        <Dropdown.Item
                            className="align-left user-settings"
                            eventKey={1.2}
                            onClick={() => { navigate('app/user-settings')} }
                        >
                        <FontAwesomeIcon icon={faUserGear} ></FontAwesomeIcon>
                            {strings.NAV_USER_SETTINGS}
                        </Dropdown.Item>
                    }

                    {userenv.eula_id &&
                        <>
                            <Dropdown.Item
                                className=""
                                eventKey={1.5}
                                onClick={ () => { navigate("app/eula") } }
                            >
                                <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon>
                                {strings.NAV_SOFTWARE_INFO}
                            </Dropdown.Item>
                        </>
                    }

                    {canSwitchToNst && 
                    <Dropdown.Item
                    eventKey={skey()}
                    href="#"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`nst/network_map`)                                    
                        }}
                        className="hover_menu dropdown-item">

                        <FontAwesomeIcon icon={faChartUser} ></FontAwesomeIcon>
                        {strings.NAV_SWITCH_TO_NST}
                        <span onClick={ (e) => { e.stopPropagation(); window.open("/nst/network_map") } }   className="mt-1 btn-link  float-end">
                            <FontAwesomeIcon size="xl"  icon={faArrowUpRightFromSquare} ></FontAwesomeIcon>
                        </span>
                    </Dropdown.Item>}

                    {AppContextObj.isSignedIn(user) &&
                        <Dropdown.Item
                            className="align-left user-settings"
                            onClick={ () => { AppContextObj.signOut() }}
                        >
                        <FontAwesomeIcon icon={faArrowRightFromBracket} ></FontAwesomeIcon>
                            {strings.SIGN_OUT}
                        </Dropdown.Item>
                    }


                    {accounts.map(accId => (
                        <Dropdown.Item
                            key={accId}
                            className="align-left user-settings"
                            onClick={ async () => { 
                                await AppContextObj.selectAccount(accId);
                                navigate("/app");
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowsRepeat} ></FontAwesomeIcon>
                            <Badge bg="light">{accId}</Badge>
                        </Dropdown.Item>
                    ))}

                </NavDropdown>
            )}

            {!AppContextObj.isSignedIn(user) &&
                <a className="align-left sign-in" href="/app/signin" >
                    {strings.SIGN_IN}
                </a>
            }

            {constants.rightSideLogo &&
                <NavItem className="right_logo">
                    <div className="img-right-logo">
                        <img src={constants.right_logo_file} className="img-right-logo-img" />
                    </div>
                </NavItem>
            }
        </Nav>
    )
}




