/**
* #
* loraWanMsgDecoder.js
* 
* 
* Purpose 
* -------------------------------
* 
* Purpose of this library is to decode the lorawan messages. 
* 
* A Lorawan messages have these types - 
* ["JoinReq", "JoinAccept", "UnConfUp", "UnConfDwn", "ConfUp", "ConfDown", "Reserved", "Proprietary"];
* 
* This library can decode JoinReq, JoinAccept, UnConfDwn, UnConfUp, ConfDown, ConfUp messages. This library is indedpendent
* and to be used only for decoding of the messages. Decryption is not the part of this libraray. Join accept messages decoding 
* needs decrypted message which should be passed to appropriate function.
* 
*/

var lorawanRegion = 'EU868';
var lorawanDevVersion = '1.0.2B';

var statusLAdrMaskStrs = ["ChMaskAck", "DataRateAck", "PwrAck"];
var statusRx2MaskStrs = ["ChAck", "RX2DataRateAck", "RX1DROffsetAck"];
var statusNewChanMaskStrs = ["ChFreqOk", "DrRangeOk"];
var statusPingSlotChannelStrs = ["ChanFreqOk", "DataRangeOk"];
var statusDlChannelMaskStrs = ["ChanFreqOk", "UlFreqExists"];
var statusRelayConfMaskStrs = ["SecondChFreqACK", "SecondChAckOffsetACK", "SecondChDrACK", "SecondChIdxACK","DefaultChIdxACK","CADPeriodicityACK"];
var statusFilterListMaskStrs = ["FilterListActionACK", "FilterListLenACK", "CombinedRulesACK"];



var msgtype = ["JoinReq", "JoinAccept", "UnConfUp", "UnConfDwn", "ConfUp", "ConfDown", "Reserved", "Proprietary"];

// var rateFccStrs = ["SF10BW125", "SF9BW125", "SF8BW125", "SF7BW125", "SF8BW500", "RFU", "RFU", "RFU",
//   "SF12BW500", "SF11BW500", "SF10BW500", "SF9BW500", "SF8BW500", "SF7BW500", "RFU", "RFU"];

// var txpwrFccStrs = ["30dBm", "28dBm", "26dBm", "24dBm", "22dBm", "20dBm", "18dBm", "16dBm", "14dBm", "12dBm", "10dBm"];

// var rateEtsiStrs = ["SF12", "SF11", "SF10", "SF9", "SF8", "SF7", "SF7BW250", "FSK_50kbps"];
// var txpwrEtsiStrs = ["20dBm", "14dBm", "11dBm", "8dBm", "5dBm", "2dBm"];

// var rateStrs = rateEtsiStrs;

// var txpwrStrs = txpwrEtsiStrs;


const getDecodedLorawanMsgAsText = (msg, isHex, lorawanVersion, region, revision, decryMsg?) => {
    if (!region || !revision || !lorawanVersion) {
        return "";
    }
    
    if (msg == null) {
        return;
    }
    region = region || lorawanRegion;
    lorawanVersion = lorawanVersion != null ? lorawanVersion + (revision || 'A') : lorawanDevVersion;
    if (!isHex) { msg = base64decode2(msg); } else if (typeof msg === typeof '') { msg = hexToArray2(msg) }
    if (typeof decryMsg === 'string') {decryMsg = hexToArray2(decryMsg);}
    var msgAsText = decodeMacPayload(msg, decryMsg, false, region, lorawanVersion);
    
    return msgAsText;
}


export { getDecodedLorawanMsgAsText } 

// const getDecodedLorawanMsgAsJSON = (msg, isHex, lorawanVersion, region, revision, decryMsg) => {
//   region = region || lorawanRegion;
//   lorawanVersion = lorawanVersion != null ? lorawanVersion + (revision || 'A') : lorawanDevVersion;
//   if (!isHex) { msg = base64decode2(msg); }
//   var msgAsJSON = decodeMacPayload(msg, decryMsg, true, region, lorawanVersion);
//   // console.log(json);
//   return msgAsJSON;
// }

const decodeMacPayload = (msg, decryMsg, returnJSON, region, lorawanVersion) => {
    var mtype = (msg[0] >> 5) & 7;
    var str:any = "";
    switch (mtype) {
        case 0: // join request
        str = processMsg_JoinReq(msg, mtype, decryMsg, returnJSON, region, lorawanVersion);
        break;
        case 1: // join accept
        str = processMsg_JoinAccept(msg, mtype, decryMsg, returnJSON, region, lorawanVersion);
        break;
        case 2: // unconf uplink
        case 3: // unconf downlink
        case 4: // conf uplink
        case 5: // conf downlink
        str = processMsg_Data(msg, mtype, decryMsg, returnJSON, region, lorawanVersion);
        break;
        case 6: // rejoin request
        break;
        case 7: // proprietary
        break;
    }
    return str;
}

type msgJSONType = {
    major?: any;
    appeui?: string;
    rfu?: any;
    mtype?: any;
    deveui?:string;
    encryMessage?:string;
    appnonce?: any;
    netid?: any;
    devAddr?: any;
    rx1DRof?: any;
    rx2DR?: any;
    rxDelay?: any;
    optneg?: any;
    cfList?: any;
    dir?: any;
    port?: any;
    FCtrl?: any;
    fcnt?: any;
    FoptsPresent?: any;
    options?: any;
    optionsDecoded?: any;
    macCmd?: any;
    
}

const processMsg_JoinReq = (msg, mtype, decryMsg, returnJSON, region, lorawanVersion) => {
    
    const rfu = (msg[0] >> 2) & 7;
    const appeui = readEui(msg, '', 1, 8);
    const deveui = readEui(msg, '', 9, 8);
    const devnonce = arrayToHex2(msg, '', 17, 2);
    const major = (msg[0] >> 0) & 3;
    const encryMessage = arrayToHex2(msg, " ", 0, msg.length);
    
    const msgJSON:msgJSONType = {};
    msgJSON.major = major;
    msgJSON.appeui = appeui;
    msgJSON.deveui = deveui;
    msgJSON.rfu = rfu;
    msgJSON.mtype = mtype;
    
    // let str = "major=" + major;
    let str = 'JoinReq';
    str += ', AppEUI:' + appeui;
    str += ', DevEUI:' + deveui;
    str += ', DevNonce:' + devnonce;
    str += ", len:" + msg.length;
    
    if (msg != null) {
        msgJSON.encryMessage = encryMessage;
        str += "<br>encrypted: " + encryMessage;
    }
    return returnJSON ? msgJSON : str;
}


const processMsg_JoinAccept = (msg, mtype, decryMsg, returnJSON, region, lorawanVersion) => {
    
    if (decryMsg == null) {
        return returnJSON ? {'error': 'JoinAccept: message encrypted'} : 'JoinAccept: message encrypted';
    }
    
    const rfu = (msg[0] >> 2) & 7;
    const major = (msg[0] >> 0) & 3;
    const devAddr = arrayToUint(decryMsg, 7, 4);
    const appnonce = arrayToHex2(decryMsg, '', 1, 3);
    const netid = arrayToHex2(decryMsg, '', 4, 3);
    const devAddrHex = makeDevAddrHex(devAddr);
    const rx1DRof = ((decryMsg[11] >> 4) & 7);
    const encryMessage = arrayToHex2(msg, " ", 0, msg.length);
    const dataRateStrs = getDataRatesStrs(region, lorawanVersion);
    const rx2DR = luv((decryMsg[11] & 0x0f), dataRateStrs);
    const rxDelay = decryMsg[12];
    
    const msgJSON: msgJSONType = { mtype, rfu, major, appnonce, netid, devAddr: devAddrHex, rx1DRof, rx2DR, rxDelay };

    
    // let str = "major=" + major;
    let str = 'JoinAccept';
    str += ', AppNonce: ' + appnonce;
    str += ', NetID:' + netid;
    str += ', DevAddr:' + devAddrHex;
    str += ', RX1DRof:' + rx1DRof; // changed as per ORB-4572
    if (lorawanVersion.indexOf('1.0') == 0) {
        // this was not defined till version 1.0.x
    } else {
        str += ', OptNeg:' + ((decryMsg[11] >> 7) & 1); // changed as per ORB-5599
        msgJSON.optneg = ((decryMsg[11] >> 7) & 1);
    }
    
    str += ', RX2DR: DR' + rx2DR;
    // adding devaddr to be used later
    // for https://orbiwise.atlassian.net/browse/RD-4
    str += ', RxDelay:' + decryMsg[12];
    
    let cfList = '';
    if (decryMsg.length == 33) {
        if (region != 'US915') {
            for (let i = 0; i < 5; i++) {
                cfList += (i == 0 ? 'CFList: [' : ', ') + (decryMsg[13 + i * 3] | (decryMsg[14 + i * 3] << 8) | (decryMsg[15 + i * 3] << 16)) * 100;
            }
            cfList += ']';
        } else {
            for (let i = 0; i < 5; i++) {
                cfList += (', ChMask' + i + ': [') + toHex2(decryMsg[14 + i * 2]) + toHex2(decryMsg[13 + i * 2]) + ']';
            }
            cfList += ', CFListType:' + decryMsg[28];
        }
    }
    
    msgJSON.cfList = cfList;
    str += ', ' + cfList;
    str += ", len:" + msg.length;
    
    if (msg != null) {
        msgJSON.encryMessage = encryMessage;
        str += "<br>encrypted: " + encryMessage;
    }
    return returnJSON ? msgJSON : str;
}

const  processMsg_Data = (msg, mtype, decryMsg, returnJSON, region, lorawanVersion) => {
    
    var msgJSON:msgJSONType = {};
    var rfu = (msg[0] >> 2) & 7;
    msgJSON.rfu = rfu;
    msgJSON.mtype = mtype;
    
    var up = mtype == 2 || mtype == 4;
    var dir = up ? 0 : 1;
    msgJSON.dir = dir;
    var FCtrl = msg[5] & 255;
    
    var optlen = FCtrl & 15;
    var msgLength = msg.length - 4;  // remove the MIC
    var port = (8 + optlen < msgLength) ? msg[8 + optlen] : -1;
    msgJSON.port = port;
    var devAddr = arrayToUint(msg, 1, 4);
    msgJSON.devAddr = devAddr;
    var major = (msg[0] >> 0) & 3;
    msgJSON.major = major;
    // var str = "major=" + major;
    let str = msgtype[mtype];
    
    str += ", FCtrl:" + toHex2(FCtrl);
    msgJSON.FCtrl = toHex2(FCtrl);
    if (FCtrl & 0xf0) {
        var spc = "";
        str += " [";
        if (FCtrl & 0x80) {
            str += spc + (up ? "ADR" : "RFU");
            spc = " ";
        }
        if (FCtrl & 0x40) {
            str += spc + "ADRACKReq";
            spc = " ";
        }
        if (FCtrl & 0x20) {
            str += spc + "ACK";
            spc = " ";
        }
        if (FCtrl & 0x10) {
            str += spc + (up ? "ClassB" : "FPending");
            spc = " ";
        }
        str += "]";
    }
    
    str += ", FCnt:" + arrayToUint(msg, 6, 2);
    msgJSON.fcnt = arrayToUint(msg, 6, 2);
    if (optlen > 0) {
        msgJSON.FoptsPresent = true;
        
        str += ", FOpts:";
        var optT = "";
        for (let i = 0; i < optlen; i++) {
            optT += toHex2(msg[8 + i]);
        }
        msgJSON.options = optT;
        str += optT;
        // the option field is filled with MAC commands.
        msgJSON.optionsDecoded = loraMacDecoder(msg, 8, optlen + 8, dir, false, region, lorawanVersion);
        str += ", " + msgJSON.optionsDecoded;
    }
    
    str += port >= 0 ? ", FPort:" + port : "";
    str += ", len:" + msg.length;
    
    if (port == 0 && decryMsg) {
        // MAC frame
        // already decoded..
        msgJSON.macCmd = loraMacDecoder(decryMsg, 0, decryMsg.length, dir, false, region, lorawanVersion);
        str += "<br>" + msgJSON.macCmd;
    }
    
    if (msg != null) {
        msgJSON.encryMessage = arrayToHex2(msg, " ", 0, msg.length);
        str += "<br>encrypted: " + msgJSON.encryMessage;
    }
    
    return returnJSON ? msgJSON : str;
}


var arrayToUint = function (arr, index, length) {
    var val = 0;
    var mul = 1, i;
    for (i = 0; i < length; i++) {
        val += (arr[index + i] & 255) * mul;
        mul *= 256;
    }
    return val;
};
var base64decode2 = function(str) {
    var l0 = str.length;
    var l = Math.floor((l0 * 6 + 7) / 8);
    var c, i = 0, v;
    
    var raw = new Uint8Array(l); //java.lang.reflect.Array.newInstance(java.lang.Byte.TYPE, l);
    
    for (let i = 0; i < l0; i++) {
        c = str.charCodeAt(i);
        
        if (c >= 65 && c <= 90) {
            v = c - 65;          // 'A'-'Z'
        } else if (c >= 97 && c <= 122) {
            v = c - 97 + 26; // 'a'-'z'
        } else if (c >= 48 && c <= 57) {
            v = c - 48 + 52; // '0'-'9'
        } else if (c == 43) {
            v = 62; // '+'
        } else if (c == 47) {
            v = 63; // '/'
        } else {
            break;
        }
        
        var j = Math.floor(i * 6 / 8);
        var k = (i * 6) % 8;
        
        v <<= 2;
        
        raw[j] |= v >> k;
        if (k > 2) {
            raw[j + 1] |= (v << 8) >> k;
        }
        
        //      print ("c="+c+" v="+v+" j="+j+" k="+k);
    }
    
    l = Math.floor((i * 6) / 8);
    var raw2 = new Uint8Array(l); //java.lang.reflect.Array.newInstance(java.lang.Byte.TYPE, l);
    
    for (i = 0; i < l; i++) {
        raw2[i] = raw[i];
    }
    
    return raw2;
};
var arrayToHex2 = function(array, separator, index, length) {
    var str = "", i;
    for (i = 0; i < length; i++) {
        str += ((i == 0) ? "" : separator) + toHex2(array[index + i]);
    }
    return str;
};
var toHex2 = function(v) {
    var h = (v & 255).toString(16).toUpperCase();
    return h.length < 2 ? "0" + h : h;
};
var hexToArray2 = function(str) {
    if (str == null) {
        return new Uint8Array(0);
    }
    
    var i = 0;
    var st = str.replace(/ /g, "");
    var l = Math.floor(st.length / 2);
    var raw = new Uint8Array(l); //java.lang.reflect.Array.newInstance(java.lang.Byte.TYPE, l);
    
    for (i = 0; i < l; i++) {
        raw[i] = parseInt(st.substring(i * 2, i * 2 + 2), 16);
    }
    
    return raw;
};
var arrayToHexReverse = function (array, separator, index, length) {
    var str = "", i;
    for (i = 0; i < length; i++) {
        str += ((i == 0) ? "" : separator) + toHex2(array[index + length - i - 1]);
    }
    return str;
};
var readEui = arrayToHexReverse;
/**
* Returns the hex value of devaddress
* @param {*} val
* @return {String} hex value of dev address
*/
function makeDevAddrHex(val) {
    const h = val.toString(16).toUpperCase();
    return '00000000'.substring(h.length) + h;
}



var maxEirp = [8, 10, 12, 13, 14, 16, 18, 20, 21, 24, 26, 27, 29, 30, 33, 36];

// 0 uplink, 1 downlink
var loraMacElements = [
    { tag: "ResetInd", dir: 0, val: 0x01, size: 1, dec: function (m, o, r, dV) { return " Minor:" + ((m[o] & 0x0f) == 1 ? '1' : 'RFU'); } }, // For 1.1
    { tag: "ResetConf", dir: 1, val: 0x01, size: 1, dec: function (m, o, r, dV) { return " Minor:" + ((m[o] & 0x0f) == 1 ? '1' : 'RFU'); } }, // For 1.1
    
    { tag: "LinkCheckReq", dir: 0, val: 0x02, size: 0, dec: function () { return ""; } },
    { tag: "LinkCheckAns", dir: 1, val: 0x02, size: 2, dec: function (m, o, r, dV) { return " Margin:" + m[o] + ", GwCnt:" + m[o + 1]; } },
    {
        tag: "LinkADRReq", dir: 1, val: 0x03, size: 4, dec: function (m, o, r, dV) {
            // for TXPower removing the value for now, showing only the index. 
            // As per - https://52.31.236.140:8989/fred/trace/issues/49
            const rateStrs = getDataRatesStrs(r, dV);
            const txPowerStrs = getTxPowerStrs(r, dV);
            return "DataRate:" + luv(m[o] >> 4, rateStrs) + ", TXPower:" +
            luv(m[o] & 0x0f, txPowerStrs) + ", Mask:" + (m[o + 1] + m[o + 2] * 256).toString(16) + ", ChMaskCtrl:" + ((m[o + 3] >> 4) & 7) + ", NbRep:" + (m[o + 3] & 0x0f);
            // return "DataRate:" + luv(m[o] >> 4, rateStrs) + ", TXPower:" +
            //   luv(m[o] & 0x0f, txpwrStrs) + ", Mask:" + (m[o + 1] + m[o + 2] * 256).toString(16) + ", ChMaskCtrl:" + ((m[o + 3] >> 4) & 7) + ", NbRep:" + (m[o + 3] & 0x0f);
        }
    },
    { tag: "LinkADRAns", dir: 0, val: 0x03, size: 1, dec: function (m, o, r, dV) { return "Status:" + lum(m[o], statusLAdrMaskStrs); } },
    { tag: "DutyCycleReq", dir: 1, val: 0x04, size: 1, dec: function (m, o, r, dV) { return "MaxDCycle:" + m[o]; } },
    { tag: "DutyCycleAns", dir: 0, val: 0x04, size: 0, dec: function () { return ""; } },
    {
        tag: "RXParamSetupReq", dir: 1, val: 0x05, size: 4, dec: function (m, o, r, dV) {
            return "RX1DROffset:" + ((m[o] >> 4) & 0x7) + ", RX2DR:" + ((m[o] & 0x0f)) +
            ", Frequency:" + lufreq(m[o + 1] + m[o + 2] * 256 + m[o + 3] * 65536);
        }
    },
    { tag: "RXParamSetupAns", dir: 0, val: 0x05, size: 1, dec: function (m, o, r, dV) { return "Status:" + lum(m[o], statusRx2MaskStrs); } },
    { tag: "DevStatusReq", dir: 1, val: 0x06, size: 0, dec: function () { return ""; } },
    { tag: "DevStatusAns", dir: 0, val: 0x06, size: 2, dec: function (m, o, r, dV) { return "Battery:" + m[o] + ", Margin:" + sign(m[o + 1] & 0x3f, 6); } },
    {
        tag: "NewChannelReq", dir: 1, val: 0x07, size: 5, dec: function (m, o, r, dV) {
            return "ChIndex:" + m[o] + ", Frequency:" + lufreq(m[o + 1] +
                m[o + 2] * 256 + m[o + 3] * 65536) + ", MaxDR:" + (m[o + 4] >> 4) + ", MinDR:" + (m[o + 4] & 0x0f);
        }
    },
    { tag: "NewChannelAns", dir: 0, val: 0x07, size: 1, dec: function (m, o, r, dV) { return "Status:" + lum(m[o], statusNewChanMaskStrs); } },
    { tag: "RXTimingSetupReq", dir: 1, val: 0x08, size: 1, dec: function (m, o, r, dV) { return "Delay:" + (m[o] === 0) ? 1 : m[o]; } },
    { tag: "RXTimingSetupAns", dir: 0, val: 0x08, size: 0, dec: function () { return ""; } },
    {
        tag: "TxParamSetupReq", dir: 1, val: 0x09, size: 1, dec: function (m, o, r, dV) {
            return "DlDwellTime:" + (((m[o] >> 5) & 1) == 0 ? "noLimit" : "400ms") +
            ", UlDwellTime:" + (((m[o] >> 4) & 1) == 0 ? "noLimit" : "400ms") +
            ", MaxEIRP:" + maxEirp[m[o] & 0x0f] + "dBm";
        }
    },
    { tag: "TxParamSetupAns", dir: 0, val: 0x09, size: 0, dec: function () { return ""; } },
    {
        tag: "DlChannelReq", dir: 1, val: 0x0a, size: 4, dec: function (m, o, r, dV) {
            return "ChIndex:" + m[o] + ", Frequency:" + lufreq(m[o + 1] +
                m[o + 2] * 256 + m[o + 3] * 65536);
            }
        },
        { tag: "DlChannelAns", dir: 0, val: 0x0a, size: 1, dec: function (m, o, r, dV) { return "Status:" + lum(m[o], statusDlChannelMaskStrs); } },
        { tag: "RekeyInd", dir: 0, val: 0x0b, size: 1, dec: function (m, o, r, dV) { return " Minor:" + ((m[o] & 0x0f) == 1 ? '1' : 'RFU'); } }, // For r6
        { tag: "RekeyConf", dir: 1, val: 0x0b, size: 1, dec: function (m, o, r, dV) { return " Minor:" + ((m[o] & 0x0f) == 1 ? '1' : 'Invalid LoraWAN version'); } }, // For r6
        {
            tag: "ADRParamSetupReq", dir: 1, val: 0x0c, size: 1, dec: function (m, o, r, dV) {
                return " ADR_ACK_LIMIT:" + Math.pow(2, (m[o] >> 4)) +
                ", ADR_ACK_DELAY:" + Math.pow(2, m[o] & 0x0f);
            }
        }, // For r6
        { tag: "ADRParamSetupAns", dir: 0, val: 0x0c, size: 0, dec: function (m, o, r, dV) { return ""; } }, // For r6
        { tag: "DeviceTimeReq", dir: 0, val: 0x0d, size: 0, dec: function () { return ""; } }, // for r6
        {
            tag: "DeviceTimeAns", dir: 1, val: 0x0d, size: 5, dec: function (m, o, r, dV) {
                const sec_frac = m[o + 4];
                const sec = m[o] + (m[o + 1] * Math.pow(2, 8)) + (m[o + 2] * Math.pow(2, 16)) + (m[o + 3] * Math.pow(2, 24));
                return " Seconds(since GPS epoch):" + sec + "." + sec_frac;
            }
        }, // for r6
        {
            tag: "ForceRejoinReq", dir: 1, val: 0x0e, size: 2, dec: function (m, o, r, dV) {
                const period = 32 + Math.pow(2, (m[o] >> 3) & 7); // TODO :: check for random number
                const max_retries = m[o] & 7;
                const rejoinType = (m[o + 1] >> 4) & 7;
                const dr = m[o + 1] & 0x0f;
                return " Period:" + period +
                ", Max_Retries:" + max_retries +
                ", RejoinType:" + (rejoinType < 2 ? 0 : (rejoinType == 2 ? 2 : 'RFU')) +
                ", DR:" + dr;
            }
        }, // TODO :: Implementation
        {
            tag: "RejoinParamSetupReq", dir: 1, val: 0x0f, size: 1, dec: function (m, o, r, dV) {
                return " MaxCountN:" + Math.pow(2, ((m[o] & 0x0f) + 4)) + ", MaxTimeN:" + Math.pow(2, (m[o] >> 4) + 10) + "sec";
            }
        }, // for r6
        { tag: "RejoinParamSetupAns", dir: 0, val: 0x0f, size: 1, dec: function (m, o, r, dV) { 
            //return m[o] & (1 === 0) ? 'CountOK' : '(CountOK|TimeOK)'; // commented on 07/12/21 due to always retun false value;
            return '(CountOK|TimeOK)';
        } 
    }, // for r6
    {
        tag: "PingSlotInfoReq", dir: 0, val: 0x10, size: 1, dec: function (m, o, r, dV) {
            // as per 1.0.2
            // return "periodicity:" + (m[o] >> 4) + ", data rate:" + luv(m[o] & 0x0f, rateStrs);
            // as per 1.0.3
            return "periodicity:" + (m[o] & 7);
        }
    },
    { tag: "PingSlotInfoAns", dir: 1, val: 0x10, size: 0, dec: function () { return ""; } },
    {
        tag: "PingSlotChannelReq", dir: 1, val: 0x11, size: 4, dec: function (m, o, r, dV) {
           
            return "Frequency:" + lufreq(m[o] + m[o + 1] * 256 + m[o + 2] * 65536) +
            ", MaxDR:" + (m[o + 3] >> 4) + ", MinDR:" + (m[o + 3] & 0x0f);
            // return "Frequency:" + lufreq(m[o + 1] + m[o + 2] * 256 + m[o + 3] * 65536) +
            //   ", MaxDR:" + (m[o + 4] >> 4) + ", MinDR:" + (m[m + 4] & 0x0f);
        }
    },
    {tag: "PingSlotChannelAns", dir: 0, val: 0x11, size: 1, dec: function(m, o, r, dV) {return "Status:" + lum(m[o], statusPingSlotChannelStrs);}},
    {tag: "BeaconTimingReq", dir: 0, val: 0x12, size: 0, dec: function() {return "";}},
    {tag: "BeaconTimingAns", dir: 1, val: 0x12, size: 3, dec: function(m, o, r, dV) {return "Delay:" + (m[o] + m[o + 1] * 256) + ", chan:" + m[o + 2];}},
    {tag: "BeaconFreqReq", dir: 1, val: 0x13, size: 3, dec: function(m, o, r, dV) {return "Frequency:" + lufreq(m[o + 1] + m[o + 2] * 256 + m[o + 3] * 65536);}},
    {tag: "BeaconFreqAns", dir: 0, val: 0x13, size: 0, dec: function() {return "";}},
    {
        tag: "RelayConfReq", dir: 1, val: 0x40, size: 5, dec: function(m, o, r, dV) {
            const CADPeriodicity = ["1s","500ms","250ms","100ms","50ms","20ms"];
            const SecondChAckOffset = ["0kHz","200kHz","400kHz","800kHz","1600kHz","3200kHz"];

            return "StartStop:" + ((m[o + 1] >> 5) & 0x01) 
                + ", CADPeriodicity:" + luv(((m[o + 1] >> 2) & 0x07),CADPeriodicity)
                + ", DefaultChIdx:" + ((m[o + 1] >> 1) & 0x01)
                + ", SecondChIdx:" + ((((m[o] >> 7) & 0x01) == 0) ? "No second channel is used" : "Second channel settings" )
                + ", SecondChAckOffset:" + luv( m[o] & 0x07 ,SecondChAckOffset) 
                + ", SecondChDr:" + ((m[o] >> 3) & 0x07) 
                + ", SecondChFreq:" +  lufreq(m[o + 2] + m[o + 3] * 256 + m[o + 4] * 65536) ;
        }
    },
    {
            tag: "RelayConfAns", dir: 0, val: 0x40, size: 1, dec: function (m, o, r, dV) { 
                return "Status:" + lum(m[o], statusRelayConfMaskStrs); 
        } 
    },
    {
        tag: "FilterListReq", dir: 1, val: 0x42,  dec: function(m, o, r, dV) {
        
            const euilength = m[o] & 0x1f;
            var eui = "";

            if (euilength > 0) {
                for (var i = 0; i < euilength; i++) {
                    eui += m[o + 1 + euilength - i].toString(16);
                }
            }

            return "FilterListAction:" + ((m[o] >> 5) & 0x03) 
                + ", FilterListIdx:" + (( (m[o] >> 7) & 0x01 ) + (( m[o + 1] & 0x07 ) <<1))
                + ", FilterListEui:" + eui ;
        }
    },
    {
        tag: "FilterListAns", dir: 0, val: 0x42, size: 1, dec: function (m, o, r, dV) { 
            return "Status:" + lum(m[o], statusFilterListMaskStrs); 
        } 
    },
    {
        tag: "ConfigureFwdLimitReq", dir: 1, val: 0x45, size: 5, dec: function(m, o, r, dV) {
        return "OverallReloadRate:" + ((m[o] ) & 0x7f)
            + ", GlobalUplinkReloadRate:" + (( (m[o] >> 7) & 0x01 ) + (( m[o + 1] & 0x3f ) <<1))
            + ", NotifyReloadRate:" + ( (( m[o + 1] >> 6) & 0x03) + (( m[o + 2] & 0x1f ) <<2))
            + ", JoinReqReloadRate:" + ( (( m[o + 2] >> 5) & 0x07) + (( m[o + 3] & 0x0f ) <<3))
            + ", ResetLimitCounter:" + (( m[o + 3] >> 4) & 0x03)
            + ", OverallLimitSize:" + ((m[o + 4] ) & 0x3)
            + ", GlobalUplinkLimitSize:" + ((m[o + 4] >>2 ) & 0x03)
            + ", NotifyLimitSize:" + ((m[o + 4] >> 4) & 0x03)
            + ", JoinReqLimitSize:" +  ((m[o + 4] >> 6) & 0x03) ;
        }
    },
    {
        tag: "ConfigureFwdLimitAns", dir: 0, val: 0x45, size: 0, dec: function (m, o, r, dV) { 
        return ""; 
        } 
    },
    {
        tag: "UpdateUplinkListReq", dir: 1, val: 0x43, size: 26, dec: function(m, o, r, dV) {
        
        var RootWorSKey = "";
        for (var i = 0; i < 16; i++) {
            RootWorSKey += m[o + 10 + i].toString(16);
        }
        return "UplinkListIdx:" + (m[o]  & 0x0f) 
            + ", UplinkLimitReloadRate:" + (( m[o+1]  & 0x3F ) + (( m[o + 1] & 0x07 ) <<1))
            + ", UplinkLimitBucketSize:" + ( (m[o+1] >> 6) & 0x03 ) 
            + ", DevAddr:" + m[o+5].toString(16) + m[o+4].toString(16) + m[o+3].toString(16) + m[o+2].toString(16)
            + ", WFCnt32:" + m[o + 6] + m[o + 7] * 256 + m[o + 8] * 65536 + m[o + 9] * 16777216
            + ", RootWorSKey:" + RootWorSKey ;
        }
    },
    {
        tag: "UpdateUplinkListAns", dir: 0, val: 0x43, size: 0, dec: function (m, o, r, dV) { 
        return "" ; 
        } 
    },
    {
        tag: "EndDeviceConfReq", dir: 1, val: 0x41, size: 6, dec: function(m, o, r, dV) {
        
        const euilength = m[o] & 0x1f;
        var eui = "";
        if (euilength > 0) {
            for (var i = 0; i < euilength; i++) {
                eui += m[o + 1 + euilength - i].toString(16);
            }
        }
        return "SmartEnable:" + (m[o] & 0x03) 
                + ", RelayModeActivation:" + ((m[o] >> 2) & 0x03) 
                + ", SecondChAckOffset:" + (m[o+2] & 0x07)
                + ", SecondChDr:" + ((m[o+2] >> 3) & 0x0F) 
                + ", SecondChIdx:" + ((( m[o+2]  >> 7) & 0x01  ) + (( m[o + 1] & 0x01 ) <<1))
                + ", BackOff:" + ((m[o+2] >> 1) & 0x3F) 
                + ", SecondChFreq:" + lufreq(m[o + 3] + m[o + 4] * 256 + m[o + 5] * 65536) ;
        }
    },
    {
        tag: "EndDeviceConfAns", dir: 0, val: 0x41, size: 1, dec: function (m, o, r, dV) { 
        return "" ; 
        } 
    },
    {
        tag: "NotifyNewEndDeviceReq", dir: 0, val: 0x46, size: 6, dec: function(m, o, r, dV) {
        
        return "DevAddr:" + m[o+3].toString(16) + m[o+2].toString(16) + m[o+1].toString(16) + m[o].toString(16) 
            + ", WORSNR:" + (m[o+5]  & 0x1F) 
            + ", WORRSSI:" + ((( m[o+5]  >> 5) & 0x07  ) + (( m[o + 4] & 0x0F ) <<4))  ;
        }
    },

    
    
];
var drDefaults = {
    'EU868':   ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'SF7BW250', 'FSK_50kbps', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'IMS2400': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'SF7BW250', 'FSK_50kbps', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'US915': ['SF10', 'SF9', 'SF8', 'SF7', 'SF8BW500', 'RFU', 'RFU', 'RFU', 'SF12BW500', 'SF11BW500', 'SF10BW500', 'SF9BW500', 'SF8BW500', 'SF7BW500', 'RFU'],
    'CN779': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'SF7BW250', 'FSK_50kbps', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'EU433': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'SF7BW250', 'FSK_50kbps', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'AU915': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'SF8BW500', 'RFU', 'SF12BW500', 'SF11BW500', 'SF10BW500', 'SF9BW500', 'SF8BW500', 'SF7BW500', 'RFU'],
    'CN470': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'AS923': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'SF7BW250', 'FSK_50kbps', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'AS923-2': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'SF7BW250', 'FSK_50kbps', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'AS923-3': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'SF7BW250', 'FSK_50kbps', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'KR920': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'IN865': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'RFU', 'FSK_50kbps', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU']
}

var dataRatesProfiles = {
    'EU868': { 'any': drDefaults.EU868 },
    'ISM2400': { 'any': drDefaults.IMS2400 },
    'US915': { 'any': drDefaults.US915 },
    'CN779': { 'any': drDefaults.CN779 },
    'EU433': { 'any': drDefaults.EU433 },
    'AU915': {
        'any': drDefaults.AU915,
        '1.0': ['SF10', 'SF9', 'SF8', 'SF7', 'SF8BW500', 'RFU', 'RFU', 'RFU', 'SF12BW500', 'SF11BW500', 'SF10BW500', 'SF9BW500', 'SF8BW500', 'SF7BW500', 'RFU', 'RFU'],
        '1.0.1': ['SF10', 'SF9', 'SF8', 'SF7', 'SF8BW500', 'RFU', 'RFU', 'RFU', 'SF12BW500', 'SF11BW500', 'SF10BW500', 'SF9BW500', 'SF8BW500', 'SF7BW500', 'RFU', 'RFU'],
        '1.0.2A': ['SF10', 'SF9', 'SF8', 'SF7', 'SF8BW500', 'RFU', 'RFU', 'RFU', 'SF12BW500', 'SF11BW500', 'SF10BW500', 'SF9BW500', 'SF8BW500', 'SF7BW500', 'RFU', 'RFU']
    },
    'CN470': { 'any': drDefaults.CN470 },
    'AS923': { 'any': drDefaults.AS923 },
    'AS923-2': { 'any': drDefaults["AS923-2"] },
    'AS923-3': { 'any': drDefaults["AS923-3"] },
    'KR920': { 'any': drDefaults.KR920 },
    'IN865': { 'any': drDefaults.IN865 }
};


var txPowerDefaults = {
    'EU868': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'IMS2400': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'US915': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm'],
    'CN779': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'EU433': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'AU915': ['SF12', 'SF11', 'SF10', 'SF9', 'SF8', 'SF7', 'SF8BW500', 'RFU', 'SF12BW500', 'SF11BW500', 'SF10BW500', 'SF9BW500', 'SF8BW500', 'SF7BW500', 'RFU'],
    'CN470': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'AS923': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'AS923-2': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'AS923-3': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'KR920': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    'IN865': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'MaxEIRP-16dBm', 'MaxEIRP-18dBm', 'MaxEIRP-20dBm', 'RFU', 'RFU', 'RFU', 'RFU'],
    'RU864': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU']
};


var txPowerProfiles = {
    'EU868': {
        'any': txPowerDefaults.EU868, // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0': ['20dBm', '14dBm', '11dBm', '8dBm', '5dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0.1': ['20dBm', '14dBm', '11dBm', '8dBm', '5dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU']
    },
    'IMS2400': {
        'any': txPowerDefaults.IMS2400, // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0': ['20dBm', '14dBm', '11dBm', '8dBm', '5dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0.1': ['20dBm', '14dBm', '11dBm', '8dBm', '5dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU']
    },
    'US915': {
        'any': txPowerDefaults.US915, // for 1.1B, 1.0.3A
        '1.1A': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm'],
        '1.0': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0.1': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm', '0dBm'],
        '1.0.2B': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm', 'RFU']
    },
    'CN779': {
        'any': txPowerDefaults.CN779, // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0': ['10dBm', '7dBm', '4dBm', '1dBm', '-2dBm', '-5dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0.1': ['10dBm', '7dBm', '4dBm', '1dBm', '-2dBm', '-5dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['12.15dBm', '10.15dBm', '8.15dBm', '6.15dBm', '4.15dBm', '2.15dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU']
    },
    'EU433': {
        'any': txPowerDefaults.EU433, // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0': ['10dBm', '7dBm', '4dBm', '1dBm', '-2dBm', '-5dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0.1': ['10dBm', '7dBm', '4dBm', '1dBm', '-2dBm', '-5dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['12.15dBm', '10.15dBm', '8.15dBm', '6.15dBm', '4.15dBm', '2.15dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU']
    },
    'AU915': {
        '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'MaxEIRP-16dBm', 'MaxEIRP-18dBm', 'MaxEIRP-20dBm', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'MaxEIRP-16dBm', 'MaxEIRP-18dBm', 'MaxEIRP-20dBm', 'MaxEIRP-22dBm', 'MaxEIRP-24dBm', 'MaxEIRP-26dBm', 'MaxEIRP-28dBm'],
        '1.0': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0.1': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm', '0dBm'],
        '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'MaxEIRP-16dBm', 'MaxEIRP-18dBm', 'MaxEIRP-20dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'MaxEIRP-16dBm', 'MaxEIRP-18dBm', 'MaxEIRP-20dBm', 'MaxEIRP-22dBm', 'MaxEIRP-24dBm', 'MaxEIRP-26dBm', 'MaxEIRP-28dBm', 'RFU']
    },
    'CN470': {
        'any': txPowerDefaults.CN470, // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0': ['17dBm', '16dBm', '14dBm', '12dBm', '10dBm', '7dBm', '5dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0.1': ['17dBm', '16dBm', '14dBm', '12dBm', '10dBm', '7dBm', '5dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['19.15dBm', '17.15dBm', '15.15dBm', '13.15dBm', '11.15dBm', '9.15dBm', '7.15dBm', '5.15dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU']
    },
    'AS923': {
        'any': txPowerDefaults.AS923, // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.1': ['14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    },
    'AS923-2': {
        'any': txPowerDefaults["AS923-2"], // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.1': ['14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    },
    'AS923-3': {
        'any': txPowerDefaults["AS923-3"], // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.1': ['14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['16dBm', '14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    },
    'KR920': {
        'any': txPowerDefaults.KR920, // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        '1.0': ['20dBm', '14dBm', '10dBm', '8dBm', '5dBm', '2dBm', '0dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.1': ['20dBm', '14dBm', '10dBm', '8dBm', '5dBm', '2dBm', '0dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['14dBm', '12dBm', '10dBm', '8dBm', '6dBm', '4dBm', '2dBm', '0dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU']
    },
    'IN865': {
        'any': txPowerDefaults.IN865, // for 1.1A, 1.1B, 1.0.2A, 1.0.2B
        // '1.1A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'MaxEIRP-16dBm', 'MaxEIRP-18dBm', 'MaxEIRP-20dBm', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'MaxEIRP-16dBm', 'MaxEIRP-18dBm', 'MaxEIRP-20dBm', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2': ['30dBm', '28dBm', '26dBm', '24dBm', '22dBm', '20dBm', '18dBm', '16dBm', '14dBm', '12dBm', '10dBm', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.2B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'MaxEIRP-16dBm', 'MaxEIRP-18dBm', 'MaxEIRP-20dBm', 'RFU', 'RFU', 'RFU', 'RFU'],
        // '1.0.3A': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'MaxEIRP-16dBm', 'MaxEIRP-18dBm', 'MaxEIRP-20dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU']
    },
    'RU864': {
        'any': txPowerDefaults.RU864, // for 1.1B 
        // '1.1B': ['MaxEIRP', 'MaxEIRP-2dBm', 'MaxEIRP-4dBm', 'MaxEIRP-6dBm', 'MaxEIRP-8dBm', 'MaxEIRP-10dBm', 'MaxEIRP-12dBm', 'MaxEIRP-14dBm', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU', 'RFU'],
    }
};



const getDataRatesStrs = (region, devVersion) => {
    return dataRatesProfiles[region][devVersion] || dataRatesProfiles[region]['any'];
}

const getTxPowerStrs = (region, devVersion) => {
    return txPowerProfiles[region][devVersion] || txPowerProfiles[region]['any'];
}

const lufreq = (f) => {
    return f + " [" + f / 10000 + "MHz]";
}

const luv = (v, a) => {
    return v + " [" + (v < a.length ? a[v] : "Illegal value") + "]";
}

const sign = (val, msb) => {
    return (val & (1 << (msb - 1))) ? val - (1 << msb) : val;
}

const lum = (v, a) => {
    var str = v, comma = "", i;
    
    if (v > 0) {
        str += " [";
        for (i = 0; i < 8; i++) {
            if (v & (1 << i)) {
                if (i > a.length) {
                    str += comma + "Illegal Value";
                    break;
                }
                str += comma + a[i];
                comma = "|";
            }
        }
        str += "]";
    }
    
    return str;
}

const  loraMacDecoder = (msg, offset, length, dir, fromMacCmd, r, dV) => {
    var str = "", i;
    var lme;
    while (offset < length) {
        
        lme = null;
        for (i = 0; i < loraMacElements.length; i++) {
            if (loraMacElements[i].dir == dir && loraMacElements[i].val == msg[offset]) {
                
                lme = loraMacElements[i];
                str += ((str != "") ? ", " : "") + lme.tag;
                
                if (lme.size == undefined && lme.val == 0x42) {
                    lme.size = (msg[offset + 1] & 0x1f) + 2;
                }

                if (offset + lme.size + 1 > length) {
                    return str + " - Unexpected end of message";
                }
                str += "(" + lme.dec(msg, offset + 1, r, dV) + ")";
                offset += 1 + lme.size;
                
                break;
            }
        }
        if (lme == null) {
            if (!fromMacCmd)
            return str + "unknown MAC CID";
            else {
                return str;
            }
        }
    }
    
    return str;
}

