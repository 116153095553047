import React, { 
        useState, 
        useEffect,  
        } from 'react';

import { createContext } from 'react';

import { changeDisplayOnClassName } from '../../../utils/filters'

import { setDebugValue, DEFAULT_RECORD_LIMIT } from './DataTableConsts';

import { 
        //BrowserView, 
        //MobileView, 
        //isBrowser, 
        isMobile 
    } from 'react-device-detect';

const DataTableChildContext = createContext(null);


export { DataTableChildContext }

import { ColumnType, SearchStateType  } from './DataTypes';

const tableSettings = {
    visibleColumnsIndexArr:[],
    hiddenColumnsIndexArr:[]
};

export { tableSettings  }



function DataTableCommonState ( WrappedComponent ) {

    return  ( props ) => {
            const searchStateInit = {
                dataTableOption:{},
                exportInit:0,
                refresh:false,
                countLabel:'',
                display:'',
                name: '',
                visibleColumnsArr:[],
                page: '',
                pageStates: [],
                limit: DEFAULT_RECORD_LIMIT,
                searchFields: {},
                allRowIds:[],
                selectedRowIds:[],
                inputFields:{},
                smallScreen:false,
                responsiveColumns:[],
                visibleColumns:[],
                initTableWidth:0,
                initColumnWidth:[],
                calcColumnCharLenArr:[],
                totalRecords:0,
                allowBulkActions: true,
                loading:false,
                sort: {sortField:'', sortOrder:'asc'},
                queryType:'',
                debug:false,
                scope:'',
                allCheckboxSelected:false,
                setSearchParams:null,
            } 

        const [searchState, setSearchState] = useState<SearchStateType>(searchStateInit);

        const dataTableOption = searchState.dataTableOption;
        const countLabel = searchState.countLabel;
        const exportInit = searchState.exportInit;
        const name = searchState.name;
        

        let modal = dataTableOption.hasOwnProperty('modal') ? dataTableOption['modal'] : false;

        //let [dataLoaded, setDataLoaded] = useState(0);

        let dataType = 'api';

        let data = [];

        if(dataTableOption.hasOwnProperty('dataType')) {
            dataType = dataTableOption['dataType'];
        }
        
        
        if(dataTableOption.hasOwnProperty('data')) {
            data = dataTableOption['data'];
        }
        

        // const [searchObj, setSearchObj] = useState<any>({});


        // const [columns, setColumns] = useState<ColumnType[]>(dataTableOption['columns']);
        

        const [inputState, setInputState] = useState<SearchStateType>(searchStateInit);

        const [isLoading, setIsLoading] = useState<boolean>(false);

        const [recordCount, setRecordCount] = useState(0);

        const [totalCellWidth, setTotalCellWidth] = useState<number>(0);

        const [bulkActionChecked, setBulkActionChecked] = useState<boolean>(false);

        //const [reloadData, setReloadDataState] = useState<boolean>(false);

        // let setSearchParams;

        // const setReloadData = () => {

        //     setReloadDataState(prevState => !prevState)
        // }


        // const setDataLoadedHandler = () => {
        //     setDataLoaded(prevState => { 
        //         return prevState === 1 ? 2 : 1; 
        //     });
        // }

        const getInputField = (  fieldName:string ) =>  {
            try {

                return searchState.inputFields[fieldName];
                
            }catch(e) {
                return '';
            }
        }

        const updateIsLoading = (state ) => {
            setIsLoading(prevState => state)
        }

        // const updateScreenSize = ( status ) => {
        //     setSearchState((prevSearchState: any) => {
        //         return {...prevSearchState, smallScreen:status } 
        //     });
        // }

        const clearSearchForm = ( ) => {

            for(let key in searchState.inputFields) {
                
                searchState.inputFields[key] = ''
            }

            setSearchState((prevSearchState: any) => { return {...searchState} });
        }

        const pageChangeHandler = ( page: any ) => {
            
            setSearchState((prevSearchState: any) => {
                if (dataTableOption.dataType == "memory" && prevSearchState.page !== page) {
                    return {...prevSearchState, page:page, loading:false, refresh: !prevSearchState.refresh } 
                } else if (prevSearchState.page !== page) {
                    return {...prevSearchState, page:page, loading:true } 
                } else {
                    return {...prevSearchState, loading:false } 
                }
            });

        }

        const memorySort = (sortOrder:string, sortField:string, data:any) => {
            
            function dynamicSort(sortOrder:string, sortField:string, subSortField:string) {
                return function (a, b) {
                    /* next line works with strings and numbers, 
                    * and you may want to customize it to your needs
                    */
                    let nameA;
                    let nameB;
                    if (subSortField && a[sortField] && b[sortField]) {
                        nameA = (a[sortField][subSortField] + "")?.toUpperCase() || ""; // ignore upper and lowercase
                        nameB = (b[sortField][subSortField] + "")?.toUpperCase() || ""; // ignore upper and lowercase
                    } else {
                        nameA = (a[sortField] + "")?.toUpperCase() || ""; // ignore upper and lowercase
                        nameB = (b[sortField] + "")?.toUpperCase() || ""; // ignore upper and lowercase
                    }
                    var result = (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
                    return result;
                }
            }

            let sortedData;
            let mainSortField;
            let subSortField;
            mainSortField = sortField.includes(".") ? sortField.split(".")[0] : sortField
            subSortField = sortField.includes(".") ? sortField.split(".")[1] : null;
            if (sortOrder === 'asc') {
                sortedData =  data.sort(dynamicSort(sortOrder, mainSortField, subSortField));
            } else {
                sortedData =  data.sort(dynamicSort(sortOrder, mainSortField, subSortField)).reverse();
            }
            return sortedData;

            
        }
        
        
        const memoryFilter = (searchFields:any, data:any) => {
            
            if(typeof searchFields[0] === 'object') {
                delete(searchFields[0]);
            }
            
            function dynamicFilter(searchFields:any) {
            
                return function (a) {
                    /* next line works with strings and numbers, 
                    * and you may want to customize it to your needs
                    */
                    var results = [];
                    let found = 0;
                    for(let key in searchFields) {

                        results.push((a[key]?.toLowerCase())?.indexOf(searchFields[key].toLowerCase()));
                        found = 0;
                        
                        for(let i = 0; i <= results.length - 1; i++ ) {
                            if(results[i] > -1) {
                                ++found;
                            }
                        }
                    }
                    let result = 0;
                    if(results.length ===  found) {
                        result = 1;
                    }else {
                        result = 0;
                    }

                    return result;
                }
            }

            return data.filter(dynamicFilter(searchFields));
        }    
        
        


        const isAllFieldVisible = () => {

            return searchState.responsiveColumns.length === 0 ? true : false;

        }

        const getFieldValue = ( column, fieldName ) => {
            if(column.filterType === 'daterange') {
                let startField;
                let endField;
                if(column.filterParams) {
                    startField = ( column.filterParams.startField) ? column?.filterParams?.startField : '';
                }
        
                if(column.filterParams) {
                    endField = ( column.filterParams.endField) ? column.filterParams.endField : '';
                }
                let res  = [{
                    startDate: inputState.inputFields[startField],
                    endDate: inputState.inputFields[endField],
                    key: 'selection'
                    }];

                return res;
            }else {
                return inputState.inputFields[fieldName];
            }
        }

        const updateInputField = ( fieldName:string, fieldValue:any, column:ColumnType  ) => {
            
            //text
            if(fieldValue === false) {

                inputState.inputFields[fieldName] = false;
                
                setInputState((prevSearchState: any) => { return {...inputState} });

            }else if(!fieldValue || fieldValue === null) {
                
                delete(inputState.inputFields[fieldName]);
                
                setInputState((prevSearchState: any) => { 
                    return {...inputState} 
                });

            }else if(column.filterType === 'date') {

                const selDate = new Date(fieldValue);
                
                selDate.setHours(0); selDate.setMinutes(0); selDate.setSeconds(0); selDate.setMilliseconds(0);
                
                inputState.inputFields[fieldName] = selDate.toISOString();
                
                setInputState((prevSearchState: any) => { return {...inputState} });


            }else if(column.filterType === 'daterange') {
                let startField = '';
                let endField = '';

                
                if(column.filterParams) {
                    startField = ( column.filterParams.startField) ? column?.filterParams?.startField : '';
                }

                if(column.filterParams) {
                    endField = ( column.filterParams.endField) ? column.filterParams.endField : '';
                }

                // For the date range we make the start date from midnight, and endtime one milisecond before midnigth - LOCAL TIME!

                const startdate = new Date(fieldValue[0].startDate);
                const enddate   = new Date(fieldValue[0].endDate);

                startdate.setHours(0); startdate.setMinutes(0); startdate.setSeconds(0); startdate.setMilliseconds(0);
                enddate.setHours(0);   enddate.setMinutes(0);   enddate.setSeconds(0);   enddate.setMilliseconds(0);

                const endtime = new Date(enddate.getTime() + 24 * 60 * 60 * 1000 - 1);

                inputState.inputFields[startField] = startdate.toISOString();
                inputState.inputFields[endField] =   endtime.toISOString();

                setInputState((prevSearchState: any) => { return {...inputState} });

            } else {
                inputState.inputFields[fieldName] = fieldValue;
                
                setInputState((prevSearchState: any) => { return {...inputState} });
            }
    }

        const updateSearchFields = ( fieldName:string, fieldValue:any, column:ColumnType ) => {
            
            
            if(fieldValue === false) {
                
                searchState.searchFields[fieldName] = false;
                searchState.page = '';
                searchState.queryType = '';
                setSearchState((prevSearchState: any) => { return {...searchState} });

            }else if(!fieldValue  || fieldValue === null) {
                
                delete(searchState.searchFields[fieldName]);
                searchState.page = '';
                searchState.queryType = '';

                
                setSearchState((prevSearchState: any) => { 
                    return {...searchState} 
                });
            }else if(column.filterType === 'date') {

                    const selDate = new Date(fieldValue);
                    
                    selDate.setHours(0); selDate.setMinutes(0); selDate.setSeconds(0); selDate.setMilliseconds(0);
                    
                    searchState.searchFields[fieldName] =  selDate.toISOString();
                    searchState.page = '';
                    searchState.queryType = '';
                    searchState.setSearchParams({...searchState.searchFields});
                    setSearchState((prevSearchState: any) => { return {...searchState} });

        
        
            }else if(column.filterType == 'daterange') {
                let startField = '';
                let endField = '';

                //2021-12-16T00:00:00%2B05:30

                //const formatPattern = `yyyy-MM-dd'T'h:I:SS`;

                if(column.filterParams) {
                    startField = ( column.filterParams.startField) ? column?.filterParams?.startField : '';
                }

                if(column.filterParams) {
                    endField = ( column.filterParams.endField) ? column.filterParams.endField : '';
                }
                
                //searchState.searchFields[startField] = format(fieldValue[0].startDate, formatPattern) ;
                //searchState.searchFields[endField] = format(fieldValue[0].endDate, formatPattern) ;
                if(fieldValue.length > 0) {
                    searchState.searchFields[startField] = fieldValue[0].startDate;
                }else {
                    delete(searchState.searchFields[startField]);
                }

                if(fieldValue.length > 0) {
                    searchState.searchFields[endField] = fieldValue[0].endDate;
                }else {
                    delete(searchState.searchFields[endField]);
                }
                
                searchState.page = '';
                searchState.queryType = '';
                setSearchState((prevSearchState: any) => { return {...searchState} });

            }else {

                
                searchState.searchFields[fieldName] = fieldValue;
                searchState.page = '';
                searchState.queryType = '';
                setSearchState((prevSearchState: any) => { return {...searchState} });
            }
            
            
        // setSearchParams(searchState.searchFields);
        }


        const updateResponsiveColumns = ( visibleColumns ) => {
            //let responsiveColumns = DataTableContextObj.searchState.responsiveColumns;

            var responsiveColumns = [...Array(visibleColumns)]

            responsiveColumns = responsiveColumns.map((item, index) => {
                return index;
            })

            let columns  = dataTableOption['columns'].filter((item, index) => {
                return (index < visibleColumns )
            })
            
            let visibleColumnsArr = columns.map(function(row, index) {
                return row.key;
            })

            setSearchState((prevSearchState: any) => { return {...prevSearchState, responsiveColumns:[...responsiveColumns], visibleColumns:[...visibleColumnsArr]} });
        }

        const resizeCalJs = (columns) => {
            //var isAtMaxWidth = screen.availWidth - window.innerWidth === 0;

            if(isMobile) {
                resizeMobile(columns);
            }else {
                resizeDesktop(columns);
            }
        }

        const resizeMobile = async ( columns ) => {
            // var element:any = document.getElementById('debug-checkbox');

            
            let calTotalCellWidth = 0;
            
            let widthArr = [];

            var marginSize = 0;

            var offsetWidth =  document.body.offsetWidth - marginSize;
            
            
            setDebugValue(`Body Offset Width  ${offsetWidth}px`, "all-extra-debug-value");
            
            //columns = columns.filter(column => { return column.key !== 'bulk_action_checkbox' && column.key !== 'action_button' });
            //set all column visible frist
            columns.map((column, index) => {
                changeDisplayOnClassName(column.key, "")
            })

            setDebugValue("availWidth " + window.screen.availWidth + " - " + " offsetWidth " +  offsetWidth, "all-extra-debug-value");

        

            changeDisplayOnClassName("RenderActionDropDownIcon", "none");
            changeDisplayOnClassName("RenderActionDropDown", "none");
            changeDisplayOnClassName("RenderActionIcons", "");

            if(document.getElementById("RenderFilterPanelIcon") !== null) {
                document.getElementById("RenderFilterPanelIcon").style.display = "none";
            }
            

            let bulkActionCheckboxFound = false;
            let actionButtonFound = false;
            
            columns.map((column) => {

                //get width of each column
                if(document.getElementById(column.key)) {
                    
                    if(column.key === 'bulk_action_checkbox') {
                        bulkActionCheckboxFound = true;
                    }

                    if(column.key === 'action_button') {
                        actionButtonFound = true;
                    }

                    let width:number =  document.getElementById(column.key).offsetWidth;
                    // if(isNaN(width)) {
                    //     width = document.getElementById(column.key).offsetWidth;
                    // }
                    
                    setDebugValue(width + 'px',`debug-${column.key}`);
                    
                    setDebugValue(column.key + ' width = ' + width, "all-extra-debug-value");
                        
                    

                    
                    //set all filter column to none that are in panel (visibles on mobile devices)
                    if(document.getElementById(`filter-${column.key}`) != null) {
                        document.getElementById(`filter-${column.key}`).style.display = "none";
                    }
                    changeDisplayOnClassName(`panel-field-${column.key}`, "none");                
                    widthArr.push(width);

                    calTotalCellWidth = calTotalCellWidth + width;
                }
            })
        
            let actionButtonWidth = 0;
            let bulkActionCheckboxWidth = 0;
            //get the width of  first and last column
            if(actionButtonFound === false && document.getElementById("action_button")) {
                actionButtonWidth = document.getElementById("action_button").offsetWidth;;
            }

            if(bulkActionCheckboxFound === false && document.getElementById("bulk_action_checkbox")) {
                bulkActionCheckboxWidth = document.getElementById("bulk_action_checkbox").offsetWidth;;
            }

            let extraColumnWidth = actionButtonWidth + bulkActionCheckboxWidth;
            

        
            //check if total column width is less then document body width
            //if table can be fit in the available space
            //then visible all columns and 
            //hide RenderActionDropDown that visibles on mobile
            //show RenderActionIcons that visibles on desktop

            
            let totalCellWidth =  calTotalCellWidth +  extraColumnWidth + 4;
            
            

            
            setDebugValue("Total Cell Width = " + totalCellWidth +  " Extra Column Width " + extraColumnWidth + " Doc body offsetWidth " + document.body.offsetWidth, "all-extra-debug-value");
            
        

            if(totalCellWidth <= offsetWidth) { 
                
                columns.map((column, index) => {
                    
                    changeDisplayOnClassName(column.key, "")
                })

                //DataTableContextObj.updateResponsiveColumns(0);

                changeDisplayOnClassName("RenderActionDropDown", "none");
                changeDisplayOnClassName("RenderActionIcons", "");
                

            }else  {
                
                //if screen is not full then
                //hide all RenderActionIcons from rows that are for desktop
                //show all RenderActionDropDown from rows that are for mobile
                
                changeDisplayOnClassName("RenderActionDropDown", "block");
                changeDisplayOnClassName("RenderActionIcons", "none");

                //calc the difference between screen width and total column width;
                let widthDiff = Math.abs((extraColumnWidth + calTotalCellWidth) - offsetWidth);

                setDebugValue("width Difference = " + widthDiff, `all-extra-debug-value`)


                let total = 0;
                
                let  len = widthArr.length - 1;
                
                let i = 0;
                
                let columnCount = 0;

                //get the sum of total column width  that can be fit in the screen 
                //count total columns that can be fit in the screen

                for(i = len; i > 0; --i) {
                    
                    total = total + widthArr[i];
                    
                    ++columnCount;
                    
                
                    if(total >= widthDiff) {
                        break;
                    }
                }

                setDebugValue("visible width Total = " + total, `all-extra-debug-value`)
                    


                //calc visible column counts
                let visibleColumnCount =  (len - columnCount)

                //make all column visible that can be fit and hide those columns that can not be fit
                tableSettings.visibleColumnsIndexArr = [];
                tableSettings.hiddenColumnsIndexArr = [];

                let minimumColumnCount = (columns[0].key === 'bulk_action_checkbox' || columns[0].key === 'field_panel_collapse_icon') ? 1 : 0;
                //minimumColumnCount = (columns[0].key === 'field_panel_collapse_icon') ? 1 : 0;
                
                columns.map((column, index) => {
                    if(index > minimumColumnCount ) {
                        let elem:any = [];
                        elem = document.getElementsByClassName(column.key);

                        if(visibleColumnCount > index) {
                    
                            for (var j = elem.length - 1; j >= 0; j--) {
                                elem[j].style.display = "";
                            };
                            tableSettings.visibleColumnsIndexArr.push(index);

                        }else {
                            for (var j = elem.length - 1; j >= 0; j--) {
                                elem[j].style.display = "none";
                            };
                            

                            tableSettings.hiddenColumnsIndexArr.push(index);
                        }
                    }
                })

            
                
                //there are some visible columsn on the mobile screen 
                if(visibleColumnCount > 0 ) {
                    
                    //show filster panel icon
                    if(document.getElementById("RenderFilterPanelIcon")){
                        document.getElementById("RenderFilterPanelIcon").style.display = "";
                    }

                    //visible RenderActionDropDownIcon that are in each row
                    changeDisplayOnClassName("RenderActionDropDownIcon", "")
                    changeDisplayOnClassName("action_button", "")
                    
                    var responsiveColumns = tableSettings.visibleColumnsIndexArr;

                    responsiveColumns = responsiveColumns.map((item, index) => {
                        return index;
                    })
                    
                    

                    let columns  = dataTableOption['columns'].filter((item, index) => {
                        return (index >= visibleColumnCount )
                    })

                    
                    columns.map(function(row, index) {
                        if(document.getElementById(`filter-${row.key}`) != null) {

                            document.getElementById(`filter-${row.key}`).style.display = "";

                        }else {
                            
                        }
                        changeDisplayOnClassName(`panel-field-${row.key}`, "");
                    })

                }else {
                    document.getElementById("RenderFilterPanelIcon").style.display = "none";
                }
                
            }

            
        }
    
        //original copy of resizeDesktop funtion

        // const resizeDesktop = async ( columns ) => {
        //     var element:any = document.getElementById('debug-checkbox');

        //     let fullScreen = false;
            
        //     let calTotalCellWidth = 0;
            
        //     let widthArr = [];

        //     var marginSize = 0;

            

        //     var offsetWidth =  document.body.offsetWidth - marginSize;
            
        //     //columns = columns.filter(column => { return column.key !== 'bulk_action_checkbox' && column.key !== 'action_button' });
        //     //set all column visible frist
        //     columns.map((column, index) => {
        //         changeDisplayOnClassName(column.key, "")
        //     })


        //     //check if screen is full or not
        //     if((window.screen.availWidth - offsetWidth) > 0) {
        //         fullScreen = false;
        //     }else {
        //         fullScreen = true;
        //     }
            

        //     changeDisplayOnClassName("RenderActionDropDownIcon", "none");
        //     changeDisplayOnClassName("RenderActionDropDown", "none");
        //     changeDisplayOnClassName("RenderActionIcons", "");

        //     if(document.getElementById("RenderFilterPanelIcon") !== null) {
        //         document.getElementById("RenderFilterPanelIcon").style.display = "none";
        //     }
            

        //     let bulkActionCheckboxFound = false;
        //     let actionButtonFound = false;
            
        //     columns.map((column) => {

        //         //get width of each column
        //         if(document.getElementById(column.key)) {
                    
        //             if(column.key === 'bulk_action_checkbox') {
        //                 bulkActionCheckboxFound = true;
        //             }

        //             if(column.key === 'action_button') {
        //                 actionButtonFound = true;
        //             }

        //             let width:number =  document.getElementById(column.key).offsetWidth;
        //             // if(column.key === 'action_button') {
        //             //     width = width + 60;
        //             // }
        //             // if(isNaN(width)) {
        //             //     width = document.getElementById(column.key).offsetWidth;
        //             // }

        //             if(element.checked) {
        //                 console.log(column.key + ' width = ' + width);
        //             }

                    
        //             //set all filter column to none that are in panel (visibles on mobile devices)
        //             if(document.getElementById(`filter-${column.key}`) != null) {
        //                 document.getElementById(`filter-${column.key}`).style.display = "none";
        //             }
        //             changeDisplayOnClassName(`panel-field-${column.key}`, "none");

        //             widthArr.push(width);

        //             calTotalCellWidth = calTotalCellWidth + width;
        //         }
        //     })
        
        //     let actionButtonWidth = 0;
        //     let bulkActionCheckboxWidth = 0;
        //     //get the width of  first and last column
        //     if(actionButtonFound === false && document.getElementById("action_button")) {
        //         actionButtonWidth = document.getElementById("action_button").offsetWidth;;
        //     }

        //     if(bulkActionCheckboxFound === false && document.getElementById("bulk_action_checkbox")) {
        //         bulkActionCheckboxWidth = document.getElementById("bulk_action_checkbox").offsetWidth;;
        //     }

        //     let extraColumnWidth = actionButtonWidth + bulkActionCheckboxWidth;

        
        //     //check if total column width is less then document body width
        //     //if table can be fit in the available space
        //     //then visible all columns and 
        //     //hide RenderActionDropDown that visibles on mobile
        //     //show RenderActionIcons that visibles on desktop

            
        //     ///console.log((calTotalCellWidth + extraColumnWidth) + " " + extraColumnWidth + " " + document.body.offsetWidth)
            
        //     let totalCellWidth =  calTotalCellWidth +  extraColumnWidth + 40;
            
            

        //     if(element.checked) {
        //         console.log("Total Cell Width = " + totalCellWidth +  " Extra Column Width " + extraColumnWidth + " Doc body offsetWidth " + document.body.offsetWidth)
        //     }
        

        //     if(totalCellWidth <= offsetWidth) { 
                
        //         columns.map((column, index) => {
                    
        //             changeDisplayOnClassName(column.key, "")
        //         })

        //         //DataTableContextObj.updateResponsiveColumns(0);

        //         changeDisplayOnClassName("RenderActionDropDown", "none");
        //         changeDisplayOnClassName("RenderActionIcons", "");
                

        //     }else if(!fullScreen) {
                
                
        //         //if screen is not full then
        //         //hide all RenderActionIcons from rows that are for desktop
        //         //show all RenderActionDropDown from rows that are for mobile
                
        //         changeDisplayOnClassName("RenderActionDropDown", "block");
        //         changeDisplayOnClassName("RenderActionIcons", "none");

        //         //calc the difference between screen width and total column width;
        //         let widthDiff = Math.abs((extraColumnWidth + calTotalCellWidth) - offsetWidth);

        //         if(element.checked) {
        //             console.log("width Difference = " + widthDiff)
        //         }   

        //         let total = 0;
                
        //         let  len = widthArr.length - 1;
                
        //         let i = 0;
                
        //         let columnCount = 0;

        //         //get the sum of total column width  that can be fit in the screen 
        //         //count total columns that can be fit in the screen

        //         for(i = len; i > 0; --i) {
                    
        //             total = total + widthArr[i];
                    
        //             ++columnCount;
                    
                
        //             if(total >= widthDiff) {
        //                 break;
        //             }
        //         }

        //         if(element.checked) {
        //             console.log("visible width Total = " + total)
        //         }   


        //         //calc visible column counts
        //         let visibleColumnCount =  (len - columnCount)

        //         if(element.checked) {
        //             console.log("visibleColumnCount = " + visibleColumnCount)
        //         }   

        //         //make all column visible that can be fit and hide those columns that can not be fit
        //         tableSettings.visibleColumnsIndexArr = [];
        //         tableSettings.hiddenColumnsIndexArr = [];

        //         let minimumColumnCount = (columns[0].key === 'bulk_action_checkbox' || columns[0].key === 'field_panel_collapse_icon') ? 1 : 0;
        //        // minimumColumnCount = (columns[0].key === 'field_panel_collapse_icon') ? 1 : 0;

        //         columns.map((column, index) => {
        //             if(index > minimumColumnCount ) {
        //                 let elem:any = [];
        //                 elem = document.getElementsByClassName(column.key);

        //                 if(visibleColumnCount > index) {
        //                     if(element.checked) {
        //                         console.log("visible index = " + index)
        //                     } 

        //                     for (var j = elem.length - 1; j >= 0; j--) {
        //                         elem[j].style.display = "";
        //                     };
        //                     tableSettings.visibleColumnsIndexArr.push(index);

        //                 }else {
        //                     for (var j = elem.length - 1; j >= 0; j--) {
        //                         elem[j].style.display = "none";
        //                     };
                            

        //                     tableSettings.hiddenColumnsIndexArr.push(index);
        //                 }
        //             }
        //         })

            
                
        //         //there are some visible columsn on the mobile screen 
        //         if(visibleColumnCount > 0 ) {
                    
        //             //show filster panel icon
        //             document.getElementById("RenderFilterPanelIcon").style.display = "";

        //             //visible RenderActionDropDownIcon that are in each row
        //             changeDisplayOnClassName("RenderActionDropDownIcon", "")
                    
        //             var responsiveColumns = tableSettings.visibleColumnsIndexArr;

        //             responsiveColumns = responsiveColumns.map((item, index) => {
        //                 return index;
        //             })
                    
                    

        //             let columns  = dataTableOption.columns.filter((item, index) => {
        //                 return (index >= visibleColumnCount )
        //             })

                    
        //             columns.map(function(row, index) {
        //                 if(document.getElementById(`filter-${row.key}`) != null) {

        //                     document.getElementById(`filter-${row.key}`).style.display = "";
        //                 }else {
                            
        //                 }
        //                 changeDisplayOnClassName(`panel-field-${row.key}`, "");
        //             })

        //         }else {
        //             document.getElementById("RenderFilterPanelIcon").style.display = "none";
        //         }
                
        //     }

            
        // }


        const getInputFieldValue = ( column, fieldName ) => {
            if(column.filterType === 'daterange') {
                let startField;
                let endField;
                if(column.filterParams) {
                    startField = ( column.filterParams.startField) ? column?.filterParams?.startField : '';
                }
        
                if(column.filterParams) {
                    endField = ( column.filterParams.endField) ? column.filterParams.endField : '';
                }
                let res  = [{
                    startDate: searchState.inputFields[startField],
                    endDate: searchState.inputFields[endField],
                    key: 'selection'
                    }];

                return res;
            }else {
                return searchState.inputFields[fieldName];
            }
        }

        const getUrlValue = (DataTableContextObj, filterField) => {
            let urlValue:any = '';
            if(DataTableContextObj.searchObj) {
                DataTableContextObj.searchObj.find(item => {
                    let keys = Object.keys(item);
                    if(keys[0] === filterField) {
                        urlValue = Object.values(item)[0];
                    }
                })
            }
            return urlValue;
        }

        const resizeDesktop = async ( columns ) => {
            var element:any = document.getElementById('debug-checkbox');

            let fullScreen = false;
            
            let calTotalCellWidth = 0;
            
            let widthArr = [];

            var marginSize = 0;

            

            var offsetWidth =  document.body.offsetWidth - marginSize;
            
            setDebugValue(' Body Offset Width ' +  offsetWidth + 'px', `all-extra-debug-value`)
            
            
            //columns = columns.filter(column => { return column.key !== 'bulk_action_checkbox' && column.key !== 'action_button' });
            //set all column visible frist
            columns.map((column, index) => {
                changeDisplayOnClassName(column.key, "")
            })


            //check if screen is full or not
            if((window.screen.availWidth - offsetWidth) > 0) {
                fullScreen = false;
            }else {
                fullScreen = true;
            }
            

            changeDisplayOnClassName("RenderActionDropDownIcon", "none");
            changeDisplayOnClassName("RenderActionDropDown", "none");
            changeDisplayOnClassName("RenderActionIcons", "");

            if(document.getElementById("RenderFilterPanelIcon") !== null) {
                document.getElementById("RenderFilterPanelIcon").style.display = "none";
            }
            

            let bulkActionCheckboxFound = false;
            let actionButtonFound = false;
            
            columns.map((column) => {

                //get width of each column
                if(document.getElementById(column.key)) {
                    
                    if(column.key === 'bulk_action_checkbox') {
                        bulkActionCheckboxFound = true;
                    }

                    if(column.key === 'action_button') {
                        actionButtonFound = true;
                    }

                    let width:number =  document.getElementById(column.key).offsetWidth;

                    setDebugValue(`${column.key}  ${width}px`, `debug-${column.key}`);

                    
                    // if(column.key === 'action_button') {
                    //     width = width + 60;
                    // }
                    // if(isNaN(width)) {
                    //     width = document.getElementById(column.key).offsetWidth;
                    // }

                    
                    setDebugValue(column.key + ' width = ' + width, `all-extra-debug-value`)
                    

                    
                    //set all filter column to none that are in panel (visibles on mobile devices)
                    if(document.getElementById(`filter-${column.key}`) != null) {
                        document.getElementById(`filter-${column.key}`).style.display = "none";
                    }
                    changeDisplayOnClassName(`panel-field-${column.key}`, "none");

                    widthArr.push(width);

                    calTotalCellWidth = calTotalCellWidth + width;
                }
            })
        
            let actionButtonWidth = 0;
            let bulkActionCheckboxWidth = 0;
            //get the width of  first and last column
            if(actionButtonFound === false && document.getElementById("action_button")) {
                actionButtonWidth = document.getElementById("action_button").offsetWidth;;
            }

            if(bulkActionCheckboxFound === false && document.getElementById("bulk_action_checkbox")) {
                bulkActionCheckboxWidth = document.getElementById("bulk_action_checkbox").offsetWidth;
            }

            let extraColumnWidth = actionButtonWidth + bulkActionCheckboxWidth;

        
            //check if total column width is less then document body width
            //if table can be fit in the available space
            //then visible all columns and 
            //hide RenderActionDropDown that visibles on mobile
            //show RenderActionIcons that visibles on desktop

            
            let totalCellWidth =  calTotalCellWidth +  extraColumnWidth + 40;
            
            setDebugValue("Total Cell Width = " + totalCellWidth +  " Extra Column Width " + extraColumnWidth + " Doc body offsetWidth " + document.body.offsetWidth, "all-extra-debug-value");

            //add true to show always all columns on desktop, till the layout issue does not fixed
            if(totalCellWidth <= offsetWidth || true) { 
            //if(totalCellWidth <= offsetWidth) { 
                
                columns.map((column, index) => {
                    
                    changeDisplayOnClassName(column.key, "")
                })

                //DataTableContextObj.updateResponsiveColumns(0);

                changeDisplayOnClassName("RenderActionDropDown", "none");
                changeDisplayOnClassName("RenderActionIcons", "");
                

            }else if(!fullScreen) {
                
                
                //if screen is not full then
                //hide all RenderActionIcons from rows that are for desktop
                //show all RenderActionDropDown from rows that are for mobile
                
                changeDisplayOnClassName("RenderActionDropDown", "block");
                changeDisplayOnClassName("RenderActionIcons", "none");

                //calc the difference between screen width and total column width;
                let widthDiff = Math.abs((extraColumnWidth + calTotalCellWidth) - offsetWidth);

                if(element.checked) {
                    setDebugValue("width Difference = " + widthDiff, "all-extra-debug-value");
                }   

                let total = 0;
                
                let  len = widthArr.length - 1;
                
                let i = 0;
                
                let columnCount = 0;

                //get the sum of total column width  that can be fit in the screen 
                //count total columns that can be fit in the screen

                for(i = len; i > 0; --i) {
                    
                    total = total + widthArr[i];
                    
                    ++columnCount;
                    
                
                    if(total >= widthDiff) {
                        break;
                    }
                }

                
                setDebugValue("visible width Total = " + total, "all-extra-debug-value");
                


                //calc visible column counts
                let visibleColumnCount =  (len - columnCount)

                
                setDebugValue("visibleColumnCount = " + visibleColumnCount, "all-extra-debug-value");
                

                //make all column visible that can be fit and hide those columns that can not be fit
                tableSettings.visibleColumnsIndexArr = [];
                tableSettings.hiddenColumnsIndexArr = [];

                let minimumColumnCount = (columns[0].key === 'bulk_action_checkbox' || columns[0].key === 'field_panel_collapse_icon') ? 1 : 0;
            // minimumColumnCount = (columns[0].key === 'field_panel_collapse_icon') ? 1 : 0;

                columns.map((column, index) => {
                    if(index > minimumColumnCount ) {
                        let elem:any = [];
                        elem = document.getElementsByClassName(column.key);

                        if(visibleColumnCount > index) {
                            
                            setDebugValue("visible index = " + index, "all-extra-debug-value");
                            

                            for (var j = elem.length - 1; j >= 0; j--) {
                                elem[j].style.display = "";
                            };
                            tableSettings.visibleColumnsIndexArr.push(index);

                        }else {
                            for (var j = elem.length - 1; j >= 0; j--) {
                                elem[j].style.display = "none";
                            };
                            

                            tableSettings.hiddenColumnsIndexArr.push(index);
                        }
                    }
                })

            
                
                //there are some visible columsn on the mobile screen 
                if(visibleColumnCount > 0 ) {
                    
                    //show filster panel icon
                    document.getElementById("RenderFilterPanelIcon").style.display = "";

                    //visible RenderActionDropDownIcon that are in each row
                    changeDisplayOnClassName("RenderActionDropDownIcon", "")
                    
                    var responsiveColumns = tableSettings.visibleColumnsIndexArr;

                    responsiveColumns = responsiveColumns.map((item, index) => {
                        return index;
                    })
                    
                    

                    let columns  = dataTableOption['columns'].filter((item, index) => {
                        return (index >= visibleColumnCount )
                    })

                    
                    columns.map(function(row, index) {
                        if(document.getElementById(`filter-${row.key}`) != null) {

                            document.getElementById(`filter-${row.key}`).style.display = "";
                        }else {
                            
                        }
                        changeDisplayOnClassName(`panel-field-${row.key}`, "");
                    })

                }else {
                    document.getElementById("RenderFilterPanelIcon").style.display = "none";
                }
                
            }

            
        }


        let defaultSortField;
        let defaultSortOrder;

        let searchParams;

        let values = {
            searchState, 
            updateSearchFields, 
            getInputField, 
            updateInputField, 
            clearSearchForm, //nu
            isLoading, 
            updateIsLoading,  //nu
            setSearchState,
            totalCellWidth, 
            setTotalCellWidth,
            recordCount, 
            setRecordCount,
            pageChangeHandler,
            bulkActionChecked, 
            setBulkActionChecked,
            modal,
            isAllFieldVisible,
            getFieldValue,
            searchParams,
            updateResponsiveColumns,
            changeDisplayOnClassName,
            countLabel,
            resizeCalJs,
            // reloadData, 
            // setReloadData,
            // setDataLoadedHandler,
            //dataLoaded, 
            defaultSortField,
            defaultSortOrder,
            // setSearchObj,
            name: (name) ? name: 'data-table',
            exportInit,
            dataType,
            data,
            memorySort,
            memoryFilter,
            getUrlValue,
            getInputFieldValue
        }



        useEffect(() => {
            
            if(searchState.exportInit > 0) {
                
                dataExportStart()
            }

        },[searchState.exportInit]);

        const dataExportStart = () => {
            var urlArr = [];
            
            let keys = Object.keys(searchState.searchFields);

            // Here we are giving priority to search_deveui on selectedIds
            
            if (!keys.includes("search_deveui")) {
                const selectedIds = searchState.selectedRowIds.map(v => v.deveui)
                if (selectedIds.length > 0) {
                    const ids = selectedIds.join(",")
                    urlArr.push("deveuis" + "=" + ids)
                }
            }

            for(let i = 0; i < keys.length; ++i) {
                urlArr.push(keys[i] + "=" + searchState.searchFields[keys[i]])
            }
            
            let exportQueryStr = urlArr.join('&');
            
            let exportPath = searchState.dataTableOption.hasOwnProperty('exportPath') ? dataTableOption['exportPath'] : '';

            var url = window.location.protocol;
            
            var tz = "&tz=" + (new Date()).getTimezoneOffset();
            
            url += '//';
            
            url += window.location.host;
            
            let fullUrl = url + '/' +  exportPath +  tz + '&' + exportQueryStr;
            
            window.location.assign(fullUrl);
            
        }

        return  (
            <WrappedComponent values={values}>
                {props.children}
            </WrappedComponent>
        )
    }
}


export { DataTableCommonState };



