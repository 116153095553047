import React, { useEffect, useState, useContext } from "react";

import { isMobile } from 'react-device-detect';
import AppContext from "../../context/AppContext";

import { SpinnerShow, SpinnerHide, ReactSpinner } from "../../components/Common/ReactSpinner";

import {
    //Form,
    FormGroup,
    FormControl,
    InputGroup,
    Button,
    Tooltip,
    OverlayTrigger
} from "react-bootstrap";

import Form from 'react-bootstrap/Form';

import { Outlet, useSearchParams } from 'react-router-dom';
//import AsyncSelect from 'react-select/async'

import MapTagSelect from "./MapTagSelect";

import { useLocation } from "react-router-dom";

import { strings } from "./../../services/Localization";

import { toast } from "./../../utils/Toaster";

import DevicesTable from "../../components/Device/NetworkMap/DevicesTable";

import Mapv2 from "../../components/Device/NetworkMap/Map/Mapv2";

import { DataTableWrapper } from '../../components/Common';

import MapGatewaysTable from '../../components/Device/NetworkMap/MapGatewaysTable';

//import { MapFlyToSrcType } from "../../datatypes/datatypes"



import MapContext from '../../context/MapContext'


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSearch, faRefresh, faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";

import { GenericDassQuery } from "../../services/BasicDassQueries";
import { DataTableContext } from "../../components/Common/DataTable/DataTableState";
import { MapDeviceStatus, getAppName, NST, getIsNst, APP } from "../../utils/consts";



const NetworkMap: React.FC<{}> = () => {


    const location = useLocation();

    const MapContextObj = useContext(MapContext);
    const AppContextObj = useContext(AppContext);
    let DataTableContextObj = useContext(DataTableContext);

    const inputFields = DataTableContextObj.searchState.inputFields;

    const state = MapContextObj.networkState;
    const user = AppContextObj.user;

    const appName = getAppName(location);

    const isNst = getIsNst(location);

    const [searchParams, setSearchParams] = useSearchParams();
        
    let tab = searchParams.get('tab');

    if(!tab) {
        tab = MapContextObj.networkState.currentTab;
    }

    const handleSetTab = ( tab ) => {

        setSearchParams({tab});

        if(tab  === 'devices') {
            MapContextObj.setNetworkState(prevState => {
                return {...prevState, TableData: state.Devices, currentTab:tab}
            })
        }else {
            MapContextObj.setNetworkState(prevState => {
                return {...prevState, TableData: state.Gateways, gateways: state.Gateways, currentTab:tab}
            })
        }
    }

    // const mapType = (isNst) ? 1 : 0;

    // const InitState = {
    //     Gateways: [],
    //     Devices: [],
    //     Draggable: false,
    //     ShowDevices: true,
    //     ShowGateways: true,
    //     FlyTo: null,
    //     OpenEditPositionModal: false,
    //     EditPositionModalData: null,
    //     TableFilterValue: "",
    //     TableData: [],
    //     LoggedUser: null,
    //     mapCenterLatitude: null,
    //     mapCenterLongitude: null,
    //     mapZoom: null,
    //     refresh:false,
    //     changed:false,
    //     map:null,
    // }
    
    // const [state, setState] = useState(InitState);

    // const onRecenter = (latitude: number, longitude: number, zoom: number) => {

    //     MapContextObj.setNetworkState(prevState => { return {...prevState, mapCenterLatitude: latitude, mapCenterLongitude: longitude, mapZoom: zoom } });
    // }

    const refreshGateway = async () => {
        document.getElementById("refresh-button-nst").style.display  = 'none';
        SpinnerShow();
        const data = await LoadAllGatewaysFunc();
        MapContextObj.setNetworkState(prevState => { return {...prevState,
            Gateways: data.Gateways,
            TableData: data.Gateways,
            refresh: !state.refresh,
            changed: !state.changed,
        }});
        SpinnerHide();
        document.getElementById("refresh-button-nst").style.display  = '';
    }

    useEffect(() => {

        async function m() {
            if(appName ===  NST) {

                MapContextObj.setNetworkState(prevState => { return {...prevState,
                    Gateways: [],
                    TableData: [],
                    changed: state.changed,
                }});
             
                
            }else {
                const data = await Promise.all([LoadAllDevicesFunc(), LoadAllGatewaysFunc()]);

                MapContextObj.setNetworkState(prevState => { return {...prevState,
                    Devices: data[0].Devices,
                    Gateways: data[1].Gateways,
                    TableData: (tab === 'gateways') ? data[1].Gateways: data[0].Devices,     // TODO: depending on the tab
                    changed: !state.changed,
                }});
            }
        }
        m();
    },[])


    const LoadAllGatewaysFunc = async () => {

        const appName = getAppName(location);
        let url = "/rest/omc/gateways-geo"
        if (searchParams.get("search_tags")) {
            url = `/rest/omc/gateways-geo?search_tags=${searchParams.get("search_tags")}`;
        } else if (inputFields && inputFields.hasOwnProperty("search_tags")) {
            let selectedTags = inputFields.search_tags;
            url = `/rest/omc/gateways-geo?search_tags=${selectedTags}`;
        }

        try {
            return {
                search_activated:  { type: "string", dass: true },
                Gateways: appName === "nst" ? (await GenericDassQuery(url)).data
                                            : (await GenericDassQuery("/rest/gateways")).data
            };
        } catch (e) {
            console.log(e.message);
        }
        return { Gateways: [] };

    }

    const LoadAllDevicesFunc = async () => {
        try {
            return { Devices: (await GenericDassQuery("/rest/nodes?size=1000")).data };
        } catch (e) {
            if(e.status == 402){
                toast.warning(e.message)
            }
            console.log(e.message);
        }
        return { Devices: [] };
    }
         //80029cfffe61b800
        //marker-80029cfffe61b800
 

    

    // const UpdateMarker = async (marker) => {
    //     if (marker && marker.position && marker.data) {
    //         const dataToSend = {
    //             "latitude": marker.position.latitude,
    //             "longitude": marker.position.longitude
    //         };
    //         if (marker.position.altitude) {
    //             dataToSend["altitude"] = marker.position.altitude || 0;
    //         } if (marker.data.hasOwnProperty("name")) {
    //             if (marker.data.type === "Devices") {
    //                 dataToSend["comment"] = marker.data.name
    //             } else {
    //                 dataToSend["name"] = marker.data.name
    //             }
    //         }
    //         if (marker.data.type === "Gateways") {
    //             const NewGateways = JSON.parse(JSON.stringify(state.Gateways));
    //             const res:any = await UpdateGateway(
    //                 marker.data.id,
    //                 dataToSend
    //             );
    //             const data = await res.text();
    //             if (res.status === 200) {
    //                 const index = NewGateways.findIndex(g => g.id === marker.data.id);
    //                 if (marker.data.hasOwnProperty("name")) {
    //                     NewGateways[index].name = marker.data.name;
    //                 }
    //                 if (marker.position.altitude) {
    //                     NewGateways[index].altitude = marker.position.altitude;
    //                 }
    //                 NewGateways[index].latitude = marker.position.latitude;
    //                 NewGateways[index].longitude = marker.position.longitude;
    //                 MapContextObj.setNetworkState(prevState => { return {...prevState,
    //                     Gateways: NewGateways,
    //                     TableData: state.Devices.concat(NewGateways)
    //                 }});
    //                 toast.success(strings.MSG_GATEWAY_POS_UPDATE_SUCCESS);
    //             } else {
    //                 toast.error(strings.MSG_GATEWAY_POS_UPDATE_FAIL);
    //                 console.log(data);
    //             }
    //         } else if (marker.data.type === "Devices") {
    //             const NewDevices = JSON.parse(JSON.stringify(state.Devices));
    //             const res:any = await UpdateDevice(
    //                 marker.data.id,
    //                 dataToSend
    //             );
    //             const data = res.text();
    //             if (res.status === 200) {
    //                 const index = NewDevices.findIndex(g => g.deveui === marker.data.id);
    //                 if (marker.data.hasOwnProperty("name")) {
    //                     NewDevices[index].comment = marker.data.name;
    //                 }
    //                 if (marker.position.altitude) {
    //                     NewDevices[index].altitude = marker.position.altitude;
    //                 }
    //                 NewDevices[index].latitude = marker.position.latitude;
    //                 NewDevices[index].longitude = marker.position.longitude;
                    
    //                 MapContextObj.setNetworkState(prevState => { return {...prevState,
    //                     Devices: NewDevices,
    //                     TableData: NewDevices.concat(state.Gateways)
    //                 }});

    //                 toast.success(strings.formatString(
    //                     strings.MSG_DEVICE_UPDATE_SUCCESS_BODY,
    //                     { deveui: marker.data.id.match(/.{2}/g).join("-").toUpperCase() },
    //                 ));
    //             } else {
    //                 toast.error(strings.formatString(
    //                     strings.MSG_DEVICE_UPDATE_FAIL_BODY,
    //                     { data: "" },
    //                 ));
    //                 console.log(data);
    //             }
    //         }
    //     }
    // }

    const reloadAllData = (e, tab) => {
        document.getElementById("refresh-button").style.display = 'none';
        SpinnerShow();

        Promise.all([LoadAllDevicesFunc(), LoadAllGatewaysFunc()])  
        .then((data:any) => {
            let keys = Object.keys(data);
            document.getElementById("refresh-button").style.display = '';
            SpinnerHide();
            if(tab === 'devices') {
                MapContextObj.setNetworkState(prevState => { return {...prevState,
                    TableData: data[keys[0]].Devices
                    ,changed: !state.changed
                }});
            }else {
                
                MapContextObj.setNetworkState(prevState => { return {...prevState,
                    TableData:  data[keys[1]].Gateways
                    ,changed: !state.changed
                }});
            }

        });
    }

    const TableFilterValue = (e, tab: string) => {

        if (e.keyCode === 13 || e.type === 'click') {
            FilterTable(tab);
        } else {
            MapContextObj.setNetworkState(prevState => { return {...prevState,
                TableFilterValue: e.target.value
            }});
        }
    }

    const FilterTable = ( tab: string ) => {
        
        try {
            const { TableFilterValue } = state;
            
            if (TableFilterValue.length > 0) {
                if(tab === 'devices') {
                    Promise.all([
                            GetFilterTableDataMemory("deveui", TableFilterValue?.toLowerCase().replace(/-|:/g, ""), tab),
                            GetFilterTableDataMemory("comment", TableFilterValue?.toLowerCase(), tab)

                    ]).then((Data) => {
                        
                        if(Data.length > 1) {
                            // console.log(!state.changed)
                            MapContextObj.setNetworkState(prevState => { return {...prevState,
                                TableData: Data[0].concat(Data[1])
                                ,changed: !state.changed,

                            }});
                        }

                    }).catch((e) => {
                        console.log(e);
                    });

                } else {
                    Promise.all([
                        GetFilterTableDataMemory("id", TableFilterValue?.toLowerCase().replace(/-|:/g, ""), tab),
                        GetFilterTableDataMemory("name", TableFilterValue?.toLowerCase(), tab),
                        
                    ]).then((Data) => {
                        // console.log(!state.changed)
                        MapContextObj.setNetworkState(prevState => { return {...prevState,
                            TableData: Data[0].concat(Data[1])
                            ,changed: !state.changed
                        }});
                        

                    }).catch((e) => {
                        console.log(e);
                    });
                }

            } else {

                if(tab === 'devices') {
                    
                    MapContextObj.setNetworkState(prevState => { return {...prevState,
                        TableData: state.Devices
                        ,changed: !state.changed
                    }});

                    }else {

                    MapContextObj.setNetworkState(prevState => { return {...prevState,
                            TableData: state.Gateways
                            ,changed: !state.changed
                    }});

                }
              
            }
        }catch(e) {
            console.log(e)
        }
    }

    // const GetFilterTableData = async (filterBy, filterValue) => {
    //     const res:any = await FilterDevices(filterBy, filterValue);
    //     let Data = [];
    //     if (res.status == 200) {
    //         Data = await res.json();
    //     }
    //     return Data;
    // }

    const GetFilterTableDataMemory = async (filterBy, filterValue, tab) => {

        const tempData: any[] = (tab === "gateways" ? state.Gateways : state.Devices) || [];
        const filterTempData = tempData.filter(row => {
            return row[filterBy]?.toLowerCase().indexOf(filterValue) > -1;
        });

        // const res:any = await FilterDevices(filterBy, filterValue);
        // let Data = [];
        // if (res.status == 200) {
        //     Data = await res.json();
        // }
        return filterTempData;
    }

    const setDefPosition = async () => {

        const map = MapContextObj.mapState.map;
        let mapCenter = map.getCenter();

        if (user) {
            const ui_settings = {
                ...user.ui_settings,
                map_center_latitude: mapCenter.lat,
                map_center_longitude: mapCenter.lng,
                map_zoom: map.getZoom(),
            };

            await GenericDassQuery(`/rest/users/$`, { method: "PUT", data: { ui_settings } });
            AppContextObj.updateUser({
                ...user,
                ui_settings
            })
            toast.success("position saved");
        }
    }



    const {
        // Gateways,
        // Devices,
        // Draggable,
        // OpenEditPositionModal,
        // EditPositionModalData,
        // FlyTo,
        // TableData,
        ShowDevices,
        ShowGateways,
        // LoggedUser
    } = state;

    // Get the default center and zoom from the current user.
    const mapCenterLat = typeof user.ui_settings?.map_center_latitude === "number" ? user.ui_settings.map_center_latitude : null;
    const mapCenterLng = typeof user.ui_settings?.map_center_longitude === "number" ? user.ui_settings.map_center_longitude : null;
    const center: [number, number] = mapCenterLat != null && mapCenterLng != null ? [mapCenterLat, mapCenterLng] : [48, 5];
    const zoom = typeof user.ui_settings?.map_zoom === "number" ? user.ui_settings?.map_zoom : 6;

    // let markers;

    // if (isNst > -1) {
    //     markers = {"Gateways": Gateways }
    // }else {
    //     markers = {"Gateways": Gateways, "Devices": Devices }
    // }
    return (
        <DataTableWrapper display={`block`} wrapperClass="table-wrapper-map">
            <div className="position-relative">
                <Mapv2 key={`map-v-2`} isNst={isNst} mapCenter={center} mapZoom={zoom}  setDefPosition={setDefPosition} />
                <AppNstLeftSide isNst={isNst} >
                    {(getAppName(location) === NST) && <NstSearchTable 
                                            refreshGateway={refreshGateway} 
                                            refresh={state.refresh}
                                            state={state} 
                                        />}


                    {(getAppName(location) === APP) && <AppSearchTable 
                                            handleSetTab={handleSetTab}
                                            TableFilterValue={TableFilterValue} 
                                            reloadAllData={reloadAllData}
                                            setDefPosition={setDefPosition}
                                            ShowGateways={ShowGateways}
                                            ShowDevices={ShowDevices}
                                            setState={MapContextObj.setNetworkState}
                                            state={state}
                                        />}
                   
                </AppNstLeftSide>
                
                <Outlet  />
            </div>
        </DataTableWrapper>
    );

}

export default NetworkMap;



const AppNstLeftSide = ( props ) => {

    const displayMapClass = isMobile ? 'ow-nst-map-left-sliding-panel-mobile ow-nst-map-left-sliding-panel' : 'ow-nst-map-left-sliding-panel'
    //let defaultState = (!isMobile) ? true : true;
    let className = (props.isNst === -1) ? 'ow-app-map-left-sliding-panel' : displayMapClass;

    const [open, setOpen] = useState(false);
    
    let id = (props.isNst === -1) ? 'NetworkMap' : 'NstNetworkMap';

    const handleSetOpen = () => {
        setOpen(prevState => !open);
        
        if(!open === true) {
            document.getElementById(id).style.display = "none";
            const collection = document.getElementsByClassName(className);
            for (let i = 0; i < collection.length; i++) {
                collection[i]['style'].width = "50px";
            }
            
        }else {
            document.getElementById(id).style.display = "";
            const collection = document.getElementsByClassName(className);
            for (let i = 0; i < collection.length; i++) {
                collection[i]['style'].width = "auto";
            }
        }
    }

    useEffect(() => {
        if(isMobile) {
            handleSetOpen();
        }
        
    },[])

    

    return (
        <div className={`${className}`}>
            {props.children}
            <div className="slide-button">
                <SlideButton setOpen={handleSetOpen} open={open}  />
            </div>
        </div>
    )
}


const NstSearchTable = ( props ) => {
    const refreshGateway = () => {
        props.refreshGateway();
    }

    return (
        <div id="NstNetworkMap" className={isMobile ? "ow-nst-map-left-sliding-panel-inner-mobile ow-nst-map-left-sliding-panel-inner" : "ow-nst-map-left-sliding-panel-inner"} >
            
            <div className="row ps-2 besides-data-table align-items-center">
                <div className="col-md-8  ">
                    <div className="search_input_wrapper  justify-content-center align-items-center mr-2" id="nst-network-map-search-box" >
                        {/* <div id="extra-search-box"></div> */}
                        <MapTagSelect  />
                    </div>
                </div>
                <div  className="col-md-4  d-flex justify-content-end align-items-end">
                    <div className="ms-1" >
                        <div className="react-spinner me-4"></div>
                        <div  id="refresh-button-nst">
                            <Button size='sm' className="text-nowrap  mr-2" onClick={() => { refreshGateway() }} title={`Refesh Gateway`} variant="outline-dark" >
                                {strings.MAP_REFRESH_GATEWAYS}
                            </Button>
                        </div>
                    </div>                    
                </div>
                
            </div>
            <div style={{marginTop: '20px'}}>
                { (!props.state.apiResponseRecieved) ? (<div id="map-gateway-react-spinner" className="my-5 ">
                            <ReactSpinner isBig={true} />
                        </div>) : (<MapGatewaysTable refresh={props.refresh} />) 
                    
                }
                
            </div>
        </div>
    )
}

const NetWorkMapTabs = (props) => {
        
    let devicesActiveClass = (props.tab === 'devices') ? "active" : '';
    let gatewaysActiveClass = (props.tab === 'gateways') ? "active" : '';
    
    return (
        <div className="tab">
            <div className={`network-map-tab ${devicesActiveClass}`}  onClick={() => { props.setTab('devices') }}>{strings.MAP_PANEL_TAB_DEVICES}</div>
            <div className={`network-map-tab ${gatewaysActiveClass}`} onClick={() => { props.setTab('gateways') }}>{strings.MAP_PANEL_TAB_GATEWAYS}</div>
        </div>
    )
}

const AppStatusFilter = ( props ) => {

    const handleCheckBox = (e) => {
        if(e.target.checked === true) {
            props.setSelectedStatus(prev => [...prev, e.target.value]);
        }else {
            let index = props.selectedStatus.indexOf(e.target.value);
            props.selectedStatus.splice(index, 1);
            props.setSelectedStatus(prev => [...props.selectedStatus]);
        }
    }

    const statusInputList = MapDeviceStatus.map((item, index) => { 
        let className = item?.statusKey.toLowerCase();
        const tooltip = (
            <Tooltip id="tooltip">
              {item?.statusValue}
            </Tooltip>
          )

        return (
            <OverlayTrigger placement="top" overlay={tooltip}>
            <Form.Check
                key={index}
                onClick={(e) => { handleCheckBox(e) } }
                inline
                label={``}
                value={`${item.statusKey}`}
                name="status"
                type={`checkbox`}
                className={`${className}`}
                id={`inline-checkbox-${index}`}
        /></OverlayTrigger>)
    })
    
    return (<div className="d-flex justify-content-end align-items-end flex-row ow-status">
                <div className="me-2">{strings.MAP_GATEWAY_TABLE_STATUS_LABEL}</div>
                {statusInputList}
            </div>)

}

const AppSearchTable = ( props ) => {
    const MapContextObj = useContext(MapContext);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState('');
    
    let tab = searchParams.get('tab');

    if(!tab) {
        tab = MapContextObj.networkState.currentTab;
    }

    const [selectedStatus, setSelectedStatus] = useState([]);

    const handleSetTab = ( tab ) => {

        props.handleSetTab(tab);

        setSearchParams({tab});

        if(tab  === 'devices') {
            MapContextObj.setNetworkState(prevState => {
                return {...prevState, TableData: props.state.Devices, currentTab:tab}
            })
        }else {
            MapContextObj.setNetworkState(prevState => {
                return {...prevState, TableData:props.state.Gateways, currentTab:tab}
            })
        }
    }

    const handleChange = (e) => {
        
        setSearchValue(prevState => e.target.value);
      
    }
    
    
    const placeholder = (tab === 'gateways') ? strings.MAP_FILTER_GATEWAYS : strings.MAP_FILTER_DEVICES;

    return (
            
            <div id="NetworkMap" className="ow-app-map-left-sliding-panel-inner" >
                <div className="app-side-panel-tab-container">
                    <NetWorkMapTabs tab={tab} setTab={handleSetTab} />
                    <div className="search_input_wrapper col-wise" key="search_input_wrapper">
                        <FormGroup>
                            <InputGroup>
                                <FormControl
                                style={{width : 210}}
                                    type="text"
                                    onKeyUp={(e) => { props.TableFilterValue(e, tab) }}
                                    placeholder={placeholder}
                                    value={ searchValue}
                                    onChange={handleChange}
                                    size="sm" 
                                />
                                <InputGroup.Text style={{cursor: 'pointer', padding:'0px'}}>
                                    <Button size='sm' className="text-nowrap"  variant="dark" onClick={ (e) => { props.TableFilterValue(e, tab) } }>
                                        <FontAwesomeIcon icon={faSearch}  />
                                    </Button>
                                </InputGroup.Text> 
                            </InputGroup>
                        </FormGroup>
                        <div className="ms-1" >
                            <div  id="refresh-button">
                                <Button size='sm' className="text-nowrap"  variant="outline-dark" onClick={ (e) => { props.reloadAllData(e, tab) } }>
                                    <FontAwesomeIcon icon={faRefresh} transform={{rotate: 90}} />
                                </Button>
                            </div>
                            <div className="react-spinner"></div>
                        </div>
                    </div>
                    <div key="app_status_filter">
                        {tab === 'gateways' &&
                        <AppStatusFilter selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />}
                    </div>
                    <div className="device_list" key="device_list">
                        <DevicesTable
                            tab={tab}
                            selectedStatus={selectedStatus}
                        />
                    </div>
                    <br/>
                    <div className="d-flex flex-row w-100 position-relative">
                        <div className="position-absolute"  style={{zIndex:999999, right:0}}>
                            {/* <Button className="saveButton" variant="light" onClick={props.setDefPosition}> <FontAwesomeIcon icon={faMapPin} /></Button> */}
                        </div>
                    </div>
                </div>
            </div>
            
        )
}






const SlideButton = ( props ) => {
    let icon = (props.open) ? faAngleRight : faAngleLeft;

    return (
        <Button size='sm' className="text-nowrap"  
                id="slide-button"
                style={{width: '30px', height: '30px', border: '0px solid'}} onClick={() => props.setOpen(!props.open)}
                aria-controls="example-collapse-text"
                aria-expanded={props.open} variant="light" >
                    <div style={{width: '100%', height: '100%', border:'0px solid'}}>
                        <FontAwesomeIcon icon={icon}  size="1x" color="black"  />
                    </div>
        </Button>
    )
}


// const TagSelect = () => {

//     const loadOptions = async () => {
        
//         const data = await new Promise((resolve, reject) => {
            
//             let resposne = fetch(`/uiapi/rest/omc/tags`).then(res => res.json());
            
//             resolve(resposne);

//           });
       

//         return data;

//     };

//     useEffect(() => {

//         async function m() {
//             let response = await loadOptions();
//             console.log(response);
//         }
//         m();

        
        
//     },[])
    
    
//     return (
//         <AsyncSelect 
//             className="col-md-12"
//             isMulti 
//             cacheOptions 
//             defaultOptions 
//             loadOptions={loadOptions} 
//             components={{ Option: DropdownProfiles }}
//             />

//     );
      
// }


// const filterColors = (inputValue: string) => {
    
//     return colourOptions.filter((i) =>
//       i.label.toLowerCase().includes(inputValue.toLowerCase())
//     );

//   };
  
//   const promiseOptions = (inputValue: string) =>

//     new Promise<ColourOption[]>((resolve) => {
//       setTimeout(() => {
//         resolve(filterColors(inputValue));
//       }, 1000);
//     });
  
//   class WithPromises extends React.Component {
//     render() {
//       return (
//         <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions} />
//       );
//     }
//   }

//   export  { WithPromises }